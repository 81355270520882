<template>
    <div class="main">
        <div class="search-pane">
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
            <div class="search-item-box">
                <a-button type="primary" ghost size="small">查询</a-button>
            </div>
            <div class="search-item-box">
                <a-button type="default" class="reset-btn" size="small">重置</a-button>
            </div>
        </div>
        <div class="table-box">
            <a-table :columns="columns" :data-source="data">
                <template #action>
                    <router-link :to="'/batteryWarning/'+122">详情</router-link>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BatteryWarning",
        data() {
            return {
                columns: [
                    {title: '站点名称', dataIndex: 'siteName', key: 'siteName'},
                    {title: '装机功率 / 容量', dataIndex: 'capacity', key: 'capacity'},
                    {title: '投运日期', dataIndex: 'startTime', key: 'startTime'},
                    {title: '电站地址', dataIndex: 'address', key: 'address'},
                    {title: '最新诊断时间', dataIndex: 'createTime', key: 'createTime'},
                    {title: '预警状态', dataIndex: 'status', key: 'status'},
                    {title: '操作', scopedSlots: { customRender: 'action' } },
                ],
                data: [
                    {
                        key: 1,
                        siteName: 'John Brown',
                        capacity: 32,
                        startTime: '2023-10-21',
                        status: '正常',
                    },
                ]
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/batteryWarning";
</style>
