<template>
    <div class="main">
        <div class="search-pane">
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
        </div>
        <div class="battery-tabs table-box">
            <a-tabs v-model:activeKey="activeTab">
                <a-tab-pane key="1" tab="电池堆">
                    <div class="battery-stack">
                        <div class="title-box">
                            <div class="curve-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-date-picker v-model:value="value1" size="small" locale="ch" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="battery-stack-echarts">
                            <!--电压模块-->
                            <Module :voltage-columns="voltageColumns" :voltage-data="voltageData"></Module>
                            <!--电流模块-->
                            <Module :voltage-columns="voltageColumns" :voltage-data="voltageData"></Module>
                            <!--SOC模块-->
                            <Module :voltage-columns="voltageColumns" :voltage-data="voltageData"></Module>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="电池簇" force-render>
                    <div class="battery-stack">
                        <div class="title-box">
                            <div class="curve-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-date-picker v-model:value="value1" size="small" locale="ch" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="battery-stack-echarts">
                            <!--电压模块-->
                            <Module :voltage-columns="voltageColumns" :voltage-data="voltageData"></Module>
                            <!--电流模块-->
                            <Module :voltage-columns="voltageColumns" :voltage-data="voltageData"></Module>
                            <!--SOC模块-->
                            <Module :voltage-columns="voltageColumns" :voltage-data="voltageData"></Module>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import Module from "@/views/BatteryAnalysis/components/Module";
    export default {
        name: "ChargeAnalysis",
        components: {Module},
        data(){
            return{
                activeTab: '1',
                value1: '', // 日期时间
                voltageColumns: [
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    { title: 'Age', dataIndex: 'age', key: 'age' },
                    { title: 'Address', dataIndex: 'address', key: 'address' },
                    { title: 'Action', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' } },
                ],
                voltageData: [
                    {
                        key: 1,
                        name: 'John Brown',
                        age: 32,
                        address: 'New York No. 1 Lake Park',
                        description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
                    },
                    {
                        key: 2,
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No. 1 Lake Park',
                        description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
                    },
                    {
                        key: 3,
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney No. 1 Lake Park',
                        description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.',
                    },
                ]
            }
        }
    }
</script>

<style lang="less">
@import "../../static/chargeAnalysis";
</style>
