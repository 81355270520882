<template>
    <div class="main">
        <div class="current-analysis">
            <div class="">
                <div class="current-analysis-header ems-box-header">
                    <div class="left">电堆电流预警</div>
                </div>
                <div class="current-main">
                    <div class="title-box">
                        <div class="current-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="current-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="current-content">
                        <!--报表  -->
                        <div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CurrentAnalysis",
        data() {
            return {
                dateRange: []
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/currentAnalysis";
</style>
