<template>
    <div class="home">
        <div class="home-line">
            <div class="home-box-item">
                <a-tabs v-model:activeKey="activeKey" :animated="false">
                    <a-tab-pane key="1" tab="数据概览">
                        <div class="overview-box">
                            <div class="item">
                                <div class="label">实时有功功率</div>
                                <div class="value">0KW</div>
                            </div>
                            <div class="item">
                                <div class="label">电池堆SOC</div>
                                <div class="value">95%</div>
                            </div>
                            <div class="item">
                                <div class="label">总收益</div>
                                <div class="value">0万元</div>
                            </div>
                            <div class="item">
                                <div class="label">综合效率</div>
                                <div class="value">88.8%</div>
                            </div>
                            <div class="item">
                                <div class="label">总充电量</div>
                                <div class="value">5.7MWh</div>
                            </div>
                            <div class="item">
                                <div class="label">总放电量</div>
                                <div class="value">5.1MWh</div>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="电站信息" force-render>
                        <div class="site-data">
                            <div class="site-data-item">
                                <span class="label">电站名称：</span>
                                <span>{{stationEntity.stationName}}</span>
                            </div>
                            <div class="site-data-item">
                                <span class="label">电站位置：</span>
                                <span>{{stationEntity.address}}</span>
                            </div>
                            <div class="site-data-item"><span class="label">投运时间：</span>
                                <span>{{moment(stationEntity.startTime).format('YYYY-MM-DD')}}</span>
                            </div>
                            <div class="site-data-item">
                                <span class="label">装机功率：</span>
                                <span>{{stationEntity.power}}kW</span>
                            </div>
                            <div class="site-data-item">
                                <span class="label">装机容量：</span>
                                <span>{{stationEntity.capacity}}kWp</span>
                            </div>
                            <div class="site-data-item img">
                                <img src="https://pv.solar.chu.edu.cn/img/4b2ed7fa-b28f-4370-b301-73e07f34c1b9.blob"/>
                            </div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="home-box-item">
                <div class="head ems-box-header">能量流转</div>
                <div class="energy-flow-box">
                    <img src="https://cloudems.skiffenergy.com/img/img-s-factory.c6846854.png">
                </div>
            </div>
            <div class="home-box-item">
                <div class="head ems-box-header">告警状态</div>
                <div class="table-boxs">
                    <div class="alarm-table-box">
                        <div class="table-item table-head">
                            <div>名称</div>
                            <div>状态</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                        <div class="table-item">
                            <div>1#PCS</div>
                            <div>正常</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="echarts-line">
            <div class="">
                <div class="">
                    <div class="head ems-box-header">
                        <div class="left">电量指标</div>
                    </div>
                    <div class="target-main">
                        <div class="target-content">
                            <div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "MonitorHome",
        props:{
            stationEntity: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                activeKey: '1',
                moment,
            }
        }
    }
</script>

<style lang="less" >
    .home {
        .ant-tabs-nav-wrap {
            background: #fff;
        }
    }
    .echarts-line{
        box-shadow: 0 2px 4px 0 rgba(54, 58, 80, 0.32);
    }
</style>
