const UUID = require ( 'uuid' )

/**
 * 转换后台指定日期的 年月日时分秒格式
 * @param fmt
 * @param date
 * @returns {string|number}
 */
function dateFormat( fmt, date ) {
    let dateTime = new Date ( date )
    let year = dateTime.getFullYear ()
    let month = function () {
        return dateTime.getMonth () < 9 ? '0' + (dateTime.getMonth () + 1) : dateTime.getMonth () + 1
    }
    let day = function () {
        return dateTime.getDate () < 10 ? '0' + dateTime.getDate () : dateTime.getDate ()
    }
    let hour = function () {
        return dateTime.getHours () < 10 ? '0' + dateTime.getHours () : dateTime.getHours ()
    }
    let minute = function () {
        return dateTime.getMinutes () < 10 ? '0' + dateTime.getMinutes () : dateTime.getMinutes ()
    }
    let second = function () {
        return dateTime.getSeconds () < 10 ? '0' + dateTime.getSeconds () : dateTime.getSeconds ()
    }
    //获取 年月日
    if ( fmt == 'yyyy-MM-dd' ) return '' + year + '-' + month () + '-' + day ()
    //获取年月
    if ( fmt == 'yyyy-MM' ) return ' ' + year + '-' + month ()
    //获取年
    if ( fmt == 'yyyy' ) return ' ' + year
    //获取月
    if ( fmt == 'MM' ) return ' ' + month
    //获取日
    if ( fmt == 'dd' ) return ' ' + day ()
    //获取昨天
    if ( fmt == 'yesterday' ) return ' ' + day () - 1
    //获取时分秒
    if ( fmt == 'hh-mm-ss' ) return ' ' + hour () + ':' + minute () + ':' + second ()
    //获取时分
    if ( fmt == 'hh-mm' ) return ' ' + hour () + ':' + minute ()
    //获取分秒
    if ( fmt == 'mm-ss' ) return minute () + ':' + second ()
    // 获取时
    if ( fmt == 'hh' ) return dateTime.getHours ()
    //获取分
    if ( fmt == 'mm' ) return minute ()
    //获取秒
    if ( fmt == 'ss' ) return second ()
    //默认时分秒年月日
    return year + '-' + month () + '-' + day () + ' ' + hour () + ':' + minute () + ':' + second ()
}

/**
 * 声明系统当下时间，只保留年月日
 * @param fmt
 * @param date
 * @returns {string|number}
 */
function toDayFormat() {
    let date = new Date ()
    let year = date.getFullYear ()
    let month = date.getMonth () + 1
    let day = date.getDate ()

    //默认时分秒年月日
    return year + '-' + month + '-' + day
}

/**
 * 将指定的文件名进行重组
 * @param fileName
 */
function fileNameTreated( fileName ) {
    let prefix = fileName.substring ( fileName.lastIndexOf ( '.' ) + 1 )
    return UUID.v1 () + '.' + prefix
}

/**
 * Converts an array buffer to a string
 * @param {Uin8} uint8arr | The buffer to convert
 * @param {Function} callback | The function to call when conversion is complete
 */
function uint8ArrayToString( uint8arr, callback ) {
    var bb = new Blob ( [uint8arr] );
    var f = new FileReader ();
    f.onload = function ( e ) {
        callback ( e.target.result );
    };
    f.readAsText ( bb );
}


// 计算两个时间差 dateBegin 开始时间
function timeFn( createTime, changeTime ) {
    let create = new Date ( createTime )
    //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
    var dateEnd = new Date ( changeTime );//获取当前时间
    var dateDiff = dateEnd.getTime () - create;//时间差的毫秒数
    var leave1 = dateDiff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
    var hours = Math.floor ( leave1 / (3600 * 1000) )//计算出小时数
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数
    var minutes = Math.floor ( leave2 / (60 * 1000) )//计算相差分钟数
    //计算相差秒数
    var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
    var seconds = Math.round ( leave3 / 1000 )
    var leave4 = leave3 % (60 * 1000)      //计算分钟数后剩余的毫秒数
    if ( hours === 0 ) {
        return minutes + "分钟" + seconds + "秒"
    }
    var timeFn = hours + "小时" + minutes + "分钟" + seconds + "秒"; //0小时 4 分钟39 秒
    if ( hours < 0 || minutes < 0 || seconds < 0 ) {
        return ''
    }
    return timeFn;
}

/**
 * 计算页面高度
 * @returns {number}
 */
export function tableHeight() {
    let h = document.documentElement.clientHeight - 64 - 50 - 45
    return h //document.documentElement.clientHeight - 150
}

/**
 * 获取本月第一天和最后一天的Date格式
 * @return {{startTime: Date, endTime: Date}}
 */
function getMonthRangTime() {
    //获取当前日期
    let date = new Date ();
    //获取当前年份
    let new_year = date.getFullYear ();
    //获取当前月份
    let new_month = date.getMonth () + 1;
    //取当年当月中的第一天
    let startTime = new Date ( new_year, new_month, 1 )
    //获取每月最后一天的日期
    let endTime = (new Date ( startTime.getTime () - 1000 * 60 * 60 * 24 ));
    startTime = switchDate ( new_year, new_month, startTime );
    endTime = switchDate ( new_year, new_month, endTime );
    let time = {
        startTime,
        endTime
    }
    return time
}

/**
 * 获取本月第一天和最后一天的Date格式
 * @return {{startTime: Date, endTime: Date}}
 */
function getYearRangTime() {
    //获取当前日期
    let date = new Date ();
    //获取当前年份
    let new_year = date.getFullYear ();
    //取当年当月中的第一天
    let startTime = new Date ( new_year, 1, 1 )
    //获取每月最后一天的日期
    let endTime = (new Date ( startTime.getTime () - 1000 * 60 * 60 * 24 ));
    startTime = switchDate ( new_year, 1, startTime );
    endTime = switchDate ( new_year, 12, endTime );
    let time = {
        startTime,
        endTime
    }
    return time
}

/**
 * 组织年月日时间戳格式
 * @param year
 * @param month
 * @param day
 * @return {string}
 */
function switchDate( year, month, day ) {
    return `${year}-${
        month.toString ().length == 1 ? '0' + month : month}-${
        day.getDate ().toString ().length == 1 ? '0' + day.getDate () : day.getDate ()}`;
}

/**
 * 根据值获取距离今天第几天，0表示获取今天日期，正数表示往后推几天，负数表示往前推几天
 * @param value
 */
function getDate( value ) {
    //获取当前日期
    let date = new Date ();
    if ( value == 0 ) {
        return date
    } else {
        //往前推几天
        date.setDate ( date.getDate () + value );
        return date
    }
}

export const format = {
    dateFormat,
    toDayFormat,
    fileNameTreated,
    uint8ArrayToString,
    timeFn,
    tableHeight,
    getMonthRangTime,
    getYearRangTime,
    getDate
}
