<template>
	<div class="big-screen" style="">
		<div class="screen-head">
			<div class="head-center">巢湖学院储能系统</div>
		</div>
		<div class="screen-content">
			<div class="screen-left">
				<div class="amount">
					<div class="header">电量指标</div>
					<div class="amount-content">
						<div class="amount-content-left">
							<div class="img"></div>
							<div class="inner">
								<div class="title"> 总充电量/MWh</div>
								<div class="num">83.51</div>
							</div>
						</div>
						<div class="amount-content-right">
							<div class="out-img"></div>
							<div class="inner">
								<div class="title"> 总充电量/MWh</div>
								<div class="num">83.51</div>
							</div>
						</div>
					</div>
				</div>
				<div class="charge-discharge">
					<div class="header">充放电量</div>
					<div class="charge-discharge-echarts" id="charge-discharge-echarts"></div>
				</div>
				<div class="benefit">
					<div class="header">收益趋势</div>
					<div class="revenue-trends-echarts" id="revenue-trends-echarts"></div>
				</div>
			</div>
			<div class="screen-center">
				<div class="top">
					<div class="one-box">
						<div>电站总数</div>
						<div>5个</div>
					</div>
					<div class="one-box">
						<div>电站总数</div>
						<div>5个</div>
					</div>
					<div class="one-box">
						<div>电站总数</div>
						<div>5个</div>
					</div>
				</div>
				<div class="map">
					<EchartChina ref="mapChina"></EchartChina>
				</div>
			</div>
			<div class="screen-right">
				<div class="money">
					<div class="header">收益指标</div>
					<div class="money-boxs">
						<div class="money-left">
							<div>累计收益（万元）</div>
							<div>22.2</div>
							<div>
								<img src="../../assets/screen/in-money.png">
							</div>
						</div>
						<div class="money-right">
							<div>今日收益（元）</div>
							<div>22.2</div>
							<div>
								<img src="../../assets/screen/out-money.png">
							</div>
						</div>
					</div>
				</div>
				<div class="trend">
					<div class="header">收益排名</div>
					<div class="pai">
						<div class="line">
							<div>休息休息</div>
							<div>2.82万元</div>
						</div>
						<div class="line">
							<div>休息休息</div>
							<div>2.82万元</div>
						</div>
						<div class="line">
							<div>休息休息</div>
							<div>2.82万元</div>
						</div>
						<div class="line">
							<div>休息休息</div>
							<div>2.82万元</div>
						</div>
					</div>
				</div>
				<div class="day-money">
					<div class="header">日总收益</div>
					<div id="system-echarts" class="day-money-echarts"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {income, system} from "@/api/siteMultiEcharts";
import EchartChina from "@/components/EchartChina.vue";
import {api, post} from "@/api/api";

export default {
	name: "Dashboard",
	data() {
		return {}
	},
	components:{
		EchartChina
	},
	// 页面初始化挂载dom
	methods: {
		efficiencyProportion() {
			let salesBox = document.getElementById ( "system-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( system ( {xData, yData} ) )
		},
		//
		revenueTrends(){
			let salesBox = document.getElementById ( "revenue-trends-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( system ( {xData, yData} ) )
		},
		// 初始化地图
		initChinaMap() {
			post(api.GetStationList, {}).then(res=>{
				this.stationEntities = res.data.data
				let mapData = [];
				mapData = res.data.data.map((item) => {
					return {
						name: item.stationName,
						value: [item.longitude, item.latitude],
					};
				});
				this.$nextTick(() => {
					this.$refs.mapChina.initEchartMap(mapData);
				});
			});
		},
		resize(){
			// 刷新当前页面
			this.$router.go(0)
		}
	},
	mounted() {
		this.initChinaMap()
		this.efficiencyProportion ()
		this.revenueTrends()
		let salesBox = document.getElementById ( "charge-discharge-echarts", null, {
			devicePixelRatio: window.devicePixelRatio,
		} );
		let salesBoxVm = this.echarts.init ( salesBox );
		let xData = ['10-14', '10-15', '10-16', '10-17', '10-18', '10-19', '10-20'],
			yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
		salesBoxVm.setOption ( income ( {xData, yData} ) )
		window.addEventListener("resize", this.resize, true);
	},
}
</script>

<style lang="less" scoped>
@import '../../static/dashboard';
</style>
