<template>
	<a-drawer
		:width="700"
		:title="title"
		:visible="visible"
		@close="onClose">
		<div class="drawer-content-box">
			<a-form-model :model="userEntity" ref="userEntity" :rules="rules" layout="vertical">
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item label="姓名" prop="name">
							<a-input v-model:value="userEntity.name" placeholder="请输入姓名" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="手机号" prop="telPhone">
							<a-input v-model:value="userEntity.telPhone" placeholder="请输入手机号"/>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item label="直属上级">
							<a-cascader v-model:value="parentValue" :options="treeData" change-on-select placeholder="请选择直属上级" :load-data="onLoadData" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="性别">
							<a-radio-group v-model:value="userEntity.sex">
								<a-radio :value="1">男</a-radio>
								<a-radio :value="2">女</a-radio>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item label="角色">
							<a-select placeholder="请选择角色" v-model:value="userEntity.roleId">
								<a-select-option :value="role.id" v-for="role in roleEntities">{{role.roleName}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="身份证">
							<a-input v-model:value="userEntity.idCard" placeholder="请输入身份证"/>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item label="邮箱">
							<a-input v-model:value="userEntity.email" placeholder="请输入邮箱"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="地址">
							<a-input v-model:value="userEntity.address" placeholder="请输入地址"/>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item label="学历">
							<a-input v-model:value="userEntity.educationId" placeholder="请输入学历"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="专业">
							<a-input v-model:value="userEntity.major" placeholder="请输入专业"/>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</div>
		<div class="drawer-footer-box">
			<a-button type="primary" @click="saveUser('userEntity')">保存</a-button>
		</div>
	</a-drawer>
</template>

<script>
import {api, post} from "@/api/api";

export default {
	name: "AddUser",
	props: {
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			roleEntities: [],
			userEntity: {},
			parentValue: [],
			treeData: [],
			rules: {
				name: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
				telPhone: [{required: true, message: '手机号不能为空', trigger: 'blur'}],
			},
		}
	},
	methods: {
		show() {
			this.visible = true
			this.treeData = []      // 树清空
			this.checkedKeys = []   // 回显清空
			this.checkedList = []   // 已选清空
			// 加载直属上级的树形结构，首先加载最高领导人，其次通过每级领导人Id异步加载下属列表
			let that = this
			post ( api.GetUserList, {roleId: '20230624387988957155168256'} ).then ( res => {
				res.data.data.forEach ( function ( item, index ) {
					let tree = {label: item.name, value: item.id, isLeaf: false}
					that.treeData.push ( tree )
				} )
			} )
			this.queryRoleList()
		},
		queryRoleList(){
			post(api.GetRoleList, {}).then(res=>{
				this.roleEntities = res.data.data
			})
		},
		// 异步加载树形结构
		onLoadData(val){
			const targetOption = val[val.length - 1];
			post ( api.GetUserList, {parentId: targetOption.value} ).then ( res => {
				let children = []
				if ( res.data.data.length > 0 ) {
					res.data.data.forEach ( function ( item, index ) {
						let option = { label: item.name, value: item.id, isLeaf: false}
						children.push ( option )
					} )
					targetOption.children = children
				}
			} )
		},
		onClose() {
			this.visible = false
			this.userEntity.name = ''
			this.userEntity.telPhone = ''
			this.parentValue = []
			this.sex = ''
		},
		saveUser() {
			this.$refs.userEntity.validate ( ( valid ) => {
				if ( valid ) {
					if ( this.parentValue.length == 0 ) {
						this.$message.error('直属上级不能为空')
						return
					}
					let parentValue = this.parentValue[this.parentValue.length - 1]
					this.userEntity.parentId = parentValue
					post(api.SaveUser, this.userEntity).then(res=>{
						this.$message.success ( "添加成功" );
						this.$refs.userEntity.resetFields ();
						this.onClose ();
						this.$emit ( "reloadList" );
					})
				}
			})
		},
	}
}
</script>

<style scoped>

</style>
