<template>
    <div class="main">
        <div class="battery-warning-detail">
            <div class="head ems-box-header">
                <span class="head-title">
                    <div class="back" @click="back">
                        <a-icon type="left"/>返回
                    </div>
                    <span> 电池预警详情 </span>
                </span>
                <div class="right">
                    最新诊断时间：
                </div>
            </div>
            <div class="content">
                <div class="table">
                    <div class="line">
                        <div class="title">站点名称</div>
                        <div class="titleName">唐山市雨天心地储能电站</div>
                        <div class="title">装机功率 / 容量</div>
                        <div class="value">60Kw / 215KWh</div>
                        <div class="title">投运日期</div>
                        <div class="date">2023-04-25</div>
                    </div>
                    <div class="line">
                        <div class="title">站点地址</div>
                        <div class="address">唐山市雨天心地储能电站</div>
                    </div>
                </div>
            </div>
            <div class="content-result">
                <div class="icon-title"> 诊断结论</div>
                <div class="result">
                    <div class="item">
                        <div class="item-box">
                            <img src="../../assets/high.png"/>
                        </div>
                        <div class="item-desc">
                            <a-icon type="check-circle"/>
                            <span class="item-desc-text">SOC一致性异常-偏高</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-box">
                            <img src="../../assets/low.png"/>
                        </div>
                        <div class="item-desc">
                            <a-icon type="check-circle"/>
                            <span class="item-desc-text">SOC一致性异常-偏低</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-box">
                            <img src="../../assets/misc.png"/>
                        </div>
                        <div class="item-desc">
                            <a-icon type="check-circle"/>
                            <span class="item-desc-text">微短路</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-box">
                            <img src="../../assets/vlow.png"/>
                        </div>
                        <div class="item-desc">
                            <a-icon type="check-circle"/>
                            <span class="item-desc-text">电池电量不足</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-box">
                            <img src="../../assets/batt-exce.png"/>
                        </div>
                        <div class="item-desc">
                            <a-icon type="check-circle"/>
                            <span class="item-desc-text">电连接异常</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-box">
                            <img src="../../assets/bms-exec.png"/>
                        </div>
                        <div class="item-desc">
                            <a-icon type="check-circle"/>
                            <span class="item-desc-text">BMS采集故障</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-detail">
                <div class="icon-title">
                    <span style="margin-right: 10px">诊断明细</span>
                    <a-button type="primary" size="small">导出</a-button>
                </div>
                <div class="content-table">
                    <a-table :columns="columns" :data-source="data"></a-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BatteryWarningDetail",
        data(){
            return{
                columns: [
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    { title: 'Age', dataIndex: 'age', key: 'age' },
                    { title: 'Address', dataIndex: 'address', key: 'address' },
                    { title: 'Action', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' } },
                ],
                data: [
                    {
                        key: 1,
                        name: 'John Brown',
                        age: 32,
                        address: 'New York No. 1 Lake Park',
                        description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
                    },
                    {
                        key: 2,
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No. 1 Lake Park',
                        description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
                    },
                    {
                        key: 3,
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney No. 1 Lake Park',
                        description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.',
                    },
                ],
            }
        },
        methods: {
            back() {
                this.$router.push('/batteryWarning')
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/batteryWarningDetail";
</style>
