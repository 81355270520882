<template>
	<div class="add-device-box">
		<a-modal v-model:visible="visible" title="绑定设备" @ok="saveDevice" width="50%">
			<a-form-model :model="deviceEntity" :rules="rules" ref="ruleForm">
				<div style="display: flex; justify-content: space-between">
					<a-form-model-item label="设备类型" prop="type" style="width: 45%">
						<a-radio-group v-model:value="deviceEntity.type" @change="changeType">
							<a-radio :value="1">主设备</a-radio>
							<a-radio :value="2">从设备</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="上级设备" prop="parentId" v-if="showParent" style="width: 45%">
						<a-select v-model:value="deviceEntity.parentId" placeholder="请选择主设备">
							<a-select-option v-for="device in deviceEntities" :value="device.id">{{device.deviceName}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</div>
				<div style="display: flex; justify-content: space-between">
					<a-form-model-item label="设备名称" prop="deviceName" style="width: 45%">
						<a-input v-model="deviceEntity.deviceName"></a-input>
					</a-form-model-item>
					<a-form-model-item label="设备地址" prop="deviceAddress" style="width: 45%">
						<a-input v-model="deviceEntity.deviceAddress"></a-input>
					</a-form-model-item>
				</div>
				<div style="display: flex; justify-content: space-between">
					<a-form-model-item label="设备登录用户名" prop="userName" style="width: 45%">
						<a-input v-model="deviceEntity.userName"></a-input>
					</a-form-model-item>
					<a-form-model-item label="设备登录密码" prop="password" style="width: 45%">
						<a-input v-model="deviceEntity.password"></a-input>
					</a-form-model-item>
				</div>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import {post, api} from "@/api/api";

export default {
	name: "AddDevice",
	data() {
		return {
			stationId: '',
			visible: false,
			deviceEntities: [],
			deviceEntity: {},
			showParent: true,
			rules: {
				deviceName: [
					{required: true, message: "请填写设备名称", trigger: "blur",},
					{max: 10, message: "设备名称不能超过10字", trigger: "blur",},
				],
				deviceAddress: [
					{required: true, message: "请填写设备地址", trigger: "blur",},
					{max: 10, message: "设备地址不能超过10字", trigger: "blur",},
				],
				userName: [
					{required: true, message: "请填写设备登录用户名", trigger: "blur",},
					{max: 10, message: "设备登录用户名不能超过10字", trigger: "blur",},
				],
				password: [
					{required: true, message: "请填写设备登录密码", trigger: "blur",},
					{max: 10, message: "设备登录密码不能超过10字", trigger: "blur",},
				],
			},
		}
	},
	methods: {
		getShow( stationId, show ) {
			this.stationId = stationId
			this.visible = show
		},
		//选择主从类型
		changeType() {
			if ( this.deviceEntity.type == 1 ) {
				this.showParent = false
			} else {
				this.showParent = true
				this.queryList()
			}
		},
		queryList() {
			post(api.DeviceEntities, {type: 1, stationId: this.stationId}).then(res=>{
				this.deviceEntities = res.data.data
			})
		},
		saveDevice() {
			this.$refs.ruleForm.validate ( ( valid ) => {
				if ( valid ) {
					this.deviceEntity.stationId = this.stationId
					post ( api.SaveDevice, this.deviceEntity ).then ( res => {
						this.visible = false
						this.deviceEntity.stationId = ''
						this.deviceEntity.userName = ''
						this.deviceEntity.deviceAddress = ''
						this.deviceEntity.deviceName = ''
						this.deviceEntity.password = ''
						this.deviceEntity.parentId = ''
					} )
				}
			} )
		}
	}
}
</script>

<style scoped>
/deep/ .ant-form-item {
	margin-bottom: 0;
}
</style>
