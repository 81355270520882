/**
 * 由于tomcat版本问题导致上传文件必须使用 http 协议
 * 不能使用https协议，如不产生文件上传功能这里和小程序一致都可以使用https协议
 * @type {string}
 */
export let baseUrl = 'http://manager.klapower.chu.edu.cn'
// export let baseUrl = 'http://localhost:8849'


/**
 * 图片格式
 * @type {{FILE_TYPE_VIDEO: number, FILE_TYPE_BANNER: number, FILE_TYPE_IMG: number, FILE_TYPE_WORKS: number}}
 */
export let ConstUtil = {
    // 文件格式魔法值
    PAPER_TEMPLATE_SYMBOL: 1,
    FILE_BANNER_IMG: 2,


    // 上传文件地址
    UPLOAD_FILE_URL: baseUrl + '/file/uploadFile',


    /******************************************
     *
     * 后端返回的结果魔法
     *
     ******************************************/
    SUCCESS: '000000',//执行成功

    SESSION_EXPIRE: '000600',// ( "000600", "连接过期" ),

    ERROR: '999999',// ( "999999", "系统错误" );
}
