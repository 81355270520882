import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import axios from 'axios'
import 'ant-design-vue/dist/antd.css';
import router from './router'
import store from './store'
import * as echarts from 'echarts';

Vue.prototype.echarts = echarts
Vue.config.productionTip = false
Vue.use(Antd);

// 设置拦截器，添加请求头
axios.interceptors.request.use(config => {
    config.headers.Token = window.sessionStorage.getItem('token')
    return config
})
// 设置拦截器处理后台的返回状态
axios.interceptors.response.use(function (response) {
    if (response.data.code === '000401') {
        router.push('/login')
    } else if (response.data === '000403') { // SpringBoot拦截器返回的IO流000403,直接取data数据
        console.error('403 权限不足')
    } else if (response.data === '000600') { // Redis过期
        console.log('000600 session 过期')
        router.push('/login')
    } else if (response.data.code === '999999') {
        console.log(response.data.message)
        this.message.error(response.data.message)
    }
    return response
}, function (error) {
    console.log('vue响应拦截器异常' + error)
    return error
})
Vue.prototype.http = axios

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
