<template>
    <div class="voltage">
        <div class="voltage-curve">
            电压曲线
        </div>
        <div class="voltage-table">
            <a-table :columns="voltageColumns" :data-source="voltageData">
                <template #action>
                    <a>删除</a>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Module",
        props: {
            voltageColumns: {
                type: Array,
                default: []
            },
            voltageData: {
                type: Array,
                default: []
            }
        }
    }
</script>

<style scoped>

</style>
