import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import Login from "@/views/user/Login"
import Index from "@/views/dashboard/Index"
import Dashboard from "@/views/dashboard/Dashboard"
import SiteMulti from "@/views/SiteOverview/SiteMulti"
import SiteSingle from "@/views/SiteOverview/SiteSingle"
import SiteMap from "@/views/SiteOverview/SiteMap"
import SiteCard from "@/views/SiteOverview/SiteCard"
import RealStation from "@/views/SiteMonitoring/RealStation"
import SingleMonitoring from "@/views/SiteMonitoring/SingleMonitoring"
import VideoMonitoring from "@/views/SiteMonitoring/VideoMonitoring"
import FireMonitoring from "@/views/SiteMonitoring/FireMonitoring"
import ChargeAnalysis from "@/views/BatteryAnalysis/ChargeAnalysis"
import VoltageAnalysis from "@/views/BatteryAnalysis/VoltageAnalysis"
import CurrentAnalysis from "@/views/BatteryAnalysis/CurrentAnalysis"
import TemperatureAnalysis from "@/views/BatteryAnalysis/TemperatureAnalysis"
import UniformityAnalysis from "@/views/BatteryAnalysis/UniformityAnalysis"
import SocAnalysis from "@/views/BatteryAnalysis/SocAnalysis"
import SohAnalysis from "@/views/BatteryAnalysis/SohAnalysis"
import FallBattery from "@/views/BatteryAnalysis/FallBattery"
import BatteryWarning from "@/views/FaultManager/BatteryWarning"
import BatteryWarningDetail from "@/views/FaultManager/BatteryWarningDetail"
import AlarmList from "@/views/FaultManager/AlarmList"
import AlarmStatistics from "@/views/FaultManager/AlarmStatistics"
import FaultTracing from "@/views/FaultManager/FaultTracing"
import ElectricReport from "@/views/report/ElectricReport"
import EarningsReport from "@/views/report/EarningsReport"
import ResponseManager from "@/views/SiteManager/ResponseManager"
import CentralizedManager from "@/views/SiteManager/CentralizedManager"
import ElectricityPrice from "@/views/SiteManager/ElectricityPrice"
import MeterConfig from "@/views/SiteManager/MeterConfig"
import UserList from "@/views/user/UserList"
import RoleList from "@/views/user/RoleList"
import MenuList from "@/views/system/MenuList"
import MerchantList from "@/views/system/MerchantList"
import Order from "@/views/Operation/Order"

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//重复点击同一个路由
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    //mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: Login},
        {path: '/dashboard', name: '数据看板', component: Dashboard},
        {
            path: '/index', component: Index, redirect: '/multi',
            children: [
                {path: '/multi', name: '电站管理 / 多站概况', component: SiteMulti},
                {path: '/single', name: '电站管理 / 单站概况', component: SiteSingle},
                {path: '/map', name: '电站管理 / 站点地图', component: SiteMap},
                {path: '/card', name: '电站管理 / 站点卡片', component: SiteCard},
                {path: '/real', name: '站点监控 / 实时运行', component: RealStation},
                {path: '/singleMonitor', name: '站点监控 / 单站监控', component: SingleMonitoring},
                {path: '/videoMonitoring', name: '站点监控 / 视频监控', component: VideoMonitoring},
                {path: '/fireMonitoring', name: '站点监控 / 消防监控', component: FireMonitoring},
                {path: '/chargeAnalysis', name: '电池分析 / 充放电分析', component: ChargeAnalysis},
                {path: '/voltageAnalysis', name: '电池分析 / 电压分析', component: VoltageAnalysis},
                {path: '/currentAnalysis', name: '电池分析 / 电流分析', component: CurrentAnalysis},
                {path: '/temperatureAnalysis', name: '电池分析 / 温度分析', component: TemperatureAnalysis},
                {path: '/uniformityAnalysis', name: '电池分析 / 一致性分析', component: UniformityAnalysis},
                {path: '/socAnalysis', name: '电池分析 / SOC分析', component: SocAnalysis},
                {path: '/sohAnalysis', name: '电池分析 / SOH分析', component: SohAnalysis},
                {path: '/fallBattery', name: '电池分析 / 落后电池报表', component: FallBattery},
                {path: '/batteryWarning', name: '故障管理 / 电池预警', component: BatteryWarning},
                {path: '/batteryWarning/:id', name: '故障管理 / 电池预警 / 电池预警详情', component: BatteryWarningDetail},
                {path: '/alarmList', name: '故障管理 / 报警列表', component: AlarmList},
                {path: '/alarmStatistics', name: '故障管理 / 报警统计', component: AlarmStatistics},
                {path: '/faultTracing', name: '故障管理 / 故障追踪', component: FaultTracing},
                {path: '/electricReport', name: '统计报表 / 电量报表', component: ElectricReport},
                {path: '/earningsReport', name: '统计报表 / 收益报表', component: EarningsReport},
                {path: '/response', name: '站点管理 / 响应管理', component: ResponseManager},
                {path: '/centralized', name: '站点管理 / 集控管理', component: CentralizedManager},
                {path: '/electricityPrice/:id', name: '站点管理 / 集控管理 / 电价配置', component: ElectricityPrice},
                {path: '/meter/:id', name: '站点管理 / 集控管理 / 电表配置', component: MeterConfig},
                {path: '/user', name: '人员管理 / 用户列表', component: UserList},
                {path: '/role', name: '人员管理 / 角色列表', component: RoleList},
                {path: '/menu', name: '系统管理 / 菜单列表', component: MenuList},
                {path: '/merchant', name: '系统管理 / 商户列表', component: MerchantList},
                {path: '/order', name: '运维管理 / 工单', component: Order},
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    const token = window.sessionStorage.getItem('token')
    store.dispatch('asyncSetRouterName', {
        routerName: to.name
    })
    next()
})

export default router
