<template>
	<div class="main">
		<div class="top-panel">
			<div class="">
				<a-select
					size="small"
					style="width: 200px"
				></a-select>
			</div>
			<div class="btn">
				<a-button type="primary" @click="addUser">添加</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table :columns="columns" :data-source="data" :loading="loading" :rowKey="data => data.id" :pagination="false" :scroll="{ x: 1000, y: tableHeight }">
				<template slot="createTime" slot-scope="data">
					{{moment(data.createTime).format('YYYY-MM-DD')}}
				</template>
				<template slot="operation" slot-scope="slotData">
					<a @click="setPermission(slotData)">赋权</a>
				</template>
			</a-table>
			<div class="page-box">
				<a-pagination
						size="small"
						:showQuickJumper="true"
						:showSizeChanger="true"
						:current="page.current"
						:pageSize="page.pageSize"
						:total="page.total"
						:show-total="(total) => `共${total}条`"
						@showSizeChange="onShowSizeChange"
						@change="changePageSize"
				></a-pagination>
			</div>
		</div>
		<RolePermission ref="permission" :title="title"></RolePermission>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import RolePermission from "@/views/user/RolePermission";
import moment from "moment";

export default {
	name: "RoleList",
	components: {RolePermission},
	data() {
		return {
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			loading: true,
			columns: [
				{title: '角色名称', dataIndex: 'roleName', key: 'roleName'},
				{title: '角色描述', dataIndex: 'roleDesc', key: 'roleDesc'},
				{title: '是否默认', dataIndex: 'isDefault', key: 'isDefault'},
				{title: '商户', dataIndex: 'merchantId', key: 'merchantId'},
				{title: '创建时间', scopedSlots: {customRender: 'createTime'}},
				{title: '操作', scopedSlots: {customRender: 'operation'}},
			],
			data: [],
			title: '分配角色权限',
			moment,
			tableHeight: 0,
		}
	},
	created() {
		post ( api.RoleEntities, {} ).then ( res => {
			this.data = res.data.data
			this.page.total = res.data.count
			this.loading = false
		} )
	},
	mounted() {
		// 设置表格y轴滚动条区域的高度，其中236包含顶部、按钮面板、分页面板以及表格header高度
		this.tableHeight = window.innerHeight - 236
	},
	methods: {
		addUser() {

		},
		setPermission(role){
			this.$refs.permission.show(true, role)
		},
		onShowSizeChange ( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		},
		changePageSize(current, pageSize){
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		}
	}
}
</script>

<style scoped>

</style>
