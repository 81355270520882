import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let state = {
    routerName: ''
}

let mutations = {
    setRouterName(state, routerName) {
        state.routerName = routerName
    }
}

let actions = {
    asyncSetRouterName(context, routerName) {
        context.commit('setRouterName', routerName)
    }
}
const store = new Vuex.Store({
    state,
    mutations,
    actions,
})
export default store;
