<template>
	<a-drawer
		placement="top"
		:closable="false"
		:visible="visible"
		height="100%"
		wrapClassName="add-power"
		@close="closeTopDrawer"
		:destroyOnClose="true"
		:headerStyle="{ background: '#f0f1f5',padding: '0',boxShadow: '0 0 3px 10px #d6d6d6'}"
		:bodyStyle="{padding: '0.3rem 0 0 0',}"
	>
		<template #title>
			<div class="head-box">
				<span>{{title}}</span>
				<div class="head-btn">
					<a-button @click="closeTopDrawer">取消</a-button>
					<a-button type="primary" class="save-btn" @click="saveStation">保存</a-button>
				</div>
			</div>
		</template>
		<div class="content-box">
			<div class="right-box">
				<a-form-model :model="form" :rules="rules" ref="ruleForm">
					<!-- 基础信息 -->
					<div class="one-peace">
						<div class="title">
							<span>基础信息</span>
							<div class="collspan" @click="showBox(1)">
								<span>{{ flagList.includes ( 1 ) ? "展开" : "收起" }}</span>
								<a-icon :type="flagList.includes(1) ? 'down' : 'up'"/>
							</div>
						</div>
						<div class="onepeace-detail" :class="flagList.includes(1) ? 'close-box' : ''">
							<a-form-model-item label="电站名称" prop="stationName">
								<a-input v-model="form.stationName"></a-input>
							</a-form-model-item>
							<a-form-model-item label="电站位置" prop="region">
								<div class="map-container" id="map-container">
									<Map @getMapInfo="getMapInfo" :lng-lat="lngLat"></Map>
								</div>
							</a-form-model-item>
							<a-form-model-item label="区域" required>
								<div class="area-select">
									<!-- 国 -->
									<a-form-model-item prop="countryId">
										<a-select :value="form.countryId">
											<a-select-option key="20230705392153575054249984">
												中国
											</a-select-option>
										</a-select>
									</a-form-model-item>
									<!-- 省 -->
									<a-form-model-item prop="provinceId" required>
										<a-select v-model="form.provinceId">
											<a-select-option :key="provice.id" v-for="provice in proviceList">
												{{ provice.areaName }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
									<!-- 市 -->
									<a-form-model-item prop="cityId" required v-if="proviceList.length > 0 && cityList.length > 0">
										<a-select v-model="form.cityId">
											<a-select-option :key="city.id" v-for="city in cityList">
												{{ city.areaName }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
									<!-- 区 -->
									<a-form-model-item prop="countyId" required v-if="cityList.length > 0 && countyList.length > 0">
										<a-select v-model="form.countyId">
											<a-select-option :key="county.id" v-for="county in countyList">
												{{ county.areaName }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</div>
							</a-form-model-item>
							<a-form-model-item label="地址" prop="address">
								<a-input v-model="form.address"></a-input>
							</a-form-model-item>
							<a-form-model-item label="经纬度" required>
								<div class="lng-box">
									<span>经度</span>
									<a-form-model-item prop="longitude">
										<a-input v-model="form.longitude"></a-input>
									</a-form-model-item>
									<span>纬度</span>
									<a-form-model-item prop="latitude">
										<a-input v-model="form.latitude"></a-input>
									</a-form-model-item>
								</div>
							</a-form-model-item>
						</div>
					</div>
					<!-- 系统信息 -->
					<div class="one-peace">
						<div class="title">
							<span>系统信息</span>
							<div class="collspan" @click="showBox(2)">
								<span>{{ flagList.includes ( 2 ) ? "展开" : "收起" }}</span>
								<a-icon :type="flagList.includes(2) ? 'down' : 'up'"/>
							</div>
						</div>
						<div class="onepeace-detail" :class="flagList.includes(2) ? 'close-box' : ''">
							<div class="type-box">
								<div class="one-type">
									<a-form-model-item label="装机容量(kWp)" prop="capacity">
										<a-input placeholder="0.01~1000000" v-model="form.capacity"></a-input>
									</a-form-model-item>
								</div>
								<div class="one-type">
									<div class="type-label">
										投运日期：
									</div>
									<a-date-picker v-model="form.startTime" :allowClear="false" style="width: 100%" />
								</div>
							</div>
							<div class="type-box">
								<div class="one-type">
									<a-form-model-item label="装机功率" prop="power">
										<a-input placeholder="0.01~1000000" v-model="form.power"></a-input>
									</a-form-model-item>
								</div>
								<div class="one-type">
									<a-form-model-item label="建设场所" prop="place">
										<a-input placeholder="请输入具体建设场所" v-model="form.place" style="width: 100%"></a-input>
									</a-form-model-item>
								</div>
							</div>
							<div class="type-box">
								<div class="one-type">
									<a-form-model-item label="EMS类型" prop="emsType">
										<a-select class="w400" v-model="form.emsType">
											<a-select-option :key="emsType.id" v-for="emsType in emsTypeList">
												{{ emsType.label }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</div>
							</div>
						</div>
					</div>
				</a-form-model>
			</div>
		</div>
	</a-drawer>
</template>

<script>
import moment from "moment";
import Map from "./Map.vue";
import {validatorTel} from "@/utils/validate.js";
import {api, post} from "@/api/api";

export default {
	name: "AddStation",
	props: {
		title: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		topDrawer: {
			type: Boolean,
			default: false
		}
	},
	components: {Map},
	data() {
		const validatorCapacity = ( rule, value, callback ) => {
			if ( value >= 0.01 && value <= 100000000 ) {
				callback ();
			} else {
				return callback ( new Error ( "请填写正确的装机容量" ) );
			}
		};
		const validatorPower = ( rule, value, callback ) => {
			if ( value >= 0.01 && value <= 100000000 ) {
				callback ();
			} else {
				return callback ( new Error ( "请填写正确的装机功率" ) );
			}
		};
		return {
			visible: false,
			lngLat: [], // 编辑时回显的经纬度值
			form: {
				countryId: '20230705392153575054249984',	// 国家
				address: '',
				longitude: 0,
				latitude: 0,
				type: 1, //新增电站所需
			},
			nationList: [], //国家列表
			proviceList: [], //省份列表
			cityList: [], //城市列表
			countyList: [], //区列表
			emsTypeList: [
				{id: 1, label: "轻EMS"},
			],
			rules: {
				stationName: [
					{required: true, message: "请填写电站名称", trigger: "blur",},
					{max: 30, message: "电站名称不能超过30字", trigger: "blur",},
				],
				provinceId: [
					{required: true, message: "请选择省份", trigger: ["blur", "change"],},
				],
				cityId: [
					{required: true, message: "请选择所在城市", trigger: ["blur", "change"],},
				],
				countyId: [
					{required: true, message: "请选择所在区县", trigger: ["blur", "change"],},
				],
				address: [
					{required: true, message: "请填写具体位置", trigger: "blur",},
					{max: 100, message: "地址名称不能超过100字", trigger: "blur",},
				],
				longitude: [
					{required: true, message: "请填写完整经度", trigger: "blur",},
				],
				latitude: [
					{required: true, message: "请填写完整经度", trigger: "blur",},
				],
				capacity: [
					{required: true, message: "请填写装机容量(kWp)", trigger: "blur",},
					{validator: validatorCapacity, trigger: "blur"},
				],
				power: [
					{required: true, message: "请填写装机功率(kWh)", trigger: "blur",},
					{validator: validatorPower, trigger: "blur"},
				],
				telPhone: [
					{required: true, message: "请填写业主手机号", trigger: "blur",},
					{validator: validatorTel, trigger: "blur"},
				],
			},
			moment,
			flagList: [],
		}
	},
	watch: {
		async id(value) {
			if ( value ) {
				await post(api.StationSingle, {id: value}).then(res=>{
					this.form = res.data.data
					this.form.startTime = moment(res.data.data.startTime, "yyyy-MM-DD");
					this.lngLat = [res.data.data.longitude, res.data.data.latitude]
					this.form.countryId = '20230705392153575054249984'
				})
			}
		},
		async topDrawer(visible){
			if ( visible ) {
				this.form.countryId = '20230705392153575054249984'  // 默认选择中国
			}
			this.visible  = visible
		},
		async "form.countryId"(newv) {
			if ( this.visible && newv ) {
				const res = await this.getAreaList(newv);
				this.proviceList = res.data.data;
			}
		},
		async "form.provinceId"(newv) {
			this.cityList = [];
			if ( this.visible ) {
				const res = await this.getAreaList(newv);
				this.cityList = res.data.data;
			}
		},
		async "form.cityId"(newv) {
			this.countyList = [];
			if ( this.visible ) {
				const res = await this.getAreaList(newv);
				this.countyList = res.data.data;
			}
		},
	},
	methods: {
		// 获取国家
		async getAreaList( id ) {
			let data = await post(api.AreaEntities, {parentId: id}).then(res=>{
				return res
			})
			return data
		},
		closeTopDrawer() {
			this.$refs.ruleForm.resetFields ();
			this.resetForm ();
			this.$emit("closeTopDrawer");
		},
		showBox( index ) {
			if ( this.flagList.includes ( index ) ) {
				let i = this.flagList.findIndex ( ( item ) => {
					return item == index;
				} );
				this.flagList.splice ( i, 1 );
			} else {
				this.flagList.push ( index );
			}
		},
		getMapInfo( info ) {
			this.form.address = info.value.address
			this.form.longitude = info.value.lng
			this.form.latitude = info.value.lat
		},
		// 保存电站信息
		saveStation() {
			this.$refs.ruleForm.validate ( ( valid ) => {
				if ( valid ) {
					const submitData = JSON.parse ( JSON.stringify ( this.form ) );
					if ( this.id ) {
						submitData.id = this.id;
					}
					let requestApi = this.id ? api.UpdateStation : api.SaveStation;
					post ( requestApi, submitData ).then ( ( res ) => {
						if ( res.data.code == "000000" ) {
							this.$message.success ( {
								content: `${this.id ? "修改" : "添加"}电站成功`,
							} );
							this.closeTopDrawer()
						} else {
							this.$message.error ( {
								content: res.message,
							} );
						}
					} ).catch ( ( err ) => {
						console.log ( err );
					} );
				} else {
					return this.$message.warning ( {
						content: "请填写完整信息",
					} );
				}
			} );
		},
		// 重置信息
		resetForm() {
			let form = {
				stationName: "", //电站名称
				longitude: "",
				latitude: "",
				createTime: moment (), //建站时间
				countryId: "", //国家id
				provinceId: "", //省份id
				cityId: "", //市区id
				countyId: "", //区id
				type: 1, //新增电站所需
			};
			this.form = form
			this.visible = false;
			this.$emit("reloadList");
		},
	},
}
</script>

<style lang="less" scoped>
@import "../../../static/addStation";
</style>
