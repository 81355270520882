<template>
    <div class="main">
        <div class="search-pane">
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
        </div>
        <div class="temp-tabs table-box">
            <a-tabs v-model:activeKey="activeTab">
                <a-tab-pane key="1" tab="电池簇温度预警">
                    <div class="battery-pile">
                        <div class="title">电池簇温差分析</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="battery-stack-echarts">
                            电堆电压预警报表
                        </div>
                    </div>
                    <div class="battery-pile">
                        <div class="title">电池簇温差预警</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="battery-stack-echarts">
                            电堆电压预警报表
                        </div>
                    </div>
                    <div class="battery-pile">
                        <div class="title">电池簇单体升温预警</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="battery-stack-echarts">
                            电堆电压预警报表
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="单体温度分析">
                    <div class="single-temp-analysis">
                        <div class="temp-analysis">
                            <div class="title">电池簇单体升温预警</div>
                            <div class="title-box">
                                <div class="voltage-search">
                                    <div class="search-item-box">
                                        <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                        <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                                    </div>
                                </div>
                                <div class="curve-btn">
                                    <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                    <a-button type="default" class="reset-btn" size="small">导出</a-button>
                                </div>
                            </div>
                            <div class="battery-stack-echarts">
                                电堆电压预警报表
                            </div>
                        </div>
                    </div>
                    <div class="single-temp-analysis">
                        <div class="temp-analysis">
                            <div class="title">单体温度曲线</div>
                            <div class="title-box">
                                <div class="voltage-search">
                                    <div class="search-item-box">
                                        <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                        <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                                    </div>
                                </div>
                                <div class="curve-btn">
                                    <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                    <a-button type="default" class="reset-btn" size="small">导出</a-button>
                                </div>
                            </div>
                            <div class="battery-stack-echarts">
                                电堆电压预警报表
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TemperatureAnalysis",
        data() {
            return {
                activeTab: '1'
            }
        }
    }
</script>

<style lang="less">
@import "../../static/temperatureAnalysis";
</style>
