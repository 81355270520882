// 柱状图
export const income = ( data ) => {
    return {
        dataZoom: [{
            type: 'inside'  // 缩放
        }],
        animationDurationUpdate: 0,
        color: ['#0190ef'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    //color: '#999'
                }
            },
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: data.xData
        },
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        //color: ['#273a62'],
                        width: 1,
                        type: 'dashed'
                    }
                }
            }
        ],
        // 鼠标悬浮的显示modal
        series: [
            {
                name: '收益',
                data: data.yData,
                type: 'bar',
                barWidth: 25,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                },
            },
        ]
    }
}


//充放电记录
export const chargeRecords = ( data ) => {
    return {
        dataZoom: [{
            type: 'inside'  // 缩放
        }],
        animationDurationUpdate: 0,
        color: ['#0190ef','#28d4ff'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                }
            },
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: data.xData
        },
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        width: 1,
                        type: 'dashed'
                    }
                }
            }
        ],
        // 鼠标悬浮的显示modal
        series: [
            {
                name: '充电量',
                type: 'bar',
                barWidth: 25,
                emphasis: {
                    focus: 'series'
                },
                data: data.yData
            },
            {
                name: '放电量',
                type: 'bar',
                barWidth: 25,
                emphasis: {
                    focus: 'series'
                },
                data: data.zData
            },
        ]
    }
}


// 系统效率折线图
export const system = ( data ) => {
    return {
        dataZoom: [{
            type: 'inside'  // 缩放
        }],
        animationDurationUpdate: 0,
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.xData
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    //color: ['#273a62'],
                    width: 1,
                    type: 'dashed'
                }
            }
        },
        series: [
            {
                name: '采购额',
                type: 'line',
                smooth: true,
                data: data.yData
            }
        ]
    }
}

/**
 * 系统效率占比图
 * @param data
 * @returns {{polar: [{center: string[], radius: string}], backgroundColor: string, radiusAxis: {axisLabel: {show: boolean}, axisLine: {show: boolean}, show: boolean, axisTick: {show: boolean}, type: string}, series: [{axisLabel: {distance: number, color: string, show: boolean, fontSize: number}, min: number, startAngle: number, max: number, axisLine: {lineStyle: {color: [[number, string]], width: number}}, endAngle: number, splitLine: {distance: number, lineStyle: {color: string}, length: number}, axisTick: {distance: number, lineStyle: {color: string}, length: number}, splitNumber: number, type: string, radius: string}, {barWidth: number, data: [number], showBackground: boolean, backgroundStyle: {color: string, borderWidth: number}, roundCap: boolean, itemStyle: {normal: {color: *, shadowBlur: number, opacity: number, shadowColor: string}}, coordinateSystem: string, type: string, polarIndex: number}], tooltip: {formatter: (function(*): string)}, title: {itemGap: number, subtext: string, top: string, left: string, text: string, textStyle: {color: string, fontSize: number}, subtextStyle: {color: string, fontSize: number}}, graphic: [{top: string, left: string, z: number, style: {text: string, fill: string, font: string}, type: string}], angleAxis: {max: number, clockwise: boolean, show: boolean}}}
 */
export const efficiencyProportion = ( data ) => {
    return {
        dataZoom: [{
            type: 'inside'  // 缩放
        }],
        angleAxis: {
            max: 100, // 满分
            clockwise: false, // 逆时针
            // 隐藏刻度线
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            },
            startAngle: 180
        },
        radiusAxis: {
            type: 'category',
            // 隐藏刻度线
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        polar: {
            center: ['50%', '30%'],
            radius: '100%' //图形大小
        },
        series: [{
            type: 'bar',
            data: [{
                name: '系统效率',
                value: 30,
                itemStyle: {
                    normal: {
                        color: { // 完成的圆环的颜色
                            colorStops: [{
                                offset: 0,
                                color: '#48FFFF' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#48FFFF' // 100% 处的颜色
                            }]
                        }
                    }
                },
            }],
            coordinateSystem: 'polar',
            roundCap: true,
            barWidth: 18,
            barGap: '-100%', // 两环重叠
            radius: ['49%', '52%'],
            z: 2,
        }, { // 灰色环
            type: 'bar',
            data: [{
                value: 50,
                itemStyle: {
                    color: '#3B4068',
                }
            }],
            coordinateSystem: 'polar',
            roundCap: true,
            barWidth: 26,
            barGap: '-123%', // 两环重叠
            radius: ['48%', '53%'],
            z: 1
        }, {
            name: '圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [30],
            symbolSize: 34,
            coordinateSystem: 'polar',
            roundCap: true,
            barGap: '-80%', // 两环重叠
            radius: ['48%', '53%'],
            z: 2,
            itemStyle: {
                normal: {
                    color: '#48FFFF',
                    opacity: 1,
                }
            }
        }]
    }
}

/*设备饼图*/
export const devicePip = ( data ) => {
    return {
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
            {
                type: 'pie',      //type为pie，表示图表为饼图
                data: [
                    {
                        value: 335,
                        name: '直接访问'
                    },
                    {
                        value: 234,
                        name: '联盟广告'
                    },
                    {
                        value: 1548,
                        name: '搜索引擎'
                    }
                ]
            }
        ]
    };
}
