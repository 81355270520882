<template>
	<a-drawer
		:width="500"
		:title="title"
		:visible="visible"
		@close="onClose">
		<div class="drawer-content-box">
			<a-form-model :model="merchantEntity" :rules="rules" ref="merchantEntity" layout="vertical">
				<a-form-model-item label="商户名称" prop="merchantName">
					<a-input v-model="merchantEntity.merchantName" placeholder="请输入商户名称"/>
				</a-form-model-item>
				<a-form-model-item label="联系电话" prop="telPhone">
					<a-input v-model:value="merchantEntity.telPhone" placeholder="请输入联系电话"/>
				</a-form-model-item>
				<a-form-model-item label="联系人" prop="contact">
					<a-input v-model:value="merchantEntity.contact" placeholder="请输入联系人"/>
				</a-form-model-item>
				<a-form-model-item label="上机商户" prop="parentId">
					<a-tree-select placeholder="请选择上机商户" v-model:value="merchantEntity.parentId" :treeData="treeData">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="邮件数量">
					<a-input v-model:value="merchantEntity.emailNumber" placeholder="请输入邮件数量"/>
				</a-form-model-item>
				<a-form-model-item label="短信数量">
					<a-input v-model:value="merchantEntity.messageNumber" placeholder="请输入短信数量"/>
				</a-form-model-item>
				<a-form-model-item label="所在地址">
					<a-textarea v-model:value="merchantEntity.address"/>
				</a-form-model-item>
				<a-form-model-item label="描述">
					<a-textarea v-model:value="merchantEntity.merchantDesc"/>
				</a-form-model-item>
			</a-form-model>
		</div>
		<div class="drawer-footer-box">
			<a-button type="primary" @click="saveMenu('merchantEntity')">保存</a-button>
		</div>
	</a-drawer>
</template>

<script>
import {api, post} from "@/api/api";

export default {
	name: "AddMerchant",
	props: {
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			merchantEntity: {},
			treeData: [],
			checkedKeys: [],
			checkedList: [],
			rules: {
				merchantName: [{required: true, message: '商户名称不能为空', trigger: 'blur'}],
				telPhone: [{required: true, message: '联系电话不能为空', trigger: 'blur'}],
				contact: [{required: true, message: '联系人不能为空', trigger: 'blur'}],
			},
		}
	},
	methods: {
		show() {
			this.visible = true
			this.treeData = []      // 树清空
			this.checkedKeys = []   // 回显清空
			this.checkedList = []   // 已选清空
			// 查询所有权限
			let that = this
			post ( api.GetMenuList, {} ).then ( res => {
				res.data.data.forEach ( function ( item, index ) {
					let tree = {title: item.menuName, value: index + '-' + item.id, key: index + '-' + item.id}
					that.treeData.push ( tree )
					let children = []
					item.menuEntities.forEach ( function ( child, cIndex ) {
						let c = {
							title: child.menuName,
							value: index + '-' + cIndex + '-' + child.id,
							key: index + '-' + cIndex + '-' + child.id
						}
						children.push ( c )
					} )
					tree.children = children
				} )
			} )
		},
		onClose() {
			this.visible = false
		},
		saveMenu() {
			this.$refs.merchantEntity.validate ( ( valid ) => {
				if ( valid ) {
					if ( this.merchantEntity.parentId ) {
						let parentValue = this.merchantEntity.parentId.split ( '-' )
						this.merchantEntity.parentId = parentValue[parentValue.length - 1]
					}
					post ( api.SaveMerchant, this.merchantEntity ).then ( res => {
						this.$message.success ( "添加成功" );
						this.visible = false
						this.$refs.merchantEntity.resetFields ();
						this.resetForm ();
						this.$emit ( "reloadList" );
					} )
				}
			} )
		},
		// 重置信息
		resetForm() {
			let merchantEntity = {
				menuName: '',
				url: '',
				type: '',
				menuClass: '',
				parentId: '',
			};
			this.lngDegree = [];
			this.latDegree = [];
			this.merchantEntity = merchantEntity;
		},
	}
}
</script>

<style scoped>

</style>
