<template>
    <div class="main">
        <div class="soc-analysis table-box">
            <div class="soc-analysis-box">
                <div class="left">单体SOH分布</div>
                <div class="soc-main">
                    <div class="title-box">
                        <div class="soc-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="current-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="current-content">
                        <!--报表  -->
                        <div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;">报表</div>
                    </div>
                </div>
            </div>
            <div class="soc-analysis-box">
                <div class="left">单体SOH曲线</div>
                <div class="soc-main">
                    <div class="title-box">
                        <div class="soc-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="current-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="current-content">
                        <!--报表  -->
                        <div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;">报表</div>
                    </div>
                </div>
            </div>
            <div class="soc-analysis-box">
                <div class="left">SOH / %</div>
                <div class="soc-main">
                    <div class="title-box">
                        <div class="soc-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="current-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="current-content">
                        <!--报表  -->
                        <div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;">报表</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SohAnalysis",
        data(){
            return{
                dateRange: []
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/socAnalysis";
</style>
