<template>
	<a-drawer
		:width="500"
		:title="title"
		:visible="visible"
		@close="onClose">
		<div class="drawer-content-box">
			<a-form-model :model="orderEntity" :rules="rules" ref="orderEntity" layout="vertical">
				<a-form-model-item label="所属电站" prop="stationId">
					<a-select placeholder="请选择所属电站" v-model:value="orderEntity.stationId">
						<a-select-option :value="station.id" v-for="station in stationEntities">{{station.stationName}}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="工单类型" prop="orderType">
					<a-select placeholder="请选择工单类型" v-model:value="orderEntity.orderType">
						<a-select-option :value="1">巡视检查</a-select-option>
						<a-select-option :value="2">故障检修</a-select-option>
						<a-select-option :value="3">维护保养</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="期望处理时间" prop="dateArray">
					<a-range-picker v-model:value="dateArray" style="width: 100%" />
				</a-form-model-item>
				<a-form-model-item label="工单说明">
					<a-textarea :max-length="100" v-model:value="orderEntity.info"/>
				</a-form-model-item>
			</a-form-model>
		</div>
		<div class="drawer-footer-box">
			<a-button type="primary" @click="saveOrder('orderEntity')">保存</a-button>
		</div>
	</a-drawer>
</template>

<script>
import moment from "moment";
import {api, post} from "@/api/api";

export default {
	name: "AddOrder",
	props: {
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			stationEntities: [],
			orderEntity: {},
			dateArray: [],
			rules: {
				stationId: [{required: true, message: '所属电站不能为空', trigger: 'blur'}],
			},
		}
	},
	methods: {
		show() {
			this.visible = true
			post(api.GetStationList, {}).then(res=>{
				this.stationEntities = res.data.data
			})
		},
		onClose() {
			this.visible = false
		},
		saveOrder() {
			this.$refs.orderEntity.validate ( ( valid ) => {
				if ( valid ) {
					if ( this.dateArray ) {
						this.orderEntity.hopeStartTime = moment(this.dateArray[0]).format('YYYY-MM-DD')
						this.orderEntity.hopeEndTime = moment(this.dateArray[1]).format('YYYY-MM-DD')
					}
					post ( api.SaveOrder, this.orderEntity ).then ( res => {
						this.$message.success ( "添加成功" );
						this.visible = false
						this.$refs.orderEntity.resetFields ();
						this.resetForm ();
						this.$emit ( "reloadList" );
					} )
				}
			} )
		},
		// 重置信息
		resetForm() {
			let orderEntity = {
				menuName: '',
				url: '',
				type: '',
				menuClass: '',
				parentId: '',
			};
			this.orderEntity = orderEntity;
		},
	}
}
</script>

<style scoped>

</style>
