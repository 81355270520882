import request from './request'
import {baseUrl} from './const'


/**
 * 通用api接口
 */
export const api = {
    Login: '/user/login',
    Logout: '/user/logout',
    GetMenus: '/menu/queryRoleMenus',   // 根据角色获取菜单路由
    UserEntities: '/user/queryList',
    GetUserList: '/user/getUserList',
    SaveUser: '/user/saveSingle',
    RoleEntities: '/role/queryList',
    MenuEntities: '/menu/queryList',
    SaveMenu: '/menu/saveSingle',       // 新增菜单路由
    GetMenuList: '/menu/getList',       // 获取所有菜单路由，组装成树
    SaveRoleMenu: '/roleMenu/saveRoleMenu',     // 保存角色菜单
    GetRoleList: '/role/getRoleList',
    AreaEntities: '/area/queryList',
    SaveStation: '/station/saveSingle',
    UpdateStation: '/station/updateSingle',
    StationEntities: '/station/queryList',
    SaveMerchant: '/merchant/saveSingle',
    MerchantEntities: '/merchant/queryList',
    SingleSite: '/station/singleSite',      // 单站概况
    GetStationList: '/station/getStationList',
    StationSingle: '/station/querySingle',
    QueryStationsOver: '/station/queryStationsOver',//多站概览顶部数据
    ChargeDisRecords: '/station/chargeDisRecords',  //充放电记录
    PcsList: '/pcs/queryList',                  // 查询全部PCS，不分页
    OpenClose: '/pcs/openClose',                // 下发PCS开关机
    AlarmRest: '/pcs/alarmRest',                // PCS故障复位
    OffGird: '/pcs/offGird',                    // 下发PCS并离网设置
    ChargeDisPower: '/pcs/setChargeDisPower',   // 设置充放电功率
    BatteryBoxEntities: '/batteryBox/queryList',//电池簇
    BatteryEntities: '/battery/queryList',      // 电池列表
    BatteryVoltageEntities: '/batteryVoltage/queryList',
    BatteryTempEntities: '/batteryTemp/queryList',
    AlarmEntities: '/alarm/queryList',          // 警告
    QueryStationAlarmEntities: '/station/queryStationAlarmList',// 基于电站查询报警数量
    SaveOrder: '/order/saveSingle',             // 保存工单
    OrderEntities: '/order/queryList',          // 工单列表
    OrderSingle: '/order/querySingle',          // 查询单个工单
    SaveCamera: '/camera/saveSingle',           // 保存相机
    CameraEntities: '/camera/queryList',        // 相机列表
    RefreshCamera: '/camera/refresh',           // 刷新摄像头，重新推流
    SaveDevice: '/device/saveSingle',           // 保存设备
    DeviceEntities: '/device/queryList',        // 设备列表
    SingleDevice: '/device/querySingle',        // 设备信息
}

export function post(url, param) {
    return request.postToken(baseUrl + url, param)
}

// 上传
export function uploadFile(parameter) {
    const dealData = new FormData()
    dealData.append("token", window.sessionStorage.getItem('token'));
    for (let key in parameter) {
        dealData.append(key, parameter[key])
    }
    return request.upload(baseUrl + api.UploadFile, parameter)
}
