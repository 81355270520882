<template>
	<div class="main">
		<div class="fault-tabs table-box">
			<a-tabs v-model:activeKey="activeTab">
				<a-tab-pane key="1" tab="故障定位">
					<div class="search-pane">
						<div class="search-item-box">
							<a-select size="small" style="width: 200px"></a-select>
						</div>
					</div>
					<div class="fault-tracing-table">
						<a-table :columns="columns" :data-source="data"></a-table>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="定位规则">
					<div class="search-pane">
						<div class="search-item-box">
							<a-button type="primary" ghost size="small">查询</a-button>
						</div>
					</div>
					<div class="fault-tracing-table">
						<a-table :columns="columns" :data-source="data"></a-table>
					</div>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
export default {
	name: "FaultTracing",
	data() {
		return {
			activeTab: '1',
			columns: [
				{title: 'Name', dataIndex: 'name', key: 'name'},
				{title: 'Age', dataIndex: 'age', key: 'age'},
				{title: 'Address', dataIndex: 'address', key: 'address'},
				{title: 'Action', dataIndex: '', key: 'x', scopedSlots: {customRender: 'action'}},
			],
			data: [
				{
					key: 1,
					name: 'John Brown',
					age: 32,
					address: 'New York No. 1 Lake Park',
					description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
				},
				{
					key: 2,
					name: 'Jim Green',
					age: 42,
					address: 'London No. 1 Lake Park',
					description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
				},
				{
					key: 3,
					name: 'Joe Black',
					age: 32,
					address: 'Sidney No. 1 Lake Park',
					description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.',
				},
			]
		}
	}
}
</script>

<style lang="less">
@import "../../static/faultTracing";
</style>
