<template>
	<div id="app">
		<a-config-provider :locale="zh_CN">
			<router-view/>
		</a-config-provider>
	</div>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
	data() {
		return {
			zh_CN
		}
	}
}
</script>
<style lang="less">
html, body, #app {
	font-family: Consolas, 微软雅黑;
	background: #f0f2f5;
}

/*自定义滚动条*/
::-webkit-scrollbar, ::-webkit-scrollbar, ::-webkit-scrollbar, .ant-table-body::-webkit-scrollbar, ::-webkit-scrollbar, ::-webkit-scrollbar, .beautify_scroll::-webkit-scrollbar {
	width: 8px;
	height: 12px;
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
	background-color: #65779f;
	border-radius: 4px;
}
</style>
