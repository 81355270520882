<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible class="admin_layout_sider">
            <div class="admin_logo">
                <img src="../../assets/logo/LOGO.png" style="width: 80%; height: auto">
            </div>
            <div class="dashboard-box" @click="toDashboard('/dashboard')">
                <span class="ic">
                    <a-icon type="fund" theme="filled"/>
                    <span>数据看板</span>
                </span>
            </div>
            <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys" :open-keys="openKeys" class="menus"
                    @openChange="onOpenChange">
                <a-sub-menu :key="index" v-for="(menu, index) in menuEntities">
                    <template #title>
                        <span>
	                        <a-icon :type="menu.menuClass" theme="filled"/>
	                        <span>{{ menu.menuName }}</span>
                        </span>
                    </template>
                    <a-menu-item :key="index+'-'+k" @click="toPath(child.url)" v-for="(child, k) in menu.menuEntities">
                        {{ child.menuName }}
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header class="admin_layout_header">
                <a-icon type="compass"/>
                {{ this.$store.state.routerName.routerName }}
            </a-layout-header>
            <a-layout-content class="admin_layout_content">
                <router-view></router-view>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>

    import {api, post} from "@/api/api";

    export default {
        name: "Index",
        data() {
            return {
                collapsed: false,
                openKeys: [""],
                selectedKeys: [''],
                path: '/dashboard',
                logo: './static/logo.jpg',       // logo图片地址
                menuEntities: [],
                userEntity: {},
                centerEntity: {},
            }
        },
        created() {
            let userEntity = window.sessionStorage.getItem('userEntity')
            post(api.GetMenus, {roleId: JSON.parse(userEntity).roleId, type: 1}).then(res => {
                this.menuEntities = res.data.data
                // 遍历菜单，当刷新页面时上一个选中的菜单常亮
                this.menuEntities.forEach((item, index) => {
                    item.menuEntities.forEach((c, j) => {
                        if (this.$route.path == '/' + c.url) {
                            this.selectedKeys = [index + '-' + j]
                            this.openKeys = [index]
                        }
                    })
                })
            })
        },
        watch: {
            $route(to, from) {
                this.$router.push(to.path)
            },
        },
        methods: {
            toDashboard(path) {
                const routeUrl = this.$router.resolve({
                    path: path,
                });
                window.open(routeUrl.href, "_blank");
                //window.open ( path, "_blank" );
            },
            toPath(path) {
                this.$router.push(path)
            },
            /*只展开一个菜单项*/
            onOpenChange(openKeys) {
                if (openKeys.length !== 0) {
                    this.openKeys = [openKeys[1]]
                } else {
                    this.openKeys = [""]
                }
            },
        },
        mounted() {
        },
    }
</script>

<style lang="less">
    /*需要配合commons里使用，完成首页table框架的美化*/
    .admin_layout_sider {
        height: 100vh;
    }

    .admin_logo {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin_logo img {
        width: 126px;
        height: 40px;
        object-fit: contain;
    }

    .menus {
        height: calc(100vh - 90px);
        overflow: hidden auto;
    }

    .admin_layout_header {
        padding: 0 24px;
        height: 50px;
        line-height: 50px;
        background: #fff;
        box-shadow: 0px 2px 5px 0px #dfdfdf;
        z-index: 1;
    }

    .ant-layout {
        display: flex;
        flex: auto;
        flex-direction: column;
        min-height: 0;
    }

    .admin_layout_content {
        position: relative;
        overflow: hidden;
        height: 0;
    }

    .ant-menu-inline .ant-menu-item {
        margin-top: 0 !important;
    }

    .dashboard-box {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
    }

    .dashboard-box span {
        color: rgba(255, 255, 255, 0.65);
        width: 100%;
    }

    .dashboard-box .ic {
        display: inline-block;
        width: 100%;
        padding-left: 24px;
    }

    .dashboard-box i {
        color: rgba(255, 255, 255, 0.65);
        margin-right: 10px;
    }

    .dashboard-box .ic:hover {
        color: #fff !important;
    }
</style>
