<template>
	<div class="main">
		<div class="top-panel">
			<div class="">
				<a-select
					size="small"
					style="width: 200px"
				></a-select>
			</div>
			<div class="btn">
				<a-button type="primary" @click="addUser">添加</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table :columns="columns" :data-source="data" :loading="loading" :rowKey="data => data.id" :pagination="false"
					 :scroll="{ x: 1000, y: tableHeight }" size="middle">
				<template slot="type" slot-scope="data">
					<a-tag :color="data.type == 1 ? '#108ee9' : '#87d068'">{{data.type == 1 ? '一级菜单' : '二级菜单'}}</a-tag>
				</template>
				<template slot="createTime" slot-scope="data">
					{{moment(data.createTime).format('YYYY-MM-DD')}}
				</template>
				<template #action>
					<a>删除</a>
				</template>
			</a-table>
			<div class="page-box">
				<a-pagination
					size="small"
					:showQuickJumper="true"
					:showSizeChanger="true"
					:current="page.current"
					:pageSize="page.pageSize"
					:total="page.total"
					:show-total="(total) => `共${total}条`"
					@showSizeChange="onShowSizeChange"
					@change="changePageSize"
				></a-pagination>
			</div>
		</div>
		<AddMenu ref="menu" :title="title" @reloadList="queryList"></AddMenu>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import AddMenu from "@/views/system/components/AddMenu";
import moment from "moment";

export default {
	name: "MenuList",
	components: {AddMenu},
	data() {
		return {
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			loading: true,
			columns: [
				{title: "序号", dataIndex: "index", width: 50,
					customRender: (text, record, index) => {
						return index + 1;
					},fixed: 'left'
				},
				{title: '菜单名称', dataIndex: 'menuName', key: 'menuName', width: 100, fixed: 'left'},
				{title: '路由', dataIndex: 'url', key: 'url', width: 250},
				{title: 'ICON', dataIndex: 'menuClass', key: 'menuClass', width: 100},
				{title: '菜单类型', scopedSlots: {customRender: 'type'}, width: 100},
				{title: '排序', dataIndex: 'sort', key: 'sort', width: 100},
				{title: '父菜单', dataIndex: 'parentName', key: 'parentName', width: 100},
				{title: '权限', dataIndex: 'permission', key: 'permission', width: 100},
				{title: '创建时间', scopedSlots: {customRender: 'createTime'}, width: 150},
				{title: '操作', dataIndex: '', key: 'x', scopedSlots: {customRender: 'action'}, width: 60},
			],
			data: [],
			title: '',
			tableHeight: 0,
			moment,
		}
	},
	created() {
		this.queryList()
	},
	mounted() {
		// 设置表格y轴滚动条区域的高度，其中236包含顶部、按钮面板、分页面板以及表格header高度
		this.tableHeight = window.innerHeight - 236
	},
	methods: {
		queryList(){
			post ( api.MenuEntities, {page: this.page.current, limit: this.page.pageSize} ).then ( res => {
				this.data = res.data.data
				this.page.total = res.data.count
				this.loading = false
			} )
		},
		addUser() {
			this.title = '新增菜单路由'
			this.$refs.menu.show()
		},
		onShowSizeChange ( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		},
		changePageSize(current, pageSize){
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		}
	}
}
</script>

<style scoped>

</style>
