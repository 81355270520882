<template>
    <div class="main">
        <div class="search-pane">
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
            <div class="search-item-box">
                <a-button type="primary" ghost size="small">查询</a-button>
            </div>
            <div class="search-item-box">
                <a-button type="default" class="reset-btn" size="small">重置</a-button>
            </div>
        </div>
        <div class="operator">
            <a-button type="primary" size="small" @click="price" :disabled="selectedRows.length == 0">电价配置</a-button>
            <a-button type="primary" size="small" @click="meter" :disabled="selectedRows.length == 0">电表配置</a-button>
        </div>
        <div class="table-box">
            <a-table :columns="columns" :data-source="data" :rowSelection="rowSelection">
                <template #action>
                    <a style="margin-right: 10px">紧急关机</a>
                    <a>开机</a>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CentralizedManager",
        data() {
            return {
                columns: [
                    {title: '站点名称', dataIndex: 'name', key: 'name'},
                    {title: '装机功率', dataIndex: 'age', key: 'age'},
                    {title: '当前功率', dataIndex: 'address', key: 'address'},
                    {title: 'SOC', dataIndex: 'SOC', key: 'SOC',},
                    {title: '上次集控时间', dataIndex: 'startTime', key: 'startTime'},
                    {title: '操作', scopedSlots: {customRender: 'action'}},
                ],
                data: [
                    {
                        key: 1,
                        name: 'John Brown',
                        age: 32,
                        address: 'New York No. 1 Lake Park',
                        description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
                    },
                    {
                        key: 2,
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No. 1 Lake Park',
                        description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
                    },
                    {
                        key: 3,
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney No. 1 Lake Park',
                        description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.',
                    },
                ],
                selectedRowKeys: [],    // 选中的表格的key
                selectedRows: []        // 选择的表格的行数据
            }
        },
        methods: {
            price() {
                this.$router.push("/electricityPrice/" + 123)
            },
            meter(){
                this.$router.push("/meter/" + 100)
            },
            onSelectChange (selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys
                this.selectedRows = selectedRows
            },
        },
        computed: {
            rowSelection () {
                return {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onSelectChange
                }
            }
        },
    }
</script>

<style lang="less">
    @import "../../static/centralizedManager";
</style>
