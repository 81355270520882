<template>
    <div class="main">
        <div class="meter-config-detail">
            <div class="head">
                <span class="head-title">
                    <div class="back" @click="back">
                        <a-icon type="left"/>返回
                    </div>
                    <span> 电价配置 </span>
                </span>
            </div>
            <div class="content">
                <div>当前选择<strong>哈尔滨100KWh</strong>进行电价配置</div>
                <div><a-select size="small" style="width: 200px"></a-select></div>
            </div>
            <div class="meter-config-table">
                <div class="header">
                    <div class="time-box">开始时间</div>
                    <div class="time-box">结束时间</div>
                    <div class="time-box">时段名称</div>
                </div>
                <div class="content-value" v-for="item in data">
                    <div class="time-box">{{item.startTime}}</div>
                    <div class="time-box">{{item.endTime}}</div>
                    <div class="time-box">{{item.name}}</div>
                </div>
            </div>
            <div class="btn">
                <a-button type="primary" ghost size="small" @click="sub">-</a-button>
                <a-button type="primary" ghost size="small" @click="add">+</a-button>
                <a-button type="primary" ghost size="small">保存</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MeterConfig",
        data() {
            return {
                data: [
                    {startTime: '00:00', endTime: '01:00', name: '平'},
                    {startTime: '00:00', endTime: '01:00', name: '平'},
                    {startTime: '00:00', endTime: '01:00', name: '平'},
                    {startTime: '00:00', endTime: '01:00', name: '平'},
                ]
            }
        },
        methods: {
            back() {
                this.$router.push("/centralized")
            },
            sub() {
                this.data.pop()
            },
            add() {
                let d = {startTime: '00:00', endTime: '23:59', name: '平'}
                this.data.push(d)
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/meterConfig";
</style>
