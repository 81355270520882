<template>
    <div class="main">
        <div class="earning-tabs">
            <a-tabs v-model:activeKey="siteType">
                <a-tab-pane key="1" tab="汇总">
                    <div class="header">
                        <div class="title-box">
                            <div class="search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px" v-if="siteType == 2"></a-select>
                                    <a-date-picker v-model:value="value1" size="small" locale="ch"/>
                                </div>
                            </div>
                            <div class="btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="radio-box">
                            <a-radio-group size="small">
                                <a-radio-button value="a">站点报表</a-radio-button>
                                <a-radio-button value="b">日报表</a-radio-button>
                                <a-radio-button value="c">月报表</a-radio-button>
                            </a-radio-group>
                        </div>
                    </div>
                    <div class="electric-table">
                        <ReportModule charging-title="充电成本（元）" dis-charge-title="放电成本（元）" total-title="实际收益（元）"></ReportModule>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="单站">
                    <div class="header">
                        <div class="title-box">
                            <div class="search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-date-picker v-model:value="value1" size="small" locale="ch"/>
                                </div>
                            </div>
                            <div class="btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="radio-box">
                            <a-radio-group size="small">
                                <a-radio-button value="a" v-if="siteType == 1">站点报表</a-radio-button>
                                <a-radio-button value="b">日报表</a-radio-button>
                                <a-radio-button value="c">月报表</a-radio-button>
                            </a-radio-group>
                        </div>
                    </div>
                    <div class="electric-table">
                        <ReportModule charging-title="日充电成本（元）" dis-charge-title="日放电成本（元）" total-title="实际收益（元）"></ReportModule>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import ReportModule from "@/views/report/components/ReportModule";
    export default {
        name: "EarningsReport",
        components: {ReportModule},
        data() {
            return {
                siteType: '1',
                value1: ''
            }
        }
    }
</script>

<style lang="less">
@import "../../static/earningsReport";
</style>
