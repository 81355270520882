<template>
	<a-drawer
		:title="title"
		:width="400"
		:visible="visible"
		@close="onClose"
	>
		<div class="drawer-content-box">
			<a-tree
				checkable
				:tree-data="treeData"
				v-model:checkedKeys="checkedKeys"
				:show-line="true"
				@check="onCheck"
			>
			</a-tree>
		</div>
		<div class="drawer-footer-box">
			<a-button type="primary" @click="save">保存</a-button>
		</div>
	</a-drawer>
</template>

<script>
import {api, post} from "@/api/api";

export default {
	name: "RolePermission",
	props: {
		title: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			visible: false,
			id: '',
			treeData: [],
			checkedKeys: [],
			checkedList: []     // 选中的树形节点
		}
	},
	methods: {
		// 点击节点
		onCheck( checkedKeys, e ) {
			let tempArr = [];
			if ( this.checkedKeys.length > 0 ) {
				for ( let a = 0; a < this.checkedKeys.length; a++ ) {
					let tempA = this.checkedKeys[a].split ( "-" );
					let index = 0;
					let tempStr = "";
					let data = this.treeData[0];
					let id = 0;
					let temFuc = () => {
						index++;
						if ( tempA.length - index >= 0 ) {
							if ( tempStr ) {
								tempStr = tempStr + "-" + data.menuName;
							} else {
								tempStr = data.menuName;
							}
							if ( data.children ) {
								data = data.children[tempA[index]];
							} else {
								id = data.id;
							}
							temFuc ();
						}
					}
					temFuc ();
					tempArr.push ( {id: this.checkedKeys[a]} )
				}
				this.checkedList = tempArr;
				let that = this
				e.halfCheckedKeys.forEach ( function ( item ) {
					that.checkedList.push ( {id: item} )
				} )
				console.log ( this.checkedList )
			}
		},
		show( visible, role ) {
			this.id = role.id
			this.visible = visible
			this.treeData = []      // 树清空
			this.checkedKeys = []   // 回显清空
			this.checkedList = []   // 已选清空
			// 查询所有权限
			let that = this
			post ( api.GetMenuList, {} ).then ( res => {
				res.data.data.forEach ( function ( item, index ) {
					let tree = {title: item.menuName, key: item.id}
					that.treeData.push ( tree )
					let children = []
					item.menuEntities.forEach ( function ( child, cIndex ) {
						let c = {title: child.menuName, key: child.id}
						children.push ( c )
					} )
					tree.children = children
				} )
				// 回显权限路由
				post ( api.GetMenus, {roleId: role.id, type: 1} ).then ( res => {
					res.data.data.forEach ( function ( item, index ) {
						item.menuEntities.forEach ( function ( child, cIndex ) {
							that.checkedKeys.push ( child.id )
						} )
					} )
				} )
			} )
		},
		onClose() {
			this.visible = false
		},
		save() {
			if ( this.checkedList.length == 0 ) {
				this.$message.error ( '所赋权限不能为空' )
				return
			}

			let roleMenu = {roleId: this.id, menuVoList: this.checkedList}
			post ( api.SaveRoleMenu, roleMenu ).then ( res => {
				this.visible = false
			} )
		}
	}
}
</script>

<style lang="less">

</style>
