<template>
    <div class="main">
        <a-spin :spinning="spinning">
            <div class="top-panel">
                <div class="search-pane">
                    <div class="search-item-box">
                        <a-select size="small" style="width: 200px"></a-select>
                    </div>
                    <div class="search-item-box">
                        <a-select size="small" style="width: 200px"></a-select>
                    </div>
                    <div class="search-item-box">
                        <a-button type="primary" ghost size="small">查询</a-button>
                    </div>
                    <div class="search-item-box">
                        <a-button type="default" class="reset-btn" size="small">重置</a-button>
                    </div>
                </div>
                <div class="btn">
                    <a-button type="primary" @click="addCamera">添加</a-button>
                </div>
            </div>
            <div class="video-list">
                <div class="video-boxs">
                    <div class="video-item" v-for="(camera, index) in cameraEntities">
                        <div class="video-item-title">
                            <div class="left">{{camera.cameraName}}</div>
                            <div class="right">
                                <a @click="refresh(camera.id)">刷新</a>
                            </div>
                        </div>
                        <div class="video-item-content">
                            <video v-if="camera.status == 1" :id="'video'+index" autoplay muted loop="loop" preload="auto" style="height: 100%;width: 100%;"></video>
                            <div v-if="camera.status == 0" class="no-video">
                                <img src="../../assets/no-video.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-box">
                    <a-pagination
                            size="small"
                            :showQuickJumper="true"
                            :showSizeChanger="true"
                            :current="page.current"
                            :pageSize="page.pageSize"
                            :total="page.total"
                            :show-total="(total) => `共${total}条`"
                            @showSizeChange="onShowSizeChange"
                            @change="changePageSize"
                    ></a-pagination>
                </div>
            </div>
        </a-spin>
        <AddCamera ref="camera" :title="title"></AddCamera>
    </div>
</template>

<script>
    import AddCamera from "@/views/SiteMonitoring/components/AddCamera";
    import {api, post} from "@/api/api";
    import flvjs from "flv.js";

    export default {
        name: "VideoMonitoring",
        components: {AddCamera},
        data() {
            return {
                page: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                },
                cameraEntities: [],
                title: '',
                flvPlayer: null,
                spinning: false,
            }
        },
        created() {

        },
        mounted() {
            this.queryList()
        },
        methods: {
            refresh(id){
                this.spinning = true
                post(api.RefreshCamera, {id: id}).then(res=>{
                    if (res.data.code == '999998') {
                        this.$message.error(res.data.message)
                        this.spinning = false
                    }
                })
            },
            addCamera(){
                this.title = '添加摄像机'
                this.$refs.camera.show()
            },
            async queryList(){
                this.spinning = true
                await post(api.CameraEntities, {page: this.page.current, limit: this.page.pageSize}).then(res=>{
                    this.cameraEntities = res.data.data
                    this.page.total = res.data.count
                })
                this.cameraEntities.forEach(function (item, index) {
                    if (item.status == 1) {
                        // 在线
                        if (flvjs.isSupported()) {
                            var videoElement = document.getElementById('video' + index);
                            var flvPlayer = flvjs.createPlayer({
                                type: "flv",
                                url: item.cameraUrl,
                            },{
                                enableWorker: false, //不启用分离线程
                                enableStashBuffer: false, //关闭IO隐藏缓冲区
                                reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
                                autoCleanupSourceBuffer: true //自动清除缓存
                            });
                            flvPlayer.attachMediaElement(videoElement);
                            flvPlayer.load();
                            flvPlayer.play();
                        }
                    }
                })
                this.spinning = false
            },
            onShowSizeChange(current, pageSize) {
                this.page.current = current
                this.page.pageSize = pageSize
                this.queryList()
            },
            changePageSize(current, pageSize) {
                this.page.current = current
                this.page.pageSize = pageSize
                this.queryList()
            },
        }
    }
</script>

<style lang="less">
    @import "../../static/videoMonitoring";
</style>
