<template>
	<div class="main">
		<div class="top-panel">
			<div class="">
				<a-select
					size="small"
					style="width: 200px"
				></a-select>
			</div>
			<div class="btn">
				<a-button type="primary" @click="addUser">添加</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table :columns="columns" :data-source="data" :loading="loading" :rowKey="data => data.id" :pagination="false" :scroll="{ x: 1000, y: tableHeight }" size="middle">
				<template slot="hopeStartTime" slot-scope="data">
					{{moment(data.hopeStartTime).format('YYYY-MM-DD')}} ~ {{moment(data.hopeEndTime).format('YYYY-MM-DD')}}
				</template>
				<template slot="orderType" slot-scope="data">
					<a-tag :color="data.type == 1 ? '#108ee9' : (data.type == 2 ? '#f50' : '#87d068')">{{data.type == 1 ? '巡视检查' : (data.type == 2 ? '故障检修' : '维护保养')}}</a-tag>
				</template>
				<template slot="orderSource" slot-scope="data">
					<a-tag :color="data.orderSource == 1 ? '#108ee9' : '#f50'">{{data.orderSource == 1 ? '手动创建' : '告警生成'}}</a-tag>
				</template>
				<template slot="createTime" slot-scope="data">
					{{ moment ( data.createTime ).format ( 'YYYY-MM-DD' ) }}
				</template>
				<template slot="action" slot-scope="data">
					<a style="margin-right: 10px" @click="toDetail(data.id)">详情</a>
					<a style="margin-right: 10px">编辑</a>
					<a>删除</a>
				</template>
			</a-table>
			<div class="page-box">
				<a-pagination
					size="small"
					:showQuickJumper="true"
					:showSizeChanger="true"
					:current="page.current"
					:pageSize="page.pageSize"
					:total="page.total"
					:show-total="(total) => `共${total}条`"
					@showSizeChange="onShowSizeChange"
					@change="changePageSize"
				></a-pagination>
			</div>
		</div>
		<AddOrder ref="order" :title="title" @reloadList="queryList"></AddOrder>
		<OrderDetail ref="detail"></OrderDetail>
	</div>
</template>

<script>
import moment from "moment";
import {api, post} from "@/api/api";
import AddOrder from "@/views/Operation/components/AddOrder";
import OrderDetail from "@/views/Operation/OrderDetail";

export default {
	name: "Order",
	components: {OrderDetail, AddOrder},
	data() {
		return {
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			loading: true,
			columns: [
				{
					title: "序号", dataIndex: "index", width: 50,
					customRender: ( text, record, index ) => {
						return index + 1;
					}, fixed: 'left'
				},
				{title: '站点名称', dataIndex: 'stationName', key: 'stationName', width: 200, fixed: 'left'},
				{title: '工单类型', scopedSlots: {customRender: 'orderType'}, width: 100},
				{title: '工单来源', scopedSlots: {customRender: 'orderSource'}, width: 100},
				{title: '期望处理时间', scopedSlots: {customRender: 'hopeStartTime'}, width: 200},
				{title: '创建时间', scopedSlots: {customRender: 'createTime'}, width: 150},
				{title: '操作', scopedSlots: {customRender: 'action'}, width: 180},
			],
			data: [],
			title: '',
			tableHeight: 0,
			moment,
		}
	},
	mounted() {
		// 设置表格y轴滚动条区域的高度，其中236包含顶部、按钮面板、分页面板以及表格header高度
		this.tableHeight = window.innerHeight - 236
	},
	created() {
		this.queryList()
	},
	methods: {
		queryList(){
			post ( api.OrderEntities, {page: this.page.current, limit: this.page.pageSize} ).then ( res => {
				this.data = res.data.data
				this.page.total = res.data.count
				this.loading = false
			} )
		},
		addUser() {
			this.title = '新增工单'
			this.$refs.order.show()
		},
		toDetail(id){
			this.$refs.detail.show(id)
		},
		onShowSizeChange ( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		},
		changePageSize(current, pageSize){
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		}
	}
}
</script>

<style scoped>

</style>
