<template>
	<div class="main">
		<div class="search-pane">
			<div class="search-item-box">
				<a-select size="small" style="width: 200px"></a-select>
			</div>
			<div class="search-item-box">
				<a-select size="small" style="width: 200px"></a-select>
			</div>
			<div class="search-item-box">
				<a-select size="small" style="width: 200px"></a-select>
			</div>
			<div class="search-item-box">
				<a-button type="primary" ghost size="small">查询</a-button>
			</div>
			<div class="search-item-box">
				<a-button type="default" class="reset-btn" size="small">重置</a-button>
			</div>
		</div>
		<div class="statistics-echarts-box">
			<div class="echarts-item">
				<div class="header ems-box-header">状态</div>
				<AlarmModule></AlarmModule>
			</div>
			<div class="echarts-item">
				<div class="header ems-box-header">告警等级</div>
				<AlarmModule></AlarmModule>
			</div>
			<div class="echarts-item">
				<div class="header ems-box-header">设备类型</div>
				<AlarmModule></AlarmModule>
			</div>
			<div class="echarts-item">
				<div class="header ems-box-header">历史故障时长</div>
				<AlarmModule></AlarmModule>
			</div>
		</div>
	</div>
</template>

<script>
import AlarmModule from "@/views/FaultManager/components/AlarmModule";
export default {
	name: "AlarmStatistics",
	components: {AlarmModule}
}
</script>

<style lang="less">
@import "../../static/alarmStatistics";
</style>
