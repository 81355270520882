<template>
	<div class="main">
		<div class="line-echarts">
			<div class="item">
				<div class="head">
					故障报警
				</div>
				<div class="box-item-content">
					<div class="inner">
						<div class="block">
							<div class="num"> 4</div>
							<div class="txt"> 今日告警 (条)</div>
						</div>
						<div class="block">
							<div class="num highlight f20"> 0</div>
							<div class="txt"> 发生中 (条)</div>
							<div class="num f20"> 4</div>
							<div class="txt"> 已结束 (条)</div>
						</div>
						<div class="block">
							<div class="num"> 4</div>
							<div class="txt"> 电站总数 (座)</div>
						</div>
						<div class="block">
							<div class="num highlight f20"> 0</div>
							<div class="txt"> 当前告警 (座)</div>
							<div class="num f20"> 1</div>
							<div class="txt"> 今日告警 (座)</div>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="head">
					故障报警
				</div>
				<div>报表</div>
			</div>
			<div class="item">
				<div class="head">
					故障报警
				</div>
				<div>报表</div>
			</div>
			<div class="item">
				<div class="head">
					故障报警
				</div>
				<div>报表</div>
			</div>
		</div>
		<div class="alarm-tabs">
			<a-tabs v-model:activeKey="activeItem" @change="changeTabs">
				<a-tab-pane key="1" tab="站点">
					<div class="title-box">
						<div class="alarm-search">
							<div class="search-item-box">
								<a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
								<a-date-picker v-model:value="value1" size="small"/>
							</div>
						</div>
						<div class="alarm-btn">
							<a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
							<a-button type="default" class="reset-btn" size="small">导出</a-button>
						</div>
					</div>
					<div class="site-table">
						<a-table :columns="stationColumns" :data-source="stationEntities" :rowKey="data => data.id" :pagination="false" :loading="loading"></a-table>
						<div class="page-box">
							<a-pagination
									size="small"
									:showQuickJumper="true"
									:showSizeChanger="true"
									:current="stationPage.current"
									:pageSize="stationPage.pageSize"
									:total="stationPage.total"
									:show-total="(total) => `共${total}条`"
									@showSizeChange="stationShowSizeChange"
									@change="stationChangePageSize"
							></a-pagination>
						</div>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="报警" force-render>
					<div class="title-box">
						<div class="alarm-search">
							<div class="search-item-box">
								<a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
								<a-date-picker v-model:value="value1" size="small" locale="ch"/>
							</div>
						</div>
						<div class="alarm-btn">
							<a-button type="primary" size="small" style="margin-right: 10px">转工单</a-button>
						</div>
					</div>
					<div class="site-table">
						<a-table :columns="alarmColumns" :data-source="alarmEntities" :rowKey="data => data.id" :pagination="false" :loading="loading">
							<template slot="createTime" slot-scope="data">
								{{moment(data.createTime).format('YYYY-MM-DD')}}
							</template>
						</a-table>
						<div class="page-box">
							<a-pagination
									size="small"
									:showQuickJumper="true"
									:showSizeChanger="true"
									:current="stationPage.current"
									:pageSize="stationPage.pageSize"
									:total="stationPage.total"
									:show-total="(total) => `共${total}条`"
									@showSizeChange="stationShowSizeChange"
									@change="stationChangePageSize"
							></a-pagination>
						</div>
					</div>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import moment from "moment";

export default {
	name: "AlarmList",
	data() {
		return {
			value1: '',
			activeItem: '1',
			loading: false,
			stationPage: {
				current: 1,
				pageSize: 10,
				total: 0,
			},
			stationColumns: [
				{title: '站点名称', dataIndex: 'stationName', key: 'stationName'},
				{title: '今日报警', dataIndex: 'alarmNumber', key: 'alarmNumber'},
				{title: '发生中', dataIndex: 'alarmOccurring', key: 'alarmOccurring'},
				{title: '已结束', dataIndex: 'alarmEndEr', key: 'alarmEndEr'},
				{title: '故障', dataIndex: 'fault', key: 'fault'},
				{title: '告警', dataIndex: 'alarm', key: 'alarm'},
				{title: '预警', dataIndex: 'warning', key: 'warning'},
			],
			stationEntities: [],
			alarmColumns: [
				{title: '站点名称', dataIndex: 'stationName', key: 'stationName'},
				{title: '设备类型', dataIndex: 'type', key: 'type'},
				{title: '设备名称', dataIndex: 'deviceSn', key: 'deviceSn'},
				{title: '告警等级', dataIndex: 'alarmLevel', key: 'alarmLevel'},
				{title: '告警标题', dataIndex: 'alarmTitle', key: 'alarmTitle', },
				{title: '告警发生时间', dataIndex: 'createTime', key: 'createTime', scopedSlots: {customRender: 'createTime'} },
				{title: '告警结束时间', dataIndex: 'changeTime', key: 'changeTime', },
				{title: '状态', dataIndex: 'status', key: 'status', },
			],
			alarmEntities: [],
			moment,
		}
	},
	created() {
		this.queryStationList()
	},
	methods: {
		changeTabs( active ) {
			if ( active == 1 ) {
				this.queryStationList()
			} else if ( active == 2 ) {
				this.loading = true
				post(api.AlarmEntities, {}).then(res=>{
					this.alarmEntities = res.data.data
					this.loading = false
				})
			}
		},
		queryStationList(){
			this.loading = true
			post(api.QueryStationAlarmEntities, {}).then(res=>{
				this.stationEntities = res.data.data
				this.stationPage.total = res.data.count
				this.loading = false
			})
		},
		stationShowSizeChange ( current, pageSize ) {
			this.stationPage.current = current
			this.stationPage.pageSize = pageSize
			this.queryList()
		},
		stationChangePageSize(current, pageSize){
			this.stationPage.current = current
			this.stationPage.pageSize = pageSize
			this.queryList()
		}
	}
}
</script>

<style lang="less">
@import "../../static/alarmList";
</style>
