<template>
	<div class="main">
		<div class="top-panel">
			<div class="search-pane">
				<div class="search-item-box">
					<a-select size="small" style="width: 200px"></a-select>
				</div>
				<div class="search-item-box">
					<a-select size="small" style="width: 200px"></a-select>
				</div>
				<div class="search-item-box">
					<a-select size="small" style="width: 200px"></a-select>
				</div>
			</div>
			<div class="btn">
				<div class="search-item-box">
					<a-button type="primary" ghost size="small">查询</a-button>
				</div>
				<div class="search-item-box">
					<a-button type="default" class="reset-btn" size="small">重置</a-button>
				</div>
			</div>
		</div>
		<div class="card-list">
			<div class="card-boxs">
				<div class="card-item" v-for="station in stationEntities">
					<div class="card-item-title">
						<div class="left">{{ station.stationName }}</div>
					</div>
					<div class="card-item-content">
						<div class="img">
							<img src="https://pv.solar.chu.edu.cn/img/4b2ed7fa-b28f-4370-b301-73e07f34c1b9.blob"/>
						</div>
						<div class="items">
							<div class="item">
								<span>投运时间：</span>
								<span>{{ dateFormat ( '', station.startTime ) }}</span>
							</div>
							<div class="item">
								<span>站点地址：</span>
								<a-tooltip>
									<template #title>{{ station.address }}</template>
									<span class="value">{{ station.address }}</span>
								</a-tooltip>
							</div>
							<div class="item">
								<span>建设场所：</span>
								<span>{{ station.place }}</span>
							</div>
							<div class="item">
								<span>EMS类型：</span>
								<span>{{ station.emsType }}</span>
							</div>
						</div>
						<div class="three-box">
							<div class="three-box-item">
								<div class="top"><span>{{ station.power }}kW </span></div>
								<div class="label">装机功率</div>
							</div>
							<div class="three-box-item">
								<div class="top"><span>{{ station.capacity }}kWh </span></div>
								<div class="label">装机容量</div>
							</div>
							<div class="three-box-item">
								<div class="top"><span> 1个 </span></div>
								<div class="label"> 并网点数量</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="page-box">
				<a-pagination
					size="small"
					:showQuickJumper="true"
					:showSizeChanger="true"
					:current="page.current"
					:pageSize="page.pageSize"
					:total="page.total"
					:show-total="(total) => `共${total}条`"
					@showSizeChange="onShowSizeChange"
					@change="changePageSize"
				></a-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import {format} from "@/api/util";

export default {
	name: "CardStation",
	data() {
		return {
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			stationEntities: [],
		}
	},
	created() {
		this.queryList ()
	},
	methods: {
		queryList() {
			post ( api.StationEntities, {page: this.page.current, limit: this.page.pageSize} ).then ( res => {
				this.stationEntities = res.data.data
				this.page.total = res.data.count
			} )
		},
		onShowSizeChange( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList ()
		},
		changePageSize( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList ()
		},
		dateFormat( mat, date ) {
			return format.dateFormat ( mat, date )
		},
	}
}
</script>

<style lang="less">
@import "../../static/cardStation";
</style>
