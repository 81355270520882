<template>
	<div class="alarm-module">
		<div class="left">
			<div class="date">
				<a-month-picker v-model:value="value2" placeholder="Select month" style="width: 100%" size="small" />
			</div>
			<div class="statistics">
				<div class="info-detail"><p>发生中</p>
					<div class="info-detail-num"> 0</div>
				</div>
				<div class="info-detail"><p>已结束</p>
					<div class="info-detail-num"> 314</div>
				</div>
			</div>
			<div>报表</div>
		</div>
		<div class="right">
			<div class="date">
                <a-month-picker v-model:value="value2" placeholder="Select month" style="width: 100%" size="small" />
			</div>
			<div class="statistics">
				<div class="info-detail"><p>发生中</p>
					<div class="info-detail-num"> 0</div>
				</div>
				<div class="info-detail"><p>已结束</p>
					<div class="info-detail-num"> 314</div>
				</div>
			</div>
			<div>报表</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AlarmModule",
	data() {
		return {
			value2: ''
		}
	}
}
</script>

<style lang="less">
@import "../../../static/alarmModule";
</style>
