<template>
	<a-drawer
		:width="500"
		:title="title"
		:visible="visible"
		@close="onClose">
		<div class="drawer-content-box">
			<a-form-model :model="menuEntity" :rules="rules" ref="menuEntity" layout="vertical">
				<a-form-model-item label="路由菜单名称" required prop="menuName">
					<a-input v-model="menuEntity.menuName" placeholder="请输入路由菜单名称"/>
				</a-form-model-item>
				<a-form-model-item label="路由路径" required prop="url">
					<a-input v-model:value="menuEntity.url" placeholder="请输入路由路径"/>
				</a-form-model-item>
				<a-form-model-item label="路边类型" name="type" prop="type">
					<a-select placeholder="请选择路由菜单级别" v-model:value="menuEntity.type">
						<a-select-option :value="1">一级路由</a-select-option>
						<a-select-option :value="2">二级路由</a-select-option>
						<a-select-option :value="3">三级按钮</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="一级路由Icon">
					<a-input v-model:value="menuEntity.menuClass" placeholder="请输入一级路由Icon"/>
				</a-form-model-item>
				<a-form-model-item label="父路由" name="parentId" prop="parentId">
					<a-tree-select
						placeholder="请选择父路由" v-model:value="menuEntity.parentId"
						:treeData="treeData">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="按钮权限">
					<a-input v-model:value="menuEntity.permission" placeholder="请输入按钮权限（一级:二级:三级）"/>
				</a-form-model-item>
				<a-form-model-item label="路由描述">
					<a-textarea v-model:value="menuEntity.note"/>
				</a-form-model-item>
			</a-form-model>
		</div>
		<div class="drawer-footer-box">
			<a-button type="primary" @click="saveMenu('menuEntity')">保存</a-button>
		</div>
	</a-drawer>
</template>

<script>
import {api, post} from "@/api/api";

export default {
	name: "AddMenu",
	props: {
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			menuEntity: {},
			treeData: [],
			checkedKeys: [],
			checkedList: [],
			rules: {
				menuName: [{required: true, message: '路由菜单名称不能为空', trigger: 'blur'}],
				url: [{required: true, message: '路由路径不能为空', trigger: 'blur'}],
				type: [{required: true, message: '路由路径不能为空', trigger: 'blur'}],
				parentId: [{required: true, message: '父路由不能为空', trigger: 'blur'}],
			},
		}
	},
	methods: {
		show() {
			this.visible = true
			this.treeData = []      // 树清空
			this.checkedKeys = []   // 回显清空
			this.checkedList = []   // 已选清空
			// 查询所有权限
			let that = this
			post ( api.GetMenuList, {} ).then ( res => {
				res.data.data.forEach ( function ( item, index ) {
					let tree = {title: item.menuName, value: index + '-' + item.id, key: index + '-' + item.id}
					that.treeData.push ( tree )
					let children = []
					item.menuEntities.forEach ( function ( child, cIndex ) {
						let c = {
							title: child.menuName,
							value: index + '-' + cIndex + '-' + child.id,
							key: index + '-' + cIndex + '-' + child.id
						}
						children.push ( c )
					} )
					tree.children = children
				} )
			} )
		},
		onClose() {
			this.visible = false
		},
		saveMenu() {
			this.$refs.menuEntity.validate ( ( valid ) => {
				if ( valid ) {
					let parentValue = this.menuEntity.parentId.split ( '-' )
					this.menuEntity.parentId = parentValue[parentValue.length - 1]
					post ( api.SaveMenu, this.menuEntity ).then ( res => {
						this.$message.success ( "添加成功" );
						this.visible = false
						this.$refs.menuEntity.resetFields ();
						this.resetForm ();
						this.$emit ( "reloadList" );
					} )
				}
			} )
		},
		// 重置信息
		resetForm() {
			let menuEntity = {
				menuName: '',
				url: '',
				type: '',
				menuClass: '',
				parentId: '',
			};
			this.menuEntity = menuEntity;
		},
	}
}
</script>

<style scoped>

</style>
