<template>
	<div class="monitor-alarm">
		<div class="top-panel">
			<div class="search-item-box">
				<a-select size="small" style="width: 200px"></a-select>
			</div>
			<div class="search-item-box">
				<a-select size="small" style="width: 200px"></a-select>
			</div>
			<div class="search-item-box">
				<a-select size="small" style="width: 200px"></a-select>
			</div>
			<div class="search-item-box">
				<a-button type="primary" ghost size="small">查询</a-button>
			</div>
			<div class="search-item-box">
				<a-button type="default" class="reset-btn" size="small">重置</a-button>
			</div>
		</div>
		<div class="table-box">
			<div class="table-contain">
				<a-table :columns="columns" :data-source="alarmEntities" :pagination="false"></a-table>
			</div>
			<div class="page-box">
				<a-pagination
					size="small"
					:showQuickJumper="true"
					:showSizeChanger="true"
					:current="page.current"
					:pageSize="page.pageSize"
					:total="page.total"
					:show-total="(total) => `共${total}条`"
					@showSizeChange="onShowSizeChange"
					@change="changePageSize"
				></a-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import moment from "moment";

export default {
	name: "MonitorAlarm",
	props: {
		stationId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			columns: [
				{title: '设备名称', dataIndex: 'deviceAddress', key: 'deviceAddress'},
				{title: '告警标题', dataIndex: 'alarmTitle', key: 'alarmTitle'},
				{title: '原始告警标题', dataIndex: 'title1', key: 'title1'},
				{title: '告警发生时间', dataIndex: 'createTime', key: 'createTime'},
				{title: '告警结束时间', dataIndex: 'endTime', key: 'endTime'},
				{title: '状态', dataIndex: 'status', key: 'status'},
			],
			alarmEntities: [],
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
		}
	},
	watch: {
		stationId( newV, oldV ) {
			this.stationId = newV
			this.queryList ()
		}
	},
	created() {
		this.queryList ()
	},
	methods: {
		queryList() {
			post ( api.AlarmEntities, {
				stationId: this.stationId,
				page: this.page.current,
				limit: this.page.pageSize
			} ).then ( res => {
				this.alarmEntities = res.data.data
				this.page.total = res.data.count
				this.alarmEntities.forEach ( function ( item, index ) {
					item.createTime = moment ( item.createTime ).format('YYYY-MM-DD HH:mm:ss')
				} )
			} )
		},
		onShowSizeChange( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList ()
		},
		changePageSize( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList ()
		},
	}
}
</script>

<style lang="less">
@import "../../../static/monitorAlarm";
</style>
