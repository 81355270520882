<template>
    <div class="main">
        <div class="search-pane">
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
        </div>
        <div class="voltage-tabs table-box">
            <a-tabs v-model:activeKey="activeTab">
                <a-tab-pane key="1" tab="电堆电压预警">
                    <div class="title-box">
                        <div class="voltage-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                            </div>
                        </div>
                        <div class="curve-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="battery-stack-echarts">
                        电堆电压预警报表
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="电池堆电压预警">
                    <div class="voltage-alarm">
                        <div class="title">电池簇电压分析</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                    </div>
                    <div class="voltage-alarm">
                        <div class="title">电池簇单体压差分布</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                    </div>
                    <div class="voltage-alarm">
                        <div class="title">电池簇单体压差趋势</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="单体电压分析">
                    <div class="single-voltage-analysis">
                        <div class="title">单体电压分布</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="echarts-box">
                            单体电压分布散点图
                        </div>
                    </div>
                    <div class="single-voltage-analysis">
                        <div class="title">电池簇电压曲线</div>
                        <div class="title-box">
                            <div class="voltage-search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                    <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                </div>
                            </div>
                            <div class="curve-btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="echarts-box">
                            单体电压曲线折线图
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VoltageAnalysis",
        data(){
            return{
                activeTab: '1',
                dateRange: []
            }
        }
    }
</script>

<style lang="less">
@import "../../static/voltageAnalysis";
</style>
