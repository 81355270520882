<template>
	<div class="real-child">
		<div class="expand_spin_top">
			<div class="left">
				<span> 站点位置：
					<span>
						<a-tooltip>
							<template #title>{{ stationEntity.address }}</template>
							<span class="value">{{ stationEntity.address }}</span>
						</a-tooltip>
					</span>
				</span>
				<span> 投运时间：{{ moment ( stationEntity.startTime ).format ( 'YYYY-MM-DD' ) }}</span>
				<span> 装机功率：{{ stationEntity.power }}kW </span>
				<span> 装机容量：{{ stationEntity.capacity }}kWp</span>
			</div>
			<!--<div class="right" @click="toMonitor(stationEntity.id)"> 进入监控</div>-->
		</div>
		<div class="station-echarts">
			<div class="amount">
				<div id="single_total_amount" class="echarts-box" style="width: 100%;height:200px;"></div>
			</div>
			<div class="power">报表</div>
			<div class="device-table">表格</div>
		</div>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import moment from "moment";
import {format} from "@/api/util";
import {chargeRecords} from "@/api/siteMultiEcharts";

export default {
	name: "RealChildStation",
	props: {
		stationId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			stationEntity: {},
			moment,
		}
	},
	created() {
		post ( api.StationSingle, {id: this.stationId} ).then ( res => {
			this.stationEntity = res.data.data
		} )
	},
	mounted() {
		//近七天
		let time = {
			startTime: format.getDate ( -7 ),
			endTime: format.getDate ( 0 )
		}
		this.queryChargeDisRecords ( time )
	},
	methods: {
		async queryChargeDisRecords( time ) {
			let salesBox = document.getElementById ( "single_total_amount", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = [], yData = [], zData = [];
			time.stationId = this.stationId
			await post ( api.ChargeDisRecords, time ).then ( res => {
				res.data.data.forEach ( function ( item, index ) {
					xData.push ( format.dateFormat ( 'yyyy-MM-dd', item.time ) )
					yData.push ( item.charge )
					zData.push ( item.disCharge )
				} )
			} )
			salesBoxVm.setOption ( chargeRecords ( {xData, yData, zData} ) )
		}
	}
}
</script>

<style lang="less">
@import "../../../static/realChildStation";
</style>
