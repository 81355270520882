<template>
    <div class="main">
        <div class="search-pane">
            <div class="search-item-box">
                <a-select size="small" style="width: 200px"></a-select>
            </div>
        </div>
        <div class="uniformity-tabs table-box">
            <a-tabs v-model:activeKey="activeTab">
                <a-tab-pane key="1" tab="电压一致性">
                    <!--报表-->
                    <div class="title-box">
                        <div class="uniformity-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="uniformity-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="battery-stack-echarts">
                        电堆电压预警报表
                    </div>
                    <!--表格-->
                    <div class="title-box">
                        <div class="uniformity-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="uniformity-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="uniformity-table">
                        <a-table :columns="columns" :data-source="data"></a-table>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="温度一致性">
                    <!--报表-->
                    <div class="title-box">
                        <div class="uniformity-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="uniformity-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="battery-stack-echarts">
                        温度一致性报表
                    </div>
                    <!--表格-->
                    <div class="title-box">
                        <div class="uniformity-search">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                            </div>
                        </div>
                        <div class="uniformity-btn">
                            <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                            <a-button type="default" class="reset-btn" size="small">导出</a-button>
                        </div>
                    </div>
                    <div class="uniformity-table">
                        <a-table :columns="columns" :data-source="data"></a-table>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UniformityAnalysis",
        data(){
            return{
                activeTab: '1',
                columns: [
                    {title: '名称', dataIndex: 'name', key: 'name'},
                    {title: '单体平均值', dataIndex: 'singleAvg', key: 'singleAvg'},
                    {title: '单体最小值', dataIndex: 'singleMin', key: 'singleMin'},
                    {title: '单体最小ID', dataIndex: 'singleMinId', key: 'singleMinId'},
                    {title: '单体最大值', dataIndex: 'singleMax', key: 'singleMax'},
                    {title: '单体最大ID', dataIndex: 'singleMaxId', key: 'singleMaxId'},
                ],
                data: [
                    {
                        key: 1,
                        name: '1#BMS - 1#电池簇',
                        singleAvg: '881.5 V',
                        singleMin: '0A',
                        singleMinId: '95 %',
                        singleMax: '3.341 V',
                        singleMaxId: '3.341 V',
                    },
                ],
            }
        }
    }
</script>

<style lang="less">
@import "../../static/uniformityAnalysis";
</style>
