<template>
    <div class="main">
        <div class="select-box">
            <a-dropdown placement="bottomLeft">
                <a class="ant-dropdown-link" @click.prevent>
                    {{firstSite.stationName}}
                    <a-icon type="down" />
                </a>
                <template #overlay>
                    <a-menu @click="menuClick">
                        <a-menu-item v-for="site in siteEntities">{{site.stationName}}</a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>
        <div class="single-base-info">
	        <div class="single-info-line">
		        <a-row :gutter="16">
			        <a-col class="single-info-item" :span="6">
				        <div class="gutter-box">
					        <div class="one-line">
						        <div class="left">
							        <div>今日充电量</div>
							        <div class="number-box">
								        <div class="num">0</div>
								        <div class="unit">KWh</div>
							        </div>
						        </div>
						        <div class="right one-right">
							        <img src="../../assets/charge.png" />
						        </div>
					        </div>
					        <div class="tow-line">
						        较昨日增长：0 Wkh
					        </div>
					        <div class="three-line">
								<div>月充: 25.378 MWh</div>
								<div>累计充: 74.93 MWh</div>
					        </div>
				        </div>
			        </a-col>
			        <a-col class="single-info-item" :span="6">
				        <div class="gutter-box">
					        <div class="one-line">
						        <div class="left">
							        <div>今日放电量</div>
							        <div class="number-box">
								        <div class="num">0</div>
								        <div class="unit">KWh</div>
							        </div>
						        </div>
						        <div class="right discharge-right">
							        <img src="../../assets/discharge.png" />
						        </div>
					        </div>
					        <div class="tow-line">
						        较昨日增长：0 Wkh
					        </div>
					        <div class="three-line">
						        <div>月放电: 25.378 MWh</div>
						        <div>累计放: 74.93 MWh</div>
					        </div>
				        </div>
			        </a-col>
			        <a-col class="single-info-item" :span="6">
				        <div class="gutter-box">
					        <div class="one-line">
						        <div class="left">
							        <div>今日收益</div>
							        <div class="number-box">
								        <div class="num">0</div>
								        <div class="unit">元</div>
							        </div>
						        </div>
						        <div class="right money-right">
							        <img src="../../assets/money.png" />
						        </div>
					        </div>
					        <div class="tow-line">
						        较昨日增长：0 元
					        </div>
					        <div class="three-line">
						        <div>月收益: 25.378 元</div>
						        <div>总收益: 74.93 元</div>
					        </div>
				        </div>
			        </a-col>
			        <a-col class="single-info-item" :span="6">
				        <div class="gutter-box">
					        <div class="one-line">
						        <div class="left">
							        <div>运维处理（今日）</div>
							        <div class="number-box">
								        <div class="num">0</div>
								        <div class="unit">个</div>
							        </div>
						        </div>
						        <div class="right alarm-right">
							        <img src="../../assets/alarm.png" />
						        </div>
					        </div>
					        <div class="tow-line">
						        较昨日增长：0 Wkh
					        </div>
					        <div class="three-line">
						        <div>发生中: 0 条</div>
						        <div>已结束：0 条</div>
					        </div>
				        </div>
			        </a-col>
		        </a-row>
	        </div>
        </div>
        <!--收益指标-->
        <div class="target-box">
            <div class="target">
                <div class="target_header ems-box-header">
                    <div class="left">收益指标</div>
                    <div class="right">
                        <a-radio-group size="small">
                            <a-radio-button value="a">本周</a-radio-button>
                            <a-radio-button value="b">本月</a-radio-button>
                            <a-radio-button value="c">本年</a-radio-button>
                            <a-radio-button value="d">全部</a-radio-button>
                        </a-radio-group>
                    </div>
                </div>
                <div class="target-main">
                    <div class="target-content">
                        <div class="target-content-left">
                            <div id="income-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                        </div>
                        <div class="target-content-right">
                            <div class="rank-box">
                                <div class="rank-box-head"><span>收益排名</span></div>
                                <div class="overviewsite_ranking_con">
                                    <div class="income-rank-table">
                                        <div class="table_row">
                                            <div class="table_col">
                                                <div class="index green_index"> 1</div>
                                                <div class="name">巢湖学院储能电站</div>
                                            </div>
                                            <div class="right"> 2593.09元</div>
                                        </div>
                                        <div class="table_row">
                                            <div class="table_col">
                                                <div class="index green_index"> 2</div>
                                                <div class="name">芜湖大砻坊储能电站</div>
                                            </div>
                                            <div class="right"> 0元</div>
                                        </div>
                                        <div class="table_row">
                                            <div data-v-2229d4f7="" class="table_col">
                                                <div class="index green_index"> 3</div>
                                                <div class="name">合肥绿能储能电站</div>
                                            </div>
                                            <div class="right"> -736.92元</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--电量指标-->
        <div class="target-box">
            <div class="target">
                <div class="target_header ems-box-header">
                    <div class="left">电量指标</div>
                </div>
                <div class="target-main">
                    <div class="target-content">
                        <div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--系统效率，告警指标-->
        <div class="system-alarm">
            <div class="system-efficiency">
                <div class="target_header ems-box-header">
                    <div class="left">系统效率</div>
                    <div class="right">
                        <a-radio-group size="small">
                            <a-radio-button value="a">本周</a-radio-button>
                            <a-radio-button value="b">本月</a-radio-button>
                            <a-radio-button value="c">本年</a-radio-button>
                            <a-radio-button value="d">全部</a-radio-button>
                        </a-radio-group>
                    </div>
                </div>
                <div class="target-main">
                    <div class="target-content">
                        <div id="system-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                    </div>
                    <div class="target-content">
                        <div class="left-efficiency">
                            <div class="rank-box-head"><span>系统效率</span></div>
                            <div id="efficiency-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                        </div>
                        <div class="right-efficiency-rank">
                            <div class="rank-box-head"><span>系统排名</span></div>
                            <div class="income-rank-table">
                                <div class="table_row">
                                    <div class="table_col">
                                        <div class="index green_index"> 1</div>
                                        <div class="name">巢湖学院储能电站</div>
                                    </div>
                                    <div class="right"> 2593.09元</div>
                                </div>
                                <div class="table_row">
                                    <div class="table_col">
                                        <div class="index green_index"> 2</div>
                                        <div class="name">芜湖大砻坊储能电站</div>
                                    </div>
                                    <div class="right"> 0元</div>
                                </div>
                                <div class="table_row">
                                    <div data-v-2229d4f7="" class="table_col">
                                        <div class="index green_index"> 3</div>
                                        <div class="name">合肥绿能储能电站</div>
                                    </div>
                                    <div class="right"> -736.92元</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alarm-target">
                <div class="target_header ems-box-header">
                    <div class="left">告警指标</div>
                    <div class="right">
                        <a-radio-group size="small">
                            <a-radio-button value="a">本周</a-radio-button>
                            <a-radio-button value="b">本月</a-radio-button>
                            <a-radio-button value="c">本年</a-radio-button>
                            <a-radio-button value="d">全部</a-radio-button>
                        </a-radio-group>
                    </div>
                </div>
                <div class="target-main">
                    <div class="target-content">
                        <div id="alarm-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                    </div>
                    <div class="target-content">
                        <div class="left-efficiency">
                            <div class="alarm-target-pip-box">
									<span>
										<a-radio-group size="small">
											<a-radio-button value="a">设备</a-radio-button>
											<a-radio-button value="b">警告等级</a-radio-button>
										</a-radio-group>
									</span>
                            </div>
                            <div id="device-pip-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
                        </div>
                        <div class="right-efficiency-rank">
                            <div class="rank-box-head"><span>排名</span></div>
                            <div class="income-rank-table">
                                <div class="table_row">
                                    <div class="table_col">
                                        <div class="index green_index"> 1</div>
                                        <div class="name">合肥绿能储能电站</div>
                                    </div>
                                    <div class="right"> 2593.09元</div>
                                </div>
                                <div class="table_row">
                                    <div class="table_col">
                                        <div class="index green_index"> 2</div>
                                        <div class="name">合肥绿能储能电站</div>
                                    </div>
                                    <div class="right"> 0元</div>
                                </div>
                                <div class="table_row">
                                    <div data-v-2229d4f7="" class="table_col">
                                        <div class="index green_index"> 3</div>
                                        <div class="name">合肥绿能储能电站</div>
                                    </div>
                                    <div class="right"> -736.92元</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {devicePip, efficiencyProportion, income, system} from "@/api/siteMultiEcharts";
    import {api, post} from "@/api/api";

    export default {
        name: "SingleStation",
        data(){
            return{
                siteEntities: [],
                firstSite: {},
            }
        },
        mounted() {
            this.queryIncome()
            this.queryElectricity()
            this.systemEfficiency()
            this.queryAlarm()
            this.efficiencyProportion()
            this.queryDevicePip()
        },
        created() {
            this.querySiteList()
        },
        methods: {
            querySiteList(){
                post(api.SingleSite, {}).then(res=>{
                    this.siteEntities = res.data.data.stationEntities
                    this.firstSite = this.siteEntities[0]
                })
            },
            // 点击下拉菜单，选择电站
            menuClick(){

            },
            // 获取收益指标数据
            queryIncome() {
                let salesBox = document.getElementById("income-echarts", null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                let salesBoxVm = this.echarts.init(salesBox);
                let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
                    yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
                salesBoxVm.setOption(income({xData, yData}))
            },
            queryElectricity() {
                let salesBox = document.getElementById("electricity-echarts", null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                let salesBoxVm = this.echarts.init(salesBox);
                let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
                    yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
                salesBoxVm.setOption(income({xData, yData}))
            },
            systemEfficiency() {
                let salesBox = document.getElementById("system-echarts", null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                let salesBoxVm = this.echarts.init(salesBox);
                let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
                    yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
                salesBoxVm.setOption(system({xData, yData}))
            },
            queryAlarm() {
                let salesBox = document.getElementById("alarm-echarts", null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                let salesBoxVm = this.echarts.init(salesBox);
                let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
                    yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
                salesBoxVm.setOption(system({xData, yData}))
            },
            efficiencyProportion() {
                let salesBox = document.getElementById("efficiency-echarts", null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                let salesBoxVm = this.echarts.init(salesBox);
                let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
                    yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
                salesBoxVm.setOption(efficiencyProportion({xData, yData}))
            },
            queryDevicePip() {
                let salesBox = document.getElementById("device-pip-echarts", null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                let salesBoxVm = this.echarts.init(salesBox);
                let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
                    yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
                salesBoxVm.setOption(devicePip({xData, yData}))
            },
        }
    }
</script>

<style lang="less">
    @import "../../static/singleStation";
</style>
