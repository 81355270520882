<template>
    <div class="main">
        <div class="response-tabs table-box">
            <a-tabs v-model:activeKey="activeItem">
                <a-tab-pane key="1" tab="今日响应">
                    <div class="one-echarts">
                        <div class="header ems-box-header">云平台今日实时总有功功率</div>
                        <div class="echarts-box">报表</div>
                    </div>
                    <div class="module">
                        <div class="one-echarts">
                            <div class="header ems-box-header">云平台实时状态</div>
	                        <div style="height: 100px;line-height: 100px;text-align: center;color: #ff8d02">未进入响应</div>
                        </div>
                    </div>
                    <div class="module">
                        <div class="one-echarts">
                            <div class="header ems-box-header">已响应指令</div>
	                        <div style="height: 100px;line-height: 100px;text-align: center;color: #ff8d02">今日暂无数据</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="明日响应">
                    <a-table :columns="columns" :data-source="data" :pagination="false"></a-table>
                </a-tab-pane>
                <a-tab-pane key="3" tab="历史响应">
                    <div class="statistic">
                        <div class="item"><div class="num">88</div><div class="txt"> 总计成功响应次数（次) </div></div>
                        <div class="item"><div class="num">3462</div><div class="txt"> 总计成功响应时长（分钟） </div></div>
                        <div class="item"><div class="num">14.2</div><div class="txt"> 总响应放电量（kWh) </div></div>
                        <div class="item"><div class="num">583.9</div><div class="txt"> 总响应充电量（kWh） </div></div>
                    </div>
                    <div class="module">
                        <div class="header ems-box-header">响应记录</div>
                        <div class="title-box">
                            <div class="search">
                                <div class="search-item-box">
                                    <a-select size="small" style="width: 200px; margin-right: 10px" v-if="siteType == 2"></a-select>
                                    <a-date-picker v-model:value="value1" size="small" locale="ch"/>
                                </div>
                            </div>
                            <div class="btn">
                                <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                <a-button type="default" class="reset-btn" size="small">导出</a-button>
                            </div>
                        </div>
                        <div class="" style="padding: 16px">
                            <a-table :columns="columns" :data-source="data" :pagination="false"></a-table>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResponseManager",
        data() {
            return {
                activeItem: '1',
                value1: [],
                columns: [
                    { title: '调度编号', dataIndex: 'name', key: 'name' },
                    { title: '指令模式', dataIndex: 'age', key: 'age' },
                    { title: '指令下发时间', dataIndex: 'address', key: 'address' },
                    { title: '预约响应时段', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' } },
                    { title: '目标功率（KW）', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' } },
                    { title: '倒计时', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' } },
                ],
                data: [
                    {
                        key: 1,
                        name: 'John Brown',
                        age: 32,
                        address: 'New York No. 1 Lake Park',
                        description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
                    },
                    {
                        key: 2,
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No. 1 Lake Park',
                        description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
                    },
                    {
                        key: 3,
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney No. 1 Lake Park',
                        description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.',
                    },
                ],
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/responseManager";
</style>
