<template>
	<div class="main">
		<div class="top-panel">
			<div class="">
				<a-select
					size="small"
					style="width: 200px"
				></a-select>
			</div>
			<div class="btn">
				<a-button type="primary" @click="addUser">添加</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table :columns="columns" :data-source="data" :loading="loading" :rowKey="data => data.id" :pagination="false" :scroll="{ x: 1000, y: tableHeight }">
				<template #action>
					<a>删除</a>
				</template>
			</a-table>
			<div class="page-box">
				<a-pagination
						size="small"
						:showQuickJumper="true"
						:showSizeChanger="true"
						:current="page.current"
						:pageSize="page.pageSize"
						:total="page.total"
						:show-total="(total) => `共${total}条`"
						@showSizeChange="onShowSizeChange"
						@change="changePageSize"
				></a-pagination>
			</div>
		</div>
		<AddUser ref="user" :title="title" @reloadList="queryList"></AddUser>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import AddUser from "@/views/user/AddUser";

export default {
	name: "UserList",
	components: {AddUser},
	data() {
		return {
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			loading: true,
			columns: [
				{title: '手机', dataIndex: 'telPhone', key: 'telPhone'},
				{title: '姓名', dataIndex: 'name', key: 'name'},
				{title: '性别', dataIndex: 'sex', key: 'sex'},
				{title: '商户', dataIndex: 'merchantId', key: 'merchantId'},
				{title: '角色', dataIndex: 'roleName', key: 'roleName'},
				{title: '身份证', dataIndex: 'idCard', key: 'idCard'},
				{title: '年龄', dataIndex: 'age', key: 'age'},
				{title: '邮箱', dataIndex: 'email', key: 'email'},
				{title: '地址', dataIndex: 'address', key: 'address'},
				{title: '操作', dataIndex: '', key: 'x', scopedSlots: {customRender: 'action'}},
			],
			title: '',
			data: [],
			tableHeight: 0,
		}
	},
	created() {
		this.queryList()
	},
	mounted() {
		// 设置表格y轴滚动条区域的高度，其中236包含顶部、按钮面板、分页面板以及表格header高度
		this.tableHeight = window.innerHeight - 236
	},
	methods: {
		queryList(){
			post ( api.UserEntities, {page: this.page.current, limit: this.page.pageSize} ).then ( res => {
				this.data = res.data.data
				this.page.total = res.data.count
				this.loading = false
			} )
		},
		addUser() {
			this.title = '新增用户'
			this.$refs.user.show()
		},
		onShowSizeChange ( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		},
		changePageSize(current, pageSize){
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		}
	}
}
</script>

<style scoped>

</style>
