<template>
	<div class="map-box" id="map-container" ref="map">
		<div class="search-box">
			<a-input placeholder="输入地址搜索电站位置" v-model="searchValue"/>
			<a-button :disabled="searchValue == ''" type="primary" @click="searchAddress">搜索</a-button>
		</div>
	</div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
	data() {
		return {
			map: null,
			AMap: null,
			searchValue: "",
			searchVm: null,
			geocoder: null,
			form: {
				lng: "",   // 经度
				lat: "",   // 纬度
				address: "",
				//地区编码
				adcode: "",
			},
		};
	},
	props: {
		lngLat: {
			type: Array,
			default: undefined,
		},
	},
	methods: {
		loadMap() {
			return new Promise ( ( reslove, reject ) => {
				AMapLoader.load ( {
					key: "6dc50444c8e2aa9b5ab6ee35f31589a4", // 申请好的Web端开发者Key，首次调用 load 时必填
					plugins: ["AMap.ToolBar", "AMap.Scale", "AMap.Geocoder", "AMap.PlaceSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
					AMapUI: {
						// 是否加载 AMapUI，缺省不加载
						version: "1.1", // AMapUI 缺省 1.1
						plugins: [] // 需要加载的 AMapUI ui插件
					}
				} ).then ( AMap => {
					this.AMap = AMap; // 回显定位
					this.map = new AMap.Map ( "map-container", {
						viewMode: "3D", //是否为3D地图模式
						zoom: 5, //初始化地图级别
						center: [113.594964, 34.799953], //初始化地图中心点位置
					} );
					//地图控件
					this.map.addControl ( new AMap.Scale () );
					// 查询地点插件
					this.searchVm = new AMap.PlaceSearch({ map: this.map });
					//点击获取经纬度;
					this.map.on("click", (e) => {
						// 获取经纬度
						this.form.lng = e.lnglat.lng;
						this.form.lat = e.lnglat.lat;
						// 清除点
						this.removeMarker();
						// 标记点
						this.setMapMarker();
					});
					reslove ();
				} ).catch ( e => {
					console.log ( e, "高德地图加载失败" );
					reject ( e );
				} );
			} );
		},
		// 初始化回显地址
		initLngLatValue(value){
			this.map?.clearMap ();
			if ( this.map ) {
				let marker = new this.AMap.Marker ( {
					icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
					position: value,
				} );
				this.map?.add ( marker );
				this.map?.setZoomAndCenter ( 8, value );
			}
		},
		// 标记点
		setMapMarker() {
			// 自动适应显示想显示的范围区域
			this.map.setFitView();
			this.marker = new AMap.Marker({
				map: this.map,
				position: [this.form.lng, this.form.lat],
			});
			this.map.setFitView();
			// 添加标记点
			this.map.add(this.marker);
			// 逆转获取详细地址
			let lnglat = [this.form.lng, this.form.lat];
			let geoCoder = new AMap.Geocoder({
				city: "010", //城市设为北京，默认：“全国”
				radius: 1000, //范围，默认：500
			});
			geoCoder.getAddress(lnglat, (status, result) => {
				if (status === "complete" && result.regeocode) {
					this.form.address = result.regeocode.formattedAddress;
					this.$emit ( "getMapInfo", {value: this.form} );
				}
			});
		},
		// 清除点
		removeMarker() {
			this.map?.clearMap ();
			if (this.marker) {
				this.map.remove(this.marker);
			}
		},
		// 搜索地址
		searchAddress() {
			this.searchVm.search ( this.searchValue, ( status, result ) => {
				let pois = result.poiList.pois;
				if ( pois.length > 0 ) {
					this.$notification.config ( {
						placement: "topLeft",
						bottom: "20px",
						duration: 3,
						getContainer: () => this.$refs.map,
					} );
					this.$notification.open ( {
						message: "",
						description: "搜索到多个符合的结果，请在地图中选择一个作为电站位置",
						style: {
							width: "max-content",
							background: "#048FFF",
							color: "#ffffff",
						},
						closeIcon: null,
					} );
				}
			} );
		},
	},
	watch: {
		lngLat: {
			handler: function ( newValue, oldValue ) {
				// 清除之前的标记点
				this.map?.clearMap ();
				if ( this.map ) {
					let marker = new this.AMap.Marker ( {
						icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
						position: newValue,
					} );
					this.map?.add ( marker );
					this.map?.setZoomAndCenter ( 8, newValue );
				}
			},
			deep: true
			//immediate: true
		},
	},
	mounted() {
		this.loadMap ();
	},
};
</script>
<style lang="less" scoped>
.map-box {
	width: 100%;
	height: 100%;
	position: relative;

	.search-box {
		position: absolute;
		z-index: 2;
		display: flex;
		left: 32px;
		top: 16px;

		/deep/ .ant-input {
			font-size: 12px;
			width: 336px;
		}

		/deep/ .ant-btn {
			font-size: 12px;
			margin-left: 16px;
		}
	}
}
</style>
