<template>
    <div class="max-box">
        <div class="right">
            <div class="login-box">
                <div class="login-type">
	                <img src="../../assets/logo/11.png" style="width: 280px"/>
                </div>
                <a-form-model ref="ruleForm" :model="userEntity" :rules="rules">
                    <a-form-model-item prop="telPhone">
                        <a-input placeholder="手机号" v-model="userEntity.telPhone">
                            <a-icon type="user" slot="prefix"/>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item prop="password">
                        <a-input-password placeholder="密码" v-model="userEntity.password">
                            <a-icon type="lock" slot="prefix"/>
                        </a-input-password>
                    </a-form-model-item>
                </a-form-model>
                <div class="btn-box">
                    <a-button type="primary" class="btn" @click="login('ruleForm')">登录</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {api, post} from "@/api/api";
    import {ConstUtil} from "@/api/const";

    export default {
        name: "Login",
        data() {
            return {
                spinning: true,
                userEntity: {
                    telPhone: "",
                    password: "",
                },
                rules: {
                    telPhone: [{required: true, message: "手机号不能为空", trigger: "blur"}],
                    password: [
                        {required: true, message: "密码不能为空", trigger: "blur"},
                    ],
                },
            };
        },
        methods: {
            login(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        // 调用登录方法
                        console.log(this.userEntity)
                        post(api.Login, this.userEntity).then(res => {
                            if (res.data.data !== null && res.data.code === ConstUtil.SUCCESS) {
                                this.$message.success('登录成功')
                                window.sessionStorage.setItem('token', res.data.data.token)
                                window.sessionStorage.setItem('userEntity', JSON.stringify(res.data.data))
                                this.$router.push('/index')
                            } else {
                                this.$message.error(res.data.message)
                            }
                        })
                    } else {
                        this.$message.error('登录信息不能为空!')
                    }
                })
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/login";
</style>
