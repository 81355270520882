<template>
	<div class="main">
		<div class="overviewsite_top">
			<div class="overviewsite_top_box"><span>{{ stationEntity.count }}</span><span> 站点总数(个)</span></div>
			<div class="overviewsite_top_box"><span>{{ stationEntity.sumPower }}</span><span> 装机功率(MW)</span></div>
			<div class="overviewsite_top_box"><span>{{ stationEntity.sumCapacity }}</span><span> 装机容量(MWh)</span></div>
			<div class="overviewsite_top_box"><span>{{ stationEntity.sumCharge }}</span><span> 总充电量(MWh)</span></div>
			<div class="overviewsite_top_box"><span>{{ stationEntity.sumDisCharge }}</span><span> 总放电量(MWh)</span></div>
			<div class="overviewsite_top_box"><span>1.95</span><span> 累计收益(万元)</span></div>
			<div class="overviewsite_top_box"><span>400.32</span><span> 昨日收益(元)</span></div>
		</div>
		<!--收益指标-->
		<div class="target-box">
			<div class="target">
				<div class="target_header ems-box-header">
					<div class="left">收益指标</div>
					<div class="right">
						<a-radio-group size="small">
							<a-radio-button value="a">本周</a-radio-button>
							<a-radio-button value="b">本月</a-radio-button>
							<a-radio-button value="c">本年</a-radio-button>
							<a-radio-button value="d">全部</a-radio-button>
						</a-radio-group>
					</div>
				</div>
				<div class="target-main">
					<div class="target-content">
						<div class="target-content-left">
							<div id="income-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
						</div>
						<div class="target-content-right">
							<div class="rank-box">
								<div class="rank-box-head"><span>收益排名</span></div>
								<div class="overviewsite_ranking_con">
									<div class="income-rank-table">
										<div class="table_row">
											<div class="table_col">
												<div class="index green_index"> 1</div>
												<div class="name">巢湖学院储能电站</div>
											</div>
											<div class="right"> 2593.09元</div>
										</div>
										<div class="table_row">
											<div class="table_col">
												<div class="index green_index"> 2</div>
												<div class="name">巢湖学院储能电站</div>
											</div>
											<div class="right"> 0元</div>
										</div>
										<div class="table_row">
											<div data-v-2229d4f7="" class="table_col">
												<div class="index green_index"> 3</div>
												<div class="name">巢湖学院储能电站</div>
											</div>
											<div class="right"> -736.92元</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--电量指标-->
		<div class="target-box">
			<div class="target">
				<div class="target_header ems-box-header">
					<div class="left">充放电记录</div>
					<div class="right">
						<a-radio-group size="small" v-model="monthAndYear" @change="changeDate">
							<a-radio-button value="0">本月</a-radio-button>
							<a-radio-button value="1">本年</a-radio-button>
						</a-radio-group>
						<div style="margin-left: 10px; display: flex">
							<a-range-picker v-model:value="startEndTime" size="small" locale="ch" @change="changeRangDate"/>
						</div>
					</div>
				</div>
				<div class="target-main">
					<div class="target-content">
						<div id="electricity-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
					</div>
				</div>
			</div>
		</div>
		<!--系统效率，告警指标-->
		<div class="system-alarm">
			<div class="system-efficiency">
				<div class="target_header ems-box-header">
					<div class="left">系统效率</div>
					<div class="right">
						<a-radio-group size="small">
							<a-radio-button value="a">本周</a-radio-button>
							<a-radio-button value="b">本月</a-radio-button>
							<a-radio-button value="c">本年</a-radio-button>
							<a-radio-button value="d">全部</a-radio-button>
						</a-radio-group>
					</div>
				</div>
				<div class="target-main">
					<div class="target-content">
						<div id="system-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
					</div>
					<div class="target-content">
						<div class="left-efficiency">
							<div class="rank-box-head"><span>系统效率</span></div>
							<div id="efficiency-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
						</div>
						<div class="right-efficiency-rank">
							<div class="rank-box-head"><span>系统排名</span></div>
							<div class="income-rank-table">
								<div class="table_row">
									<div class="table_col">
										<div class="index green_index"> 1</div>
										<div class="name"> 巢湖学院储能电站</div>
									</div>
									<div class="right"> 2593.09元</div>
								</div>
								<div class="table_row">
									<div class="table_col">
										<div class="index green_index"> 2</div>
										<div class="name">芜湖大砻坊储能电站</div>
									</div>
									<div class="right"> 0元</div>
								</div>
								<div class="table_row">
									<div data-v-2229d4f7="" class="table_col">
										<div class="index green_index"> 3</div>
										<div class="name"> 芜湖大砻坊储能电站</div>
									</div>
									<div class="right"> -736.92元</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="alarm-target">
				<div class="target_header ems-box-header">
					<div class="left">告警指标</div>
					<div class="right">
						<a-radio-group size="small">
							<a-radio-button value="a">本周</a-radio-button>
							<a-radio-button value="b">本月</a-radio-button>
							<a-radio-button value="c">本年</a-radio-button>
							<a-radio-button value="d">全部</a-radio-button>
						</a-radio-group>
					</div>
				</div>
				<div class="target-main">
					<div class="target-content">
						<div id="alarm-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
					</div>
					<div class="target-content">
						<div class="left-efficiency">
							<div class="alarm-target-pip-box">
									<span>
										<a-radio-group size="small">
											<a-radio-button value="a">设备</a-radio-button>
											<a-radio-button value="b">警告等级</a-radio-button>
										</a-radio-group>
									</span>
							</div>
							<div id="device-pip-echarts" class="echarts-box" style="width: 100%;height:300px;"></div>
						</div>
						<div class="right-efficiency-rank">
							<div class="rank-box-head"><span>排名</span></div>
							<div class="income-rank-table">
								<div class="table_row">
									<div class="table_col">
										<div class="index green_index"> 1</div>
										<div class="name">芜湖大砻坊储能电站</div>
									</div>
									<div class="right"> 2593.09元</div>
								</div>
								<div class="table_row">
									<div class="table_col">
										<div class="index green_index"> 2</div>
										<div class="name">芜湖大砻坊储能电站</div>
									</div>
									<div class="right"> 0元</div>
								</div>
								<div class="table_row">
									<div data-v-2229d4f7="" class="table_col">
										<div class="index green_index"> 3</div>
										<div class="name">芜湖大砻坊储能电站</div>
									</div>
									<div class="right"> -736.92元</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {devicePip, efficiencyProportion, income, system, chargeRecords} from "@/api/siteMultiEcharts";
import {api, post} from "@/api/api";
import {format} from '@/api/util';

export default {
	name: "MultiStation",
	data() {
		return {
			stationEntity: {},
			startEndTime: [],
			monthAndYear: '', // 查询时间本月、今年
		}
	},
	mounted() {
		this.queryIncome ()
		//充放电记录
		let time = format.getMonthRangTime () //默认查询当月
		this.queryChargeDisRecords (time)
		this.systemEfficiency ()
		this.queryAlarm ()
		this.efficiencyProportion ()
		this.queryDevicePip ()
	},
	created() {
		//顶部数据包
		this.queryStationsOver ()
	},
	methods: {
		//切换月年时间选择
		changeDate( value ) {
			//本月，今年和指定时间段，拼接startTime和endTime即可
			let time = {}
			if ( this.monthAndYear == 0 ) {
				time = format.getMonthRangTime ()
			}
			if ( this.monthAndYear == 1 ) {
				time = format.getYearRangTime ()
			}
			this.queryChargeDisRecords ( time )
		},
		//选择时间区间
		changeRangDate(date, dateString){
			let time = {startTime: dateString[0], endTime: dateString[1]}
			this.queryChargeDisRecords(time)
		},
		//顶部数据
		queryStationsOver() {
			post ( api.QueryStationsOver, {} ).then ( res => {
				this.stationEntity = res.data.data
			} )
		},
		// 获取收益指标数据
		queryIncome() {
			let salesBox = document.getElementById ( "income-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( income ( {xData, yData} ) )
		},
		//充放电记录
		async queryChargeDisRecords(time) {
			let salesBox = document.getElementById ( "electricity-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = [],  yData = [], zData = [];
			await post ( api.ChargeDisRecords, time ).then ( res => {
				res.data.data.forEach(function ( item, index ) {
					xData.push(format.dateFormat('yyyy-MM-dd', item.time))
					yData.push(item.charge)
					zData.push(item.disCharge)
				})
			} )
			salesBoxVm.setOption ( chargeRecords ( {xData, yData, zData} ) )
		},
		systemEfficiency() {
			let salesBox = document.getElementById ( "system-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( system ( {xData, yData} ) )
		},
		queryAlarm() {
			let salesBox = document.getElementById ( "alarm-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( system ( {xData, yData} ) )
		},
		efficiencyProportion() {
			let salesBox = document.getElementById ( "efficiency-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( efficiencyProportion ( {xData, yData} ) )
		},
		queryDevicePip() {
			let salesBox = document.getElementById ( "device-pip-echarts", null, {
				devicePixelRatio: window.devicePixelRatio,
			} );
			let salesBoxVm = this.echarts.init ( salesBox );
			let xData = ['2023-10-14', '2023-10-15', '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20'],
				yData = [123.45, 234.56, 345.67, 345.23, 342.12, 567.45, 567.56];
			salesBoxVm.setOption ( devicePip ( {xData, yData} ) )
		},
	}
}
</script>

<style lang="less">
@import "../../static/commons";
@import "../../static/multiStation";
</style>
