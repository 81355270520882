<template>
    <div class="curve">
        <div class="left">
            <div class="item" :class="activeItem==1?'left_aside_active':''" @click="activeItem=1">概览统计</div>
            <div class="item" :class="activeItem==2?'left_aside_active':''" @click="activeItem=2">功率曲线</div>
            <div class="item" :class="activeItem==3?'left_aside_active':''" @click="activeItem=3">PCS曲线</div>
            <div class="item" :class="activeItem==4?'left_aside_active':''" @click="activeItem=4">电池堆曲线</div>
            <div class="item" :class="activeItem==5?'left_aside_active':''" @click="activeItem=5">单体曲线</div>
            <div class="item" :class="activeItem==6?'left_aside_active':''" @click="activeItem=6">电池温度报表</div>
            <div class="item" :class="activeItem==7?'left_aside_active':''" @click="activeItem=7">电池电压报表</div>
            <div class="item" :class="activeItem==8?'left_aside_active':''" @click="activeItem=8">电表报表</div>
            <div class="item" :class="activeItem==9?'left_aside_active':''" @click="activeItem=9">收益报表</div>
            <div class="item" :class="activeItem==10?'left_aside_active':''" @click="activeItem=10">自定义曲线</div>
            <div class="item" :class="activeItem==11?'left_aside_active':''" @click="activeItem=11">导出列表</div>
        </div>
        <div class="right">
            <div class="overview" v-if="activeItem==1">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px"></a-select>
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <!--电量指标-->
                <div class="target-box">
                    <div class="target">
                        <div class="target_header ems-box-header">
                            <div class="">收益指标</div>
                            <div class="">
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                <a-radio-group size="small">
                                    <a-radio-button value="a">本周</a-radio-button>
                                    <a-radio-button value="b">本月</a-radio-button>
                                    <a-radio-button value="c">本年</a-radio-button>
                                    <a-radio-button value="d">全部</a-radio-button>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="target-main">
                            <div class="">
                                <div class="total">总收益： 0.0 元</div>
                                <div>报表</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--电量指标-->
                <div class="target-box">
                    <div class="target">
                        <div class="target_header ems-box-header">
                            <div class="">电量指标</div>
                            <div class="">
                                <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                                <a-radio-group size="small">
                                    <a-radio-button value="a">本周</a-radio-button>
                                    <a-radio-button value="b">本月</a-radio-button>
                                    <a-radio-button value="c">本年</a-radio-button>
                                    <a-radio-button value="d">全部</a-radio-button>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="target-main">
                            <div class="">
                                <div class="total">总收益： 0.0 元</div>
                                <div>报表</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-if="activeItem==2">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px"></a-select>
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px" />
                    </div>
                </div>
                <div class="power-echarts">
                    功率曲线图
                </div>
            </div>
            <div class="" v-if="activeItem==3">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px"></a-select>
                        </div>
                    </div>
                </div>
                <div class="contrast-trend">
                    <a-tabs v-model:activeKey="activeTab">
                        <a-tab-pane key="1" tab="对比">
                            <div class="title-box">
                                <div class="curve-search">
                                    <div class="search-item-box">
                                        <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                        <a-date-picker v-model:value="value1" size="small" locale="ch" />
                                    </div>
                                </div>
                                <div class="curve-btn">
                                    <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                    <a-button type="default" class="reset-btn" size="small">导出</a-button>
                                </div>
                            </div>
                            <div class="contrast-echarts">
                                <div class="echarts-box">有功功率对比图</div>
                                <div class="echarts-box">温度对比图</div>
                                <div class="echarts-box">三相电压对比图</div>
                                <div class="echarts-box">三相电流对比图</div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="趋势" force-render>Content of Tab Pane 2</a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="" v-if="activeItem==4">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px"></a-select>
                        </div>
                    </div>
                </div>
                <div class="contrast-trend">
                    <a-tabs v-model:activeKey="activeTab">
                        <a-tab-pane key="1" tab="对比">
                            <div class="title-box">
                                <div class="curve-search">
                                    <div class="search-item-box">
                                        <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                                        <a-date-picker v-model:value="value1" size="small" locale="ch" />
                                    </div>
                                </div>
                                <div class="curve-btn">
                                    <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                                    <a-button type="default" class="reset-btn" size="small">导出</a-button>
                                </div>
                            </div>
                            <div class="contrast-echarts">
                                <div class="echarts-box">堆平均温度对比图</div>
                                <div class="echarts-box">堆电压对比图</div>
                                <div class="echarts-box">堆电流对比图</div>
                                <div class="echarts-box">堆SOC对比图</div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="趋势" force-render>Content of Tab Pane 2</a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="" v-if="activeItem==5">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px"></a-select>
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <div class="single-echarts">
                    <div class="echarts-box">电压曲线</div>
                    <div class="echarts-box">温度曲线</div>
                    <div class="echarts-box">堆SOC曲线</div>
                </div>
            </div>
            <div class="" v-if="activeItem==6">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-date-picker v-model:value="value1" size="small" locale="ch" />
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <div class="">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
            </div>
            <div class="" v-if="activeItem==7">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-date-picker v-model:value="value1" size="small" locale="ch" />
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <div class="">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
            </div>
            <div class="" v-if="activeItem==8">
                <div style="margin-bottom: 10px;">
                    <a-radio-group v-model:value="meterRadioValue" button-style="solid">
                        <a-radio-button value="a">电表电量</a-radio-button>
                        <a-radio-button value="b">电表止度</a-radio-button>
                    </a-radio-group>
                </div>
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-date-picker v-model:value="value1" size="small" locale="ch" />
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <div class="">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
            </div>
            <div class="" v-if="activeItem==9">
                <div class="title-box">
                    <div class="curve-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px;margin-right: 10px"></a-select>
                            <a-date-picker v-model:value="value1" size="small" locale="ch" />
                        </div>
                    </div>
                    <div class="curve-btn">
                        <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <div class="">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
            </div>
            <div class="" v-if="activeItem==10"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CurveReport",
        data() {
            return {
                activeItem: 1, // 激活的左侧项
                activeTab: '1', // 激活的Tab
                dateRange: [],
                value1: '',
                meterRadioValue: 'a', // 电表单选
                batteryColumns: [
                    {title: '时间', dataIndex: 'number', key: 'number'},
                    {title: '最高温度/℃', dataIndex: 'v', key: 'v'},
                    {title: '单体ID', dataIndex: 'c', key: 'c'},
                    {title: '最低温度/℃', dataIndex: 'soc', key: 'soc'},
                    {title: '单体ID', dataIndex: 'soh', key: 'soh'},
                ],
                batteryData: [
                    {
                        key: 1,
                        number: '1#BMS - 1#电池簇',
                        v: '881.5 V',
                        c: '0A',
                        soc: '95 %',
                        soh: '3.341 V',
                    },
                ],
            }
        }
    }
</script>

<style lang="less">
@import "../../../static/curveReport";
</style>
