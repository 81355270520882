<template>
    <div class="main">
        <div class="fall-battery table-box">
            <div class="fall-battery-main">
                <div class="title-box">
                    <div class="soc-search">
                        <div class="search-item-box">
                            <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                            <a-select size="small" style="width: 200px; margin-right: 10px"></a-select>
                            <a-range-picker v-model:value="dateRange" size="small" style="margin-right: 10px"/>
                        </div>
                    </div>
                    <div class="current-btn">
                        <a-button type="primary" size="small" style="margin-right: 10px">查询</a-button>
                        <a-button type="default" class="reset-btn" size="small">导出</a-button>
                    </div>
                </div>
                <div class="icon">
                    <a-icon type="info-circle"/>
                    自动汇总当日每个电池簇最低电压的5个单体，便于及时发现落后电池。
                </div>
                <div class="fall-battery-content">
                    <div class="head">
                        <div class="head-date">日期</div>
                        <div class="head-content">
                            <div class="top">1#电池簇</div>
                            <div class="bottom">单体编号 / 最低电压 / 首次发生时间</div>
                        </div>
                    </div>
                    <div class="fall-content">
                        <div class="content-date">2023-10-21</div>
                        <div class="content-content">
                            <div> 3# / 2.918 V / 17:02</div>
                            <div> 3# / 2.918 V / 17:02</div>
                            <div> 3# / 2.918 V / 17:02</div>
                            <div> 3# / 2.918 V / 17:02</div>
                            <div> 3# / 2.918 V / 17:02</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FallBattery",
        data() {
            return {
                dateRange: []
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/fallBattery";
</style>
