<template>
	<div class="qiu">
		<div id="china_map"></div>
	</div>
</template>
<script>
import * as echarts from "echarts";
import china from "@/utils/china.json";

echarts.registerMap ( "china", china );
export default {
	data() {
		return {};
	},

	methods: {
		//初始化中国地图
		initEchartMap( echartData = [] ) {
			let mapDiv = document.getElementById ( "china_map" );
			let myChart = echarts.init ( mapDiv );

			var dataValue = echartData;
			var options;
			options = {
				tooltip: {
					show: false,
					triggerOn: "mousemove", //mousemove、click
					padding: 8,
					backgroundColor: "rgba(0,0,0,0.6)",
					borderWidth: 0,
					textStyle: {
						color: "#fff",
						fontSize: 12,
					},
				},
				geo: {
					map: "china",
					roam: true, // 一定要关闭拖拽
					zoom: 1.5,
					center: [102, 36], // 调整地图位置
					aspectScale: 0.8, //长宽比
					label: {
						normal: {
							show: true, //关闭省份名展示
							fontSize: "10",
							color: "#ffffff",
						},
						emphasis: {
							show: false,
						},
					},
					silent: true, // 取消高亮  //禁用地图的hover事件
					itemStyle: {
						normal: {
							areaColor: "#1a7dd6",
							borderColor: "#e3e3e3",
							borderWidth: 1, //设置外层边框
						},
						emphasis: {
							show: false,
							shadowBlur: 5,
							borderWidth: 0,
						},
					},
				},
				series: [
					{
						name: "",
						type: "scatter",
						coordinateSystem: "geo",
						data: dataValue,
						symbol: "circle",
						symbolSize: 15,
						hoverSymbolSize: 15,
						tooltip: {
							formatter( value ) {
								return value.data.name;
							},
							show: true,
						},
						encode: {
							value: 2,
						},
						label: {
							formatter: "{b}",
							position: "right",
							show: false,
						},
						itemStyle: {
							color: "#fff",
							borderWidth: 3,
							borderColor: "#f39f25",
						},
						emphasis: {
							disabled: false,
							label: {
								//鼠标移入不显示name
								show: false,
							},
							itemStyle: {
								areaColor: "inherit",
								color: "auto",
								borderColor: "#5AB0FE",
								borderWidth: 2,
							},
						},
					},
				],
			};
			myChart.setOption ( options );
			//随着屏幕大小调节图表
			window.addEventListener ( "resize", () => {
				myChart.resize ();
			} );
		},
	},
};
</script>

<style scoped>
.qiu {
	width: 100%;
	height: 100%;
}

#china_map {
	height: 100%;
	width: 100%;
}
</style>
