<template>
	<div class="main real-box">
		<div class="top-panel">
			<div class="search-pane">
				<div class="search-item-box">
					<a-select size="small" style="width: 200px"></a-select>
				</div>
				<div class="search-item-box">
					<a-select size="small" style="width: 200px"></a-select>
				</div>
				<div class="search-item-box">
					<a-select size="small" style="width: 200px"></a-select>
				</div>
				<div class="search-item-box">
					<a-button type="primary" ghost size="small">查询</a-button>
				</div>
				<div class="search-item-box">
					<a-button type="default" class="reset-btn" size="small">重置</a-button>
				</div>
			</div>
			<div class="btn">
				<a-button type="primary" @click="addStation">添加</a-button>
			</div>
		</div>
		<div class="table-box">
			<div class="table-contain">
				<a-table :columns="columns" :data-source="data" :expandRowByClick="true" :rowKey="data => data.id"
				         :loading="loading" :pagination="false">
					<template slot="startTime" slot-scope="data">
						{{ moment ( data.startTime ).format ( 'YYYY-MM-DD' ) }}
					</template>
					<template slot="action" slot-scope="data">
						<a-icon type="edit" class="icon-box" style="margin-right: 10px" title="编辑" @click.stop="editStation(data.id)"/>
						<a-icon type="api" class="icon-box" style="margin-right: 10px" title="绑定设备" @click.stop="bindDevice(data.id)"/>
						<a-icon type="delete" class="icon-box" title="删除" @click.stop="deleteStation(data.id)"/>
					</template>
					<template slot="expandedRowRender" slot-scope="data">
						<div style="margin: 0">
							<RealChildStation :station-id="data.id"></RealChildStation>
						</div>
					</template>
				</a-table>
			</div>
			<div class="page-box">
				<a-pagination
					size="small"
					:showQuickJumper="true"
					:showSizeChanger="true"
					:current="page.current"
					:pageSize="page.pageSize"
					:total="page.total"
					:show-total="(total) => `共${total}条`"
					@showSizeChange="onShowSizeChange"
					@change="changePageSize"
				></a-pagination>
			</div>
		</div>
		<AddStation ref="station" :title="title" :id="id" @reloadList="queryList" :topDrawer="topDrawer"
		            @closeTopDrawer="closeTopDrawer"></AddStation>
		<!--绑定设备-->
		<AddDevice ref="device"></AddDevice>
	</div>
</template>

<script>
import AddStation from "@/views/SiteMonitoring/components/AddStation";
import {api, post} from "@/api/api";
import RealChildStation from "@/views/SiteMonitoring/components/RealChildStation";
import moment from "moment";
import AddDevice from "@/views/SiteMonitoring/components/AddDevice";

const columns = [
	{title: '电站名称', dataIndex: 'stationName', key: 'stationName', width: 200, ellipsis: true},
	{title: '装机功率', dataIndex: 'power', key: 'power'},
	{title: '装机容量', dataIndex: 'capacity', key: 'capacity'},
	{title: '地址', dataIndex: 'address', key: 'address', width: 400, ellipsis: true},
	{title: '建设场所', dataIndex: 'place', key: 'place', ellipsis: true},
	{title: 'EMS类型', dataIndex: 'emsType', key: 'emsType'},
	{title: '投运时间', scopedSlots: {customRender: 'startTime'}, width: 120},
	{title: '操作', dataIndex: '', key: 'x', scopedSlots: {customRender: 'action'}},
];
export default {
	name: "RealStation",
	components: {AddDevice, RealChildStation, AddStation},
	data() {
		return {
			topDrawer: false,
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			id: '', // 编辑时的电站Id
			loading: true,
			data: [],
			columns,
			title: '',
			moment,
		}
	},
	created() {
		this.queryList ()
	},
	methods: {
		queryList() {
			post ( api.StationEntities, {page: this.page.current, limit: this.page.pageSize} ).then ( res => {
				this.data = res.data.data
				this.page.total = res.data.count
				this.loading = false
			} )
		},
		editStation( id ) {
			this.title = '编辑电站'
			this.id = id
			this.topDrawer = true
		},
		bindDevice( id ) {
			this.$refs.device.getShow(id, true)
		},
		deleteStation( id ) {
			console.log ( id )
		},
		addStation() {
			this.title = '添加电站'
			this.topDrawer = true
		},
		closeTopDrawer() {
			this.topDrawer = false;
			this.id = "";
		},
		onShowSizeChange( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList ()
		},
		changePageSize( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList ()
		},
	}
}
</script>

<style scoped>
.real-box .table-box {
	height: calc(100vh - 140px);
}

.real-box .table-contain {
	height: calc(100% - 50px);
	overflow-y: auto;
}
</style>
