<template>
    <div class="device-monitor">
        <div class="left">
            <div class="item" :class="activeItem==1?'left_aside_active':''" @click="activeItem=1">实时运行</div>
            <div class="item" :class="activeItem==2?'left_aside_active':''" @click="changeMonitorItem(2)">PCS</div>
            <!--<div class="item" :class="activeItem==3?'left_aside_active':''" @click="changeMonitorItem(3)">BMS总览</div>-->
            <div class="item" :class="activeItem==4?'left_aside_active':''" @click="changeMonitorItem(4)">BMS电池簇</div>
            <div class="item" :class="activeItem==5?'left_aside_active':''" @click="changeMonitorItem(5)">单体电池</div>
            <div class="item" :class="activeItem==6?'left_aside_active':''" @click="activeItem=6">电表</div>
            <div class="item" :class="activeItem==7?'left_aside_active':''" @click="activeItem=7">空调</div>
            <div class="item" :class="activeItem==8?'left_aside_active':''" @click="activeItem=8">消防安全</div>
        </div>
        <div class="right">
            <div class="real-box" v-if="activeItem==1">
                <div class="realTime-headList">
                    <div class="item">
                        <div class="txt">实时有功功率</div>
                        <div class="num"> 0kW</div>
                    </div>
                    <div class="item">
                        <div class="txt">实时无功功率</div>
                        <div class="num"> 0kVar</div>
                    </div>
                    <div class="item">
                        <div class="txt">电池堆SOC</div>
                        <div class="num">95%</div>
                    </div>
                    <div class="item">
                        <div class="txt"> 电池堆SOH</div>
                        <div class="num">100%</div>
                    </div>
                    <div class="item">
                        <div class="txt"> 今日充电量</div>
                        <div class="num"> 82.2kWh</div>
                    </div>
                    <div class="item">
                        <div class="txt"> 今日放电量</div>
                        <div class="num"> 0.3kWh</div>
                    </div>
                </div>
                <div class="echarts-boxs">
                    <div class="echarts-boxs-item">
                        asdf
                    </div>
                    <div class="echarts-boxs-item">
                        asdf
                    </div>
                    <div class="echarts-boxs-item">
                        asdf
                    </div>
                    <div class="echarts-boxs-item">
                        asdf
                    </div>
                </div>
            </div>
            <!--PCS模块-->
            <div class="pcs-box" v-if="activeItem==2">
                <a-spin :spinning="spinning">
                    <div v-for="(pcs, index) in pcsData" style="margin-bottom: 30px">
                        <div class="title">
                            <div>{{ index + 1 }}#PCS</div>
                            <div class="btn-box" style="display: flex;">
                                <div class="changeTime-box">
                                    <div>更新时间：{{ pcs.createTime }}</div>
                                    <span style="margin-left: 10px;cursor: pointer"><a-icon type="sync" class="icon" @click="queryPcs"/></span>
                                </div>
                                <a-button :type="pcs.connectStatus==1?'danger':'primary'" size="small" @click="openClose(pcs)">{{pcs.connectStatus==1?'关机':'开机'}}</a-button>
                                <a-button type="primary" class="reset-btn" size="small" @click="alarmReset(pcs)">故障恢复</a-button>
                                <a-button type="primary" class="reset-btn" size="small" @click="offGrid(pcs)">并网</a-button>
                                <a-button type="default" class="reset-btn" size="small" @click="setPower(pcs)">设置充放电功率</a-button>
                            </div>
                        </div>
                        <div class="inner">
                            <div class="status">
                                <div class="item"> 工作状态： <span class="red"> {{ pcs.workStatus }} </span>
                                </div>
                                <div class="item"> 并网状态： <span class="red"> 离网 </span>
                                </div>
                                <div class="item"> 设备状态： <span class="green"> 正常 </span>
                                </div>
                                <div class="item"> 控制模式： <span> 远程 </span></div>
                            </div>
                            <div class="data">
                                <div class="row">
                                    <div> 总交流有功功率： {{ pcs.acTotalActivePower }}kW</div>
                                    <div> 总交流无功功率： {{ pcs.acTotalReactivePower }}kVar</div>
                                    <div> 总交流视在功率： {{ pcs.acTotalApparentPower }}kVA</div>
                                    <div> 总交流功率因数：{{ pcs.acpowerFactor }}</div>
                                    <div> A相有功功率：{{ pcs.aactivePower }}</div>
                                    <div> B相有功功率：{{ pcs.bactivePower }}</div>
                                    <div> C相有功功率：{{ pcs.cactivePower }}</div>
                                    <div> A相有无功率：{{ pcs.areactivePower }}</div>
                                    <div> B相有无功率：{{ pcs.breactivePower }}</div>
                                    <div> C相有无功率：{{ pcs.creactivePower }}</div>
                                    <div> A相视在功率：{{ pcs.aapparentPower }}</div>
                                    <div> B相视在功率：{{ pcs.bapparentPower }}</div>
                                    <div> C相视在功率：{{ pcs.capparentPower }}</div>
                                    <div> 直流历史充电量：{{ pcs.dcHistoryChargeCapacity }}kWh</div>
                                    <div> 直流历史放电量：{{ pcs.dcHistoryDischargeCapacity }}kWh</div>
                                    <div> 直流当天充电量：{{ pcs.dcDayChargeCapacity }}kWh</div>
                                    <div> 直流历史放电量：{{ pcs.dcHistoryDischargeCapacity }}kWh</div>
                                    <div> 交流历史充电量：{{ pcs.acHistoryChargeCapacity }}kWh</div>
                                    <div> 交流当天充电量：{{ pcs.acDayChargeCapacity }}kWh</div>
                                    <div> 交流历史发电量：{{ pcs.acHistoryDischargeCapacity }}kWh</div>
                                    <div> 交流当天发电量：{{ pcs.acDayDischargeCapacity }}kWh</div>
                                    <div> PCS模块温度：{{ pcs.igbttemp || '--' }}℃</div>
                                    <div> PCS环境温度：{{ pcs.ambientTemp }}℃</div>
                                    <div> A相电压：{{ pcs.avoltage }}V</div>
                                    <div> B相电压：{{ pcs.bvoltage }}V</div>
                                    <div> C相电压：{{ pcs.cvoltage }}V</div>
                                    <div> 交流频率：{{ pcs.powerRate }}Hz</div>
                                    <div> A相电流：{{ pcs.acurrent }}A</div>
                                    <div> B相电流：{{ pcs.bcurrent }}A</div>
                                    <div> C相电流：{{ pcs.ccurrent }}A</div>
                                    <div> 电感A相电流：{{ pcs.inductanceACurrent }}A</div>
                                    <div> 电感B相电流：{{ pcs.inductanceBCurrent }}A</div>
                                    <div> 电感C相电流：{{ pcs.inductanceCCurrent }}A</div>
                                    <div> AB电压：{{ pcs.outABVoltage }}A</div>
                                    <div> BC电压：{{ pcs.outBCVoltage }}A</div>
                                    <div> AC电压：{{ pcs.outACVoltage }}A</div>
                                    <div> 总母线电压：{{ pcs.totalBusVoltage }}V</div>
                                    <div> 正母线电压：{{ pcs.positiveBusVoltage }}V</div>
                                    <div> 负母线电压：{{ pcs.negativeBusVoltage }}V</div>
                                    <div> 当前实际充放电工作模式：{{ pcs.actualWorkModel }}</div>
                                </div>
                            </div>
                            <div class="branch">
                                <div class="branch-title">
                                    <div class="branch-name"> 设备故障字</div>
                                </div>
                                <div class="branch-inner">
                                    <div> 硬件故障字1：{{ pcs.alarmBit1 }}</div>
                                    <div> 硬件故障字2：{{ pcs.alarmBit2 }}</div>
                                    <div> 电网故障字：{{ pcs.alarmBit3 }}</div>
                                    <div> 母线故障字：{{ pcs.alarmBit4 }}</div>
                                </div>
                                <div class="branch-inner">
                                    <div> 交流电容故障字：{{ pcs.alarmBit5 }}</div>
                                    <div> 系统故障字：{{ pcs.alarmBit6 }}</div>
                                    <div> 开关故障字：{{ pcs.alarmBit7 }}</div>
                                    <div> 其他故障字：{{ pcs.alarmBit8 }}</div>
                                </div>
                            </div>
                            <div class="branch">
                                <div class="branch-title">
                                    <div class="branch-name"> 支路1</div>
                                </div>
                                <div class="branch-inner">
                                    <div> 直流功率：{{ pcs.dcPower }}kW</div>
                                    <div> 直流电压：{{ pcs.batteryVoltage }}V</div>
                                    <div> 直流电流：{{ pcs.dcTotalCurrent }}A</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
            <div class="bmsSynopsis" v-if="activeItem==3">
                <a-spin :spinning="spinning">
                    <div class="title">
                        <div>1#BMS</div>
                        <div class="btn-box">
                            <a-button type="default" class="reset-btn" size="small">故障复归</a-button>
                        </div>
                    </div>
                    <div class="inner">
                        <!--引入一个电池簇-->
                        <BmsPileInfo></BmsPileInfo>
                    </div>
                </a-spin>
            </div>
            <div class="bmsBatteryPiece" v-if="activeItem==4">
                <a-spin :spinning="spinning">
                    <div class="title">
                        <div>1#BMS</div>
                        <div class="btn-box" style="display: flex">
                            <div class="changeTime-box">
                                <div>更新时间：{{ batteryBoxEntities[0].createTime }}</div>
                                <span style="margin-left: 10px;cursor: pointer"><a-icon type="sync" class="icon"
                                                                                        @click="queryBatteryBox"/></span>
                            </div>
                            <a-button type="default" class="reset-btn" size="small">故障复归</a-button>
                        </div>
                    </div>
                    <div class="inner">
                        <!--引入一个电池簇-->
                        <BmsPileInfo :battery-box-entities="batteryBoxEntities"></BmsPileInfo>
                    </div>
                </a-spin>
            </div>
            <!--单体电池电压和温度-->
            <div class="batteryCell" v-if="activeItem==5">
                <a-spin :spinning="spinning">
                    <div class="batteryCellContent">
                        <div class="battery-search-pane">
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px"></a-select>
                            </div>
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px"></a-select>
                            </div>
                            <div class="search-item-box">
                                <a-select size="small" style="width: 200px"></a-select>
                            </div>
                            <div class="search-item-box">
                                <a-button type="primary" ghost size="small">查询</a-button>
                            </div>
                            <div class="search-item-box">
                                <a-button type="default" class="reset-btn" size="small">重置</a-button>
                            </div>
                        </div>
                        <div class="battery-table-box">
                            <div style="margin-bottom: 10px; display: flex; justify-content: space-between">
                                <div>
                                    <strong>{{ topText.maxText }}：</strong>{{
                                    topText.maxValue
                                    }}<strong>{{ topText.maxNumber }}：</strong>{{ topText.maxNumberValue }}
                                    <span style="border-right: 1px solid #ccc; margin-right: 10px"></span>
                                    <strong>{{ topText.minText }}：</strong>{{
                                    topText.minValue
                                    }}<strong>{{ topText.minNumber }}：</strong>{{ topText.minNumberValue }}
                                </div>
                                <div>
                                    <a-radio-group size="small" v-model="voltageAndTemp" @change="batteryVoltageTemp">
                                        <a-radio-button value="0">单体电压</a-radio-button>
                                        <a-radio-button value="1">单体温度</a-radio-button>
                                    </a-radio-group>
                                </div>
                            </div>
                            <div class="top-line">
                                <div class="index-no">序号</div>
                                <div class="index-no">单体电压</div>
                                <div class="index-no">单位：{{voltageAndTemp==0?'mV':'℃'}}</div>
                            </div>
                            <div class="">
                                <div class="battery-content" v-for="(battery, index) in batteryVoltageAndTemp">
                                    <div class="battery-num">{{ index * 10 + 1 }}# ~ {{ batteryVoltageAndTempCount <
                                        index *
                                        10 + 10 ? batteryVoltageAndTempCount : index * 10 + 10 }}#
                                    </div>
                                    <div class="battery-box">
                                        <div :class="battery[0] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[0]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[0]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[0] }}
                                        </div>
                                        <div :class="battery[1] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[1]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[1]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[1] }}
                                        </div>
                                        <div :class="battery[2] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[2]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[2]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[2] }}
                                        </div>
                                        <div :class="battery[3] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[3]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[3]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[3] }}
                                        </div>
                                        <div :class="battery[4] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[4]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[4]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[4] }}
                                        </div>
                                        <div :class="battery[5] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[5]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[5]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[5] }}
                                        </div>
                                        <div :class="battery[6] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[6]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[6]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[6] }}
                                        </div>
                                        <div :class="battery[7] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[7]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[7]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[7] }}
                                        </div>
                                        <div :class="battery[8] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[8]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[8]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[8] }}
                                        </div>
                                        <div :class="battery[9] == null ? '' : 'item-battery'"
                                             :style="topText.maxValue==battery[9]?'box-shadow: 0 0px 0px 2px #ff661d;':(topText.minValue==battery[9]?'box-shadow: 0 0px 0px 2px #1890ff;':'')">
                                            {{ battery[9] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
            <div class="ammeter-page" v-if="activeItem==6">
                <div class="title">
                    <div class="title-left green"> 1#储能表</div>
                </div>
                <div class="inner">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
                <div class="title" style="margin-top: 16px">
                    <div class="title-left green"> 1#总表</div>
                </div>
                <div class="inner">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
            </div>
            <div class="airConditioner-page" v-if="activeItem==7">
                <div class="title">
                    <div class="title-left"> 1#空调</div>
                    <div class="title-right">
                        <a-button type="primary" ghost size="small" style="margin-right: 10px">设置</a-button>
                        <a-button type="default" class="reset-btn" size="small">关机</a-button>
                    </div>
                </div>
                <div class="inner">
                    <div class="status">
                        <div class="item">工作状态：<span class="green">运行</span></div>
                    </div>
                    <div class="con">
                        <div class="left">
                            <div class="row">
                                <div> 制冷开启点：-40.0℃</div>
                                <div> 制冷停止点：-40.0℃</div>
                                <div> 制热开启点：-40.0℃</div>
                                <div> 制热停止点：-40.0℃</div>
                            </div>
                            <div class="row">
                                <div> 高温告警点：-40.0℃</div>
                                <div> 低温告警点：-40.0℃</div>
                                <div> 湿度：23.0%</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="state-box getWidth">
                                <div class="state">
                                    <div class="green"> 高低温告警</div>
                                </div>
                                <div class="state">
                                    <div class="green"> 高低电压告警</div>
                                </div>
                                <div class="state">
                                    <div class="green"> 传感器告警</div>
                                </div>
                                <div class="state">
                                    <div class="green"> 压缩机告警</div>
                                </div>
                                <div class="state">
                                    <div class="green"> 高低气压告警</div>
                                </div>
                            </div>
                            <div id="gaugeEchart0" class="echart"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fireSafety" v-if="activeItem==8">
                <div class="title">
                    <div class="title-left"> 1#空调</div>
                </div>
                <div class="inner">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
                <div class="inner" style="margin-top: 16px">
                    <a-table :columns="batteryColumns" :data-source="batteryData" :pagination="false"></a-table>
                </div>
            </div>
        </div>
        <a-modal v-model:visible="setPcsPowerModel" title="设置充放电功率" @ok="handleOk">
            <a-form-model :model="deviceEntity" :rules="rules" ref="ruleForm">
                <a-form-model-item label="充放电功率" prop="chargeDisPower">
                    <a-input v-model="deviceEntity.chargeDisPower" placeholder="请输入充放电功率"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import BmsPileInfo from "@/views/SiteMonitoring/components/BmsPileInfo";
    import {api, post} from "@/api/api";
    import {format} from "@/api/util"

    export default {
        name: "DeviceMonitoring",
        components: {BmsPileInfo},
        props: {
            stationId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                activeItem: 1,
                spinning: false,
                deviceEntity: {},//查询当前设备信息
                //PCS
                pcsData: [],
                pcsAlarmBit: [],
                //设置PCS充放电功率
                setPcsPowerModel: false,
                rules: {
                    chargeDisPower: [
                        {required: true, message: "请填写充放电功率", trigger: "blur",},
                        {max: 10, message: "充放电功率不能超过10字", trigger: "blur",},
                    ],
                },
                //电池簇数据
                batteryBoxEntities: [],
                //BMS单体电池模块
                voltageAndTemp: '0', // 电压或温度选项值
                topText: {
                    maxText: '最高电压：',
                    maxValue: '',
                    maxNumber: '',
                    maxNumberValue: '',
                    minText: '最低电压',
                    minValue: '',
                    minNumber: '',
                    minNumberValue: '',
                },
                batteryVoltageAndTemp: [],              //电池电压和温度数据列表
                batteryVoltageAndTempCount: 0,          //统计电池电压和温度数据个数
            }
        },
        watch: {
            stationId(newV, oldV) {
                this.stationId = newV
                this.queryList()
            }
        },
        created() {
        },
        methods: {
            changeMonitorItem(item) {
                this.activeItem = item
                switch (item) {
                    case 1:
                        break;
                    case 2:
                        this.queryPcs()
                        break;
                    case 4:
                        this.queryBatteryBox()
                        break;
                    case 4:
                        this.queryBatteryBox()
                        break;
                    case 5:
                        this.queryVoltage()
                        break;
                }
            },
            //根据设备地址查询设备
            queryDevice(deviceAddress){
                this.deviceEntity = {}
                post(api.SingleDevice, {deviceAddress: deviceAddress}).then(res=>{
                    this.deviceEntity = res.data.data
                })
            },
            queryPcs() {
                this.spinning = true
                post(api.PcsList, {}).then(res => {
                    this.pcsData = res.data.data
                    this.pcsData.forEach(function (value, index) {
                        value.pcsAlarmBitVoList.forEach(function (item, i) {
                            if (item.status == 1) {
                                value.alarmBit1 = item.alarmValue
                            } else if (item.status == 2) {
                                value.alarmBit2 = item.alarmValue
                            } else if (item.status == 3) {
                                value.alarmBit3 = item.alarmValue
                            } else if (item.status == 4) {
                                value.alarmBit4 = item.alarmValue
                            } else if (item.status == 5) {
                                value.alarmBit5 = item.alarmValue
                            } else if (item.status == 6) {
                                value.alarmBit6 = item.alarmValue
                            } else if (item.status == 7) {
                                value.alarmBit7 = item.alarmValue
                            } else if (item.status == 8) {
                                value.alarmBit8 = item.alarmValue
                            }
                        })
                        value.createTime = format.dateFormat('', value.createTime)
                    })
                    this.spinning = false
                })
            },
            //PCS控制开关机
            openClose(pcs) {
                let p = {id: pcs.id, deviceAddress: pcs.deviceAddress}
                let that = this
                that.$confirm({
                    title: '确认开关机吗？',
                    onOk() {
                        post(api.OpenClose, p).then(res => {
                            that.queryPcs()
                        })
                    },
                    onCancel() {
                        console.log('取消')
                    },
                })
            },
            //PCS故障复位下发
            alarmReset(pcs) {
                let p = {id: pcs.id, deviceAddress: pcs.deviceAddress}
                let that = this
                that.$confirm({
                    title: '确认故障复位吗？',
                    onOk() {
                        post(api.AlarmRest, p).then(res => {
                            that.queryPcs()
                        })
                    },
                    onCancel() {
                        console.log('取消')
                    },
                })
            },
            //并离网设置
            offGrid(pcs) {
                let p = {id: pcs.id, deviceAddress: pcs.deviceAddress}
                let that = this
                that.$confirm({
                    title: '确认并离网设置吗？',
                    onOk() {
                        post(api.OffGird, p).then(res => {
                            that.queryPcs()
                        })
                    },
                    onCancel() {
                        console.log('取消')
                    },
                })
            },
            // 设置充放电功率
            setPower(pcs) {
                this.deviceEntity.id = pcs.id
                this.deviceEntity.deviceAddress = pcs.deviceAddress
                //查询目前的充放电功率值
                this.queryDevice(pcs.deviceAddress)
                this.setPcsPowerModel = true
            },
            //保存PCS充放电功率
            handleOk(){
                this.$refs.ruleForm.validate ( ( valid ) => {
                    if (valid) {
                        if (this.deviceEntity.chargeDisPower.trim()==''){
                            this.$message.error('功率值不能为空')
                            return
                        }
                        if (parseFloat(this.deviceEntity.chargeDisPower)>3276 || parseFloat(this.deviceEntity.chargeDisPower)<-3276){
                            this.$message.error('功率值范围在【-3276 ~ 3276】之间')
                            return;
                        }
                        if (parseInt(this.deviceEntity.chargeDisPower) != this.deviceEntity.chargeDisPower) {
                            this.$message.error('功率值必须是整数')
                            return;
                        }
                        let that = this
                        that.$confirm({
                            title: '确认修改充放电功率吗？',
                            onOk() {
                                post(api.ChargeDisPower, that.deviceEntity).then(res => {
                                    that.queryPcs()
                                    that.setPcsPowerModel = false
                                })
                            },
                            onCancel() {
                                console.log('取消')
                            },
                        })
                    }
                })
            },
            //电池簇
            queryBatteryBox() {
                this.spinning = true
                post(api.BatteryBoxEntities, {}).then(res => {
                    this.batteryBoxEntities = res.data.data
                    this.batteryBoxEntities.forEach(function (item, index) {
                        item.createTime = format.dateFormat('', item.createTime)
                    })
                    this.spinning = false
                })
            },
            //切换单体电压和温度
            batteryVoltageTemp(e) {
                this.spinning = true
                if (this.voltageAndTemp == 0) {
                    this.queryVoltage()
                } else if (this.voltageAndTemp == 1) {
                    this.queryTemp()
                }
            },
            queryVoltage() {
                this.spinning = true
                this.batteryVoltageAndTemp = []
                post(api.BatteryVoltageEntities, {}).then(res => {
                    let vArr1 = res.data.data[0].voltage1.split(';')
                    let vArr2 = res.data.data[0].voltage2.split(';')
                    let vArr3 = res.data.data[0].voltage3.split(';')
                    let vArr4 = res.data.data[0].voltage4.split(';')
                    let vArr5 = res.data.data[0].voltage5.split(';')
                    let vArr6 = res.data.data[0].voltage6.split(';')
                    let vArr7 = res.data.data[0].voltage7.split(';')
                    let vArr8 = res.data.data[0].voltage8.split(';')
                    let vArr9 = res.data.data[0].voltage9.split(';')
                    let vArr10 = res.data.data[0].voltage10.split(';')
                    let vArr11 = res.data.data[0].voltage11.split(';')
                    let vArr12 = res.data.data[0].voltage12.split(';')
                    let vArr13 = res.data.data[0].voltage13.split(';')
                    let vArr14 = res.data.data[0].voltage14.split(';')
                    let vArr15 = res.data.data[0].voltage15.split(';')
                    let vArr16 = res.data.data[0].voltage16.split(';')
                    let vArr17 = res.data.data[0].voltage17.split(';')
                    let vArr18 = res.data.data[0].voltage18.split(';')
                    let vArr19 = res.data.data[0].voltage19.split(';')
                    let vArr20 = res.data.data[0].voltage20.split(';')
                    let vArr21 = res.data.data[0].voltage21.split(';')
                    let vArr22 = res.data.data[0].voltage22.split(';')
                    this.batteryVoltageAndTemp.push(vArr1)
                    this.batteryVoltageAndTemp.push(vArr2)
                    this.batteryVoltageAndTemp.push(vArr3)
                    this.batteryVoltageAndTemp.push(vArr4)
                    this.batteryVoltageAndTemp.push(vArr5)
                    this.batteryVoltageAndTemp.push(vArr6)
                    this.batteryVoltageAndTemp.push(vArr7)
                    this.batteryVoltageAndTemp.push(vArr8)
                    this.batteryVoltageAndTemp.push(vArr9)
                    this.batteryVoltageAndTemp.push(vArr10)
                    this.batteryVoltageAndTemp.push(vArr11)
                    this.batteryVoltageAndTemp.push(vArr12)
                    this.batteryVoltageAndTemp.push(vArr13)
                    this.batteryVoltageAndTemp.push(vArr14)
                    this.batteryVoltageAndTemp.push(vArr15)
                    this.batteryVoltageAndTemp.push(vArr16)
                    this.batteryVoltageAndTemp.push(vArr17)
                    this.batteryVoltageAndTemp.push(vArr18)
                    this.batteryVoltageAndTemp.push(vArr19)
                    this.batteryVoltageAndTemp.push(vArr20)
                    this.batteryVoltageAndTemp.push(vArr21)
                    this.batteryVoltageAndTemp.push(vArr22)
                    //计算最高，最低
                    this.maxVoltageAndTemp(1)
                    this.batteryVoltageAndTempCount = res.data.count
                    this.spinning = false
                })
            },
            queryTemp() {
                this.batteryVoltageAndTemp = []
                post(api.BatteryTempEntities, {}).then(res => {
                    let vArr1 = res.data.data[0].temp1.split(';')
                    let vArr2 = res.data.data[0].temp2.split(';')
                    let vArr3 = res.data.data[0].temp3.split(';')
                    let vArr4 = res.data.data[0].temp4.split(';')
                    let vArr5 = res.data.data[0].temp5.split(';')
                    let vArr6 = res.data.data[0].temp6.split(';')
                    this.batteryVoltageAndTemp.push(vArr1)
                    this.batteryVoltageAndTemp.push(vArr2)
                    this.batteryVoltageAndTemp.push(vArr3)
                    this.batteryVoltageAndTemp.push(vArr4)
                    this.batteryVoltageAndTemp.push(vArr5)
                    this.batteryVoltageAndTemp.push(vArr6)
                    this.batteryVoltageAndTempCount = res.data.count
                    //计算最高最低温度
                    this.maxVoltageAndTemp(0)
                    this.spinning = false
                })
            },
            //计算最高最低电压，最高最低温度
            maxVoltageAndTemp(type) {
                let max = 0, min = 5000, maxNumber, minNumber
                this.batteryVoltageAndTemp.forEach(function (item, index) {
                    item.forEach(function (it, ind) {
                        if (max < it) {
                            max = it
                            maxNumber = index * 10 + (ind + 1)
                        }
                        if (min > it) {
                            min = it
                            minNumber = index * 10 + (ind + 1)
                        }
                    })
                })
                this.topText = {
                    maxText: type == 1 ? '最高电压' : '最高温度',
                    maxValue: max,
                    maxNumber: ' 最高编号',
                    maxNumberValue: maxNumber,
                    minText: type == 1 ? '最低电压' : '最低温度',
                    minValue: min,
                    minNumber: ' 最低编号',
                    minNumberValue: minNumber
                }
            },
        }
    }
</script>

<style lang="less">
    @import "../../../static/deviceMonitoring";
</style>
