<template>
    <div class="main">
        <div class="electricity-price-detail">
            <div class="head">
                <span class="head-title">
                    <div class="back" @click="back">
                        <a-icon type="left"/>返回
                    </div>
                    <span> 电价配置 </span>
                </span>
            </div>
            <div class="content">
                <div>当前选择<strong>哈尔滨100KWh</strong>进行电价配置</div>
            </div>
            <div class="electricity-price-table">
                <div class="header">
                    <div class="month">月份</div>
                    <div class="price-box">
                        <div class="price">电价（元/KWh）</div>
                        <div class="cells">
                            <div>尖</div>
                            <div>峰</div>
                            <div>平</div>
                            <div>谷</div>
                        </div>
                    </div>
                </div>
                <div class="content-value">
                    <div class="month-value">
                        asdf
                    </div>
                    <div class="content-price-box">
                        <div class="price-item">尖</div>
                        <div class="price-item">峰</div>
                        <div class="price-item">平</div>
                        <div class="price-item">谷</div>
                    </div>
                </div>
            </div>
            <div style="text-align: right; margin: 0 16px 16px 0">
                <a-button type="primary" ghost size="small">保存</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ElectricityPrice",
        methods: {
            back() {
                this.$router.push("/centralized")
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/electricityPrice";
</style>
