<template>
    <div class="main">
        <div class="top-panel">
            <div class="search-item-box">
                <a-dropdown placement="bottomLeft">
                    <a class="ant-dropdown-link" @click.prevent>
                        {{stationEntity.stationName}}
                        <a-icon type="down" />
                    </a>
                    <template #overlay>
                        <a-menu>
                            <a-menu-item v-for="site in stationEntities" @click="menuClick(site)">{{site.stationName}}</a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </div>
        </div>
        <div class="layout-nav">
            <div class="layout-nav-tabs">
                <div class="layout-nav-tabs-item" :class="activeItem==1?'active':''" @click="activeItem = 1">
                    <a-icon type="appstore" class="icon"/>
                    <div> 首页</div>
                </div>
                <div class="layout-nav-tabs-item" :class="activeItem==2?'active':''" @click="activeItem = 2">
                    <a-icon type="cluster" class="icon"/>
                    <div> 主接线图</div>
                </div>
                <div class="layout-nav-tabs-item" :class="activeItem==3?'active':''" @click="activeItem = 3">
                    <a-icon type="gateway" class="icon"/>
                    <div> 设备监控</div>
                </div>
                <div class="layout-nav-tabs-item" :class="activeItem==4?'active':''" @click="activeItem = 4">
                    <a-icon type="warning" class="icon"/>
                    <div> 故障告警</div>
                </div>
                <div class="layout-nav-tabs-item" :class="activeItem==5?'active':''" @click="activeItem = 5">
                    <a-icon type="line-chart" class="icon"/>
                    <div> 曲线报表</div>
                </div>
                <div class="layout-nav-tabs-item" :class="activeItem==6?'active':''" @click="activeItem = 6">
                    <a-icon type="setting" class="icon"/>
                    <div> 策略管理</div>
                </div>
            </div>
        </div>
        <div class="monitor-content">
            <MonitorHome v-if="activeItem==1" :station-entity="stationEntity"></MonitorHome>
            <DeviceMonitoring v-if="activeItem==3" :station-id="stationEntity.id"></DeviceMonitoring>
            <MonitorAlarm v-if="activeItem==4" :station-id="stationEntity.id"></MonitorAlarm>
            <CurveReport v-if="activeItem==5"></CurveReport>
        </div>
    </div>
</template>

<script>
    import MonitorHome from "@/views/SiteMonitoring/components/MonitorHome";
    import MonitorAlarm from "@/views/SiteMonitoring/components/MonitorAlarm";
    import DeviceMonitoring from "@/views/SiteMonitoring/components/DeviceMonitoring";
    import CurveReport from "@/views/SiteMonitoring/components/CurveReport";
    import {api, post} from "@/api/api";

    export default {
        name: "SingleMonitoring",
        components: {CurveReport, DeviceMonitoring, MonitorAlarm, MonitorHome},
        data() {
            return {
                activeItem: 1,
                stationEntities: [],
                stationEntity: {}
            }
        },
        created() {
            this.querySiteList()
        },
        methods: {
            querySiteList(){
                post(api.SingleSite, {}).then(res=>{
                    this.stationEntities = res.data.data.stationEntities
                    this.stationEntity = res.data.data.singleSite
                })
            },
            // 点击下拉菜单，选择电站
            menuClick(site){
                this.stationEntity = site
            },
        }
    }
</script>

<style lang="less">
    @import "../../static/singleMonitoring";
</style>
