<template>
    <div class="pile">
        <div class="status">
            <div class="item">系统状态：<span>{{batteryBoxEntities[0].status}}</span></div>
            <div class="item">从机状态：<span>{{batteryBoxEntities[0].salveCommStatus}}</span></div>
        </div>
        <div class="data">
            <div class="line">
                <div> 电池总压： {{batteryBoxEntities[0].voltage || '--'}}V</div>
                <div> 可充电量： {{batteryBoxEntities[0].lackAmount || '--'}}kWh</div>
                <div> 累计充电量：{{batteryBoxEntities[0].totalChargeAmount || '--'}}kWh</div>
                <div class="progressMark">
                    <div class="progress ant-progress ant-progress-line ant-progress-status-normal ant-progress-show-info ant-progress-default">
                        <div>
                            <div class="ant-progress-outer">
                                <div class="ant-progress-inner">
                                    <div class="ant-progress-bg"
                                         style="width: 95%; height: 50px; border-radius: 0px; background-image: linear-gradient(to right, rgb(19, 194, 194) 0%, rgb(135, 232, 222) 100%);"></div>
                                </div>
                            </div>
                            <span class="ant-progress-text">
                                            <div style="font-size: 20px; font-weight: 500; text-align: left; color: rgb(19, 194, 194); line-height: 28px;"> {{batteryBoxEntities[0].soc || '--'}}% </div><div>soc</div></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line">
                <div> 总电流： {{batteryBoxEntities[0].current}}A</div>
                <div> 可放电量：{{batteryBoxEntities[0].residueAmount}}kWh</div>
                <div> 累计放电量：{{batteryBoxEntities[0].totalDischargeAmount}}kWh</div>
            </div>
            <div class="line">
                <div> SOH：{{batteryBoxEntities[0].soh || '--'}}%</div>
                <div> 平均温度：{{batteryBoxEntities[0].avgTemp || '--'}}℃</div>
                <div> 绝缘电阻：{{batteryBoxEntities[0].resistance || '--'}}KΩ</div>
            </div>
            <div class="line">
                <div> 最高温度：{{batteryBoxEntities[0].maxTemp || '--'}}℃</div>
                <div> 最低温度：{{batteryBoxEntities[0].minTemp || '--'}}℃</div>
                <div> 电池温差：{{batteryBoxEntities[0].batteryTempDiff || '--'}}℃</div>
            </div>
            <div class="line">
                <div> 电池额定容量：{{batteryBoxEntities[0].batteryRatedCapacity || '--'}}</div>
                <div> 本次放电量：{{batteryBoxEntities[0].dayDischargeAmount || '--'}}V</div>
                <div> 本次充电量：{{batteryBoxEntities[0].dayChargeAmount || '--'}}V</div>
            </div>
            <div class="line">
                <div> 最高单体电压：{{batteryBoxEntities[0].maxVoltage || '--'}}V</div>
                <div> 最低单体电压：{{batteryBoxEntities[0].minVoltage || '--'}}V</div>
                <div> 平均电压：{{batteryBoxEntities[0].avgVoltage || '--'}}V</div>
            </div>
            <div class="box-s">
                <div>最高单体编号：{{batteryBoxEntities[0].maxSingleNumber}}</div>
                <div>最低单体编号：{{batteryBoxEntities[0].minSingleNumber}}</div>
                <div>最高温度编号：{{batteryBoxEntities[0].maxTempNumber}}</div>
                <div>最低温度编号：{{batteryBoxEntities[0].minTempNumber}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BmsPileInfo",
        props: {
            batteryBoxEntities: {
                type: Array,
                default: null,
            }
        }
    }
</script>

<style scoped>
    .box-s {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }
    .box-s div{
        width: 20%;
        padding: 15px 10px;
        background: rgba(0,9,91,.1);
    }
</style>
