import axios from 'axios'

// 设置超时时间
// axios.defaults.timeout = 10000
// 设置默认post的请求头

let request = {
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     * @return 返回ajax结果
     */
    get(url, params) {
        params.token = window.sessionStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(url, params).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    /**
     * post方法，对应post请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     * @return 返回ajax结果
     */
    postToken(url, params) {
        if (typeof params !== 'string') {
            params.token = window.sessionStorage.getItem('token')
        }
        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    /**
     * 文件上传
     * @param url
     * @param params
     * @return {Promise<unknown>}
     */
    upload(url, params) {
        if (params) {
            params.token = window.sessionStorage.getItem('token')
        }
        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

}


export default request
