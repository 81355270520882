<template>
    <a-drawer
            placement="top"
            :closable="false"
            :visible="visible"
            height="100%"
            wrapClassName="order-detail-drawer"
            @close="closeTopDrawer"
    >
        <template #title>
            <div class="head-box">
                <span>工单详情</span>
                <div class="head-btn">
                    <a-button @click="closeTopDrawer">取消</a-button>
                </div>
            </div>
        </template>
        <div class="drawer-content-box">
            <div class="order-detail">
                <div class="base">
                    <div class="title">工单详情</div>
                    <div class="base-content">
                        <div class="line">
                            <div class="item">
                                <div class="t">站点名称：</div>
                                <div class="value">{{orderEntity.stationName}}</div>
                            </div>
                            <div class="item">
                                <div class="t">工单类型：</div>
                                <div class="value">
                                    <template>
                                        <a-tag :color="orderEntity.type == 1 ? '#108ee9' : (orderEntity.type == 2 ? '#f50' : '#87d068')">{{orderEntity.type == 1 ? '巡视检查' : (orderEntity.type == 2 ? '故障检修' : '维护保养')}}</a-tag>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="item">
                                <div class="t">工单来源：</div>
                                <div class="value">
                                    <template>
                                        <a-tag :color="orderEntity.orderSource == 1 ? '#108ee9' : '#f50'">{{orderEntity.orderSource == 1 ? '手动创建' : '告警生成'}}</a-tag>
                                    </template>
                                </div>
                            </div>
                            <div class="item">
                                <div class="t">期望处理时间：</div>
                                <div class="value">{{moment(orderEntity.hopeStartTime).format('YYYY-MM-DD')}} ~ {{moment(orderEntity.hopeEndTime).format('YYYY-MM-DD')}}</div>
                            </div>
                        </div>
                        <div class="line">
                            <div class="item">
                                <div class="t">内容描述：</div>
                                <div class="value">{{orderEntity.info}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flow">
                    <div class="title">工单详情</div>
                    <a-steps :current="0">
                        <template #progressDot="{ index, status, prefixCls }">
                            <a-popover>
                                <template #content>
                                    <span>step {{ index }} status: {{ status }}</span>
                                </template>
                                <span :class="`${prefixCls}-icon-dot`"/>
                            </a-popover>
                        </template>
                        <a-step title="生成工单" description="工单来源：手动创建"/>
                        <a-step title="已接单" description=""/>
                        <a-step title="已完成" description=""/>
                    </a-steps>
                </div>
                <div class="detail">
                    <div class="title">执行详情</div>
                    <div class="line">
                        <div>详情说明：</div>
                        <div>撒的发生大发</div>
                    </div>
                    <div class="line">
                        <div>图片：</div>
                        <div>撒的发生大发</div>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import {api, post} from "@/api/api";
    import moment from "moment";

    export default {
        name: "OrderDetail",
        data() {
            return {
                visible: false,
                id: '',
                orderEntity: {},
                moment,
            }
        },
        methods: {
            back() {
                this.$router.push('/order')
            },
            show(id){
                this.id = id
                this.querySingle()
                this.visible = true
            },
            querySingle() {
                post(api.OrderSingle, {id: this.id}).then(res => {
                    this.orderEntity = res.data.data
                })
            },
            closeTopDrawer() {
                this.visible = false
            }
        }
    }
</script>

<style lang="less">
    @import "../../static/orderDetail";
</style>
