<template>
	<div class="report">
		<div class="report-table">
			<div class="table-header">
				<div class="site-name">
					站点名称
				</div>
				<div class="child-cells">
					<div class="child-cell">
						{{chargingTitle}}
					</div>
					<div class="cells">
						<div>尖</div>
						<div>峰</div>
						<div>平</div>
						<div>谷</div>
						<div style="border-right: 1px solid #ddd;">总</div>
					</div>
				</div>
				<div class="child-cells">
					<div class="child-cell">
						{{disChargeTitle}}
					</div>
					<div class="cells">
						<div>尖</div>
						<div>峰</div>
						<div>平</div>
						<div>谷</div>
						<div style="border-right: 1px solid #ddd;">总</div>
					</div>
				</div>
				<div class="efficiency">
					{{totalTitle}}
				</div>
			</div>
			<!--内容-->
			<div class="table-content">
				<div class="content-row">
					<div class="content-site-name">大沙发斯蒂芬</div>
					<div class="content-cells">
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
					</div>
					<div class="content-efficiency">233.34</div>
				</div>
				<div class="content-row">
					<div class="content-site-name">大沙发斯蒂芬</div>
					<div class="content-cells">
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
					</div>
					<div class="content-efficiency">233.34</div>
				</div>
			</div>
			<!--汇总-->
			<div class="total">
				<div class="content-row">
					<div class="total-value">总计</div>
					<div class="content-cells">
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
						<div class="cells-item">0</div>
					</div>
					<div class="content-efficiency">233.34</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ReportModule",
	props:{
		chargingTitle: {
			type: String,
			default: ''
		},
		disChargeTitle: {
			type: String,
			default: ''
		},
		totalTitle: {
			type: String,
			default: ''
		},
		data: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			value1: '',
		}
	}
}
</script>

<style lang="less">
@import "../../../static/reportModule";
</style>
