<template>
	<a-drawer
		:width="500"
		:title="title"
		:visible="visible"
		@close="onClose">
		<div class="drawer-content-box">
			<a-form-model :model="cameraEntity" :rules="rules" ref="cameraEntity" layout="vertical">
				<a-form-model-item label="相机名称" prop="cameraName">
					<a-input v-model="cameraEntity.cameraName" placeholder="请输入相机名称"/>
				</a-form-model-item>
				<a-form-model-item label="相机型号" prop="cameraModel">
					<a-input v-model:value="cameraEntity.cameraModel" placeholder="请输入相机型号"/>
				</a-form-model-item>
				<a-form-model-item label="相机IP" prop="cameraIp">
					<a-input v-model:value="cameraEntity.cameraIp" placeholder="请输入相机IP"/>
				</a-form-model-item>
				<a-form-model-item label="相机端口" prop="cameraPort">
					<a-input v-model:value="cameraEntity.cameraPort" placeholder="请输入相机端口"/>
				</a-form-model-item>
				<a-form-model-item label="用户名" prop="cameraUser">
					<a-input v-model:value="cameraEntity.cameraUser" placeholder="请输入用户名"/>
				</a-form-model-item>
				<a-form-model-item label="密码" prop="cameraPassword">
					<a-input v-model:value="cameraEntity.cameraPassword" placeholder="请输入密码"/>
				</a-form-model-item>
				<a-form-model-item label="所属电站" prop="stationId">
					<a-select placeholder="请选择所属电站" v-model:value="cameraEntity.stationId">
						<a-select-option :value="station.id" v-for="station in stationEntities">{{station.stationName}}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="安装位置">
					<a-textarea v-model:value="cameraEntity.installAddress" :max-length="50"/>
				</a-form-model-item>
			</a-form-model>
		</div>
		<div class="drawer-footer-box">
			<a-button type="primary" @click="saveCamera('cameraEntity')">保存</a-button>
		</div>
	</a-drawer>
</template>

<script>
import {api, post} from "@/api/api";

export default {
	name: "AddCamera",
	props: {
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			stationEntities: [],
			cameraEntity: {},
			rules: {
				cameraName: [{required: true, message: '相机名称不能为空', trigger: 'blur'}],
				cameraIp: [{required: true, message: '相机IP不能为空', trigger: 'blur'}],
				cameraUser: [{required: true, message: '相机用户名不能为空', trigger: 'blur'}],
				cameraPassword: [{required: true, message: '相机密码不能为空', trigger: 'blur'}],
			},
		}
	},
	methods: {
		show() {
			this.visible = true
			post(api.GetStationList, {}).then(res=>{
				this.stationEntities = res.data.data
			})
		},
		onClose() {
			this.visible = false
		},
		saveCamera() {
			this.$refs.cameraEntity.validate ( ( valid ) => {
				if ( valid ) {
					post ( api.SaveCamera, this.cameraEntity ).then ( res => {
						this.$message.success ( "添加成功" );
						this.visible = false
						this.$refs.cameraEntity.resetFields ();
						this.resetForm ();
						this.$emit ( "reloadList" );
					} )
				}
			} )
		},
		// 重置信息
		resetForm() {
			this.cameraEntity = {};
		},
	}
}
</script>

<style scoped>

</style>
