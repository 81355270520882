<template>
	<div class="main">
		<div class="overviewsite_top box">
			<div class="overviewsite_top_box"><span>4</span><span> 站点总数(个)</span></div>
			<div class="overviewsite_top_box"><span>0.38</span><span> 装机功率(MW)</span></div>
			<div class="overviewsite_top_box"><span>0.84</span><span> 装机容量(MWh)</span></div>
			<div class="overviewsite_top_box"><span>74.38</span><span> 总充电量(MWh)</span></div>
			<div class="overviewsite_top_box"><span>63.04</span><span> 总放电量(MWh)</span></div>
			<div class="overviewsite_top_box"><span>1.95</span><span> 累计收益(万元)</span></div>
			<div class="overviewsite_top_box"><span>400.32</span><span> 昨日收益(元)</span></div>
		</div>
		<div class="map-content" id="map-content"></div>
		<div class="right-info-box" v-if="stationEntity.id != null">
			<div class="header">
				<span>站点概览</span>
			</div>
			<div class="contet">
				<div class="padd-content">
					<div class="ant-col"> 电站名称： <span class="t1">{{stationEntity.stationName}}</span></div>
					<div class="ant-col"> 电站位置： <span class="t1">{{stationEntity.address}}</span></div>
					<div class="ant-col"> 投运时间： <span class="t1">{{moment(stationEntity.startTime).format('YYYY-MM-DD')}}</span></div>
					<div class="ant-col"> 装机功率： <span class="t1">{{stationEntity.power}}KWh</span></div>
					<div class="ant-col"> 装机容量： <span class="t1">{{stationEntity.capacity}}KWp</span></div>
					<div class="ant-col">
						<img src="https://pv.solar.chu.edu.cn/img/4b2ed7fa-b28f-4370-b301-73e07f34c1b9.blob">
					</div>
					<div class="ant-col">
						<div class="report-box" style="height: 300px"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import {api, post} from "@/api/api";
import moment from "moment";

export default {
	name: "SiteMap",
	data() {
		return {
			// 地图实例
			map: null,
			stationEntities: [],
			stationEntity: {},
			img: require ( "../../assets/station.png" ),
			moment,
		};
	},
	mounted() {
		post(api.GetStationList, {}).then(res=>{
			this.stationEntities = res.data.data
			this.loadMap ();
		})
	},
	methods: {
		loadMap() {
			return new Promise ( ( reslove, reject ) => {
				AMapLoader.load ( {
					key: "6dc50444c8e2aa9b5ab6ee35f31589a4", // 申请好的Web端开发者Key，首次调用 load 时必填
					plugins: ["AMap.ToolBar", "AMap.Scale", "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
					AMapUI: {
						// 是否加载 AMapUI，缺省不加载
						version: "1.1", // AMapUI 缺省 1.1
						plugins: [] // 需要加载的 AMapUI ui插件
					}
				} ).then ( AMap => {
					this.map = new AMap.Map ( "map-content", {
						viewMode: "3D", //是否为3D地图模式
						zoom: 5, //初始化地图级别
						center: [113.594964, 34.799953], //初始化地图中心点位置
					} );
					//地图控件
					this.map.addControl ( new AMap.Scale () );
					// 初始化位置
					this.initAddress ()
					reslove ();
				} ).catch ( e => {
					console.log ( e, "高德地图加载失败" );
					reject ( e );
				} );
			} );
		},
		// 初始化默认位置
		initAddress() {
			let that = this, markers = []
			that.stationEntities.forEach ( function ( item ) {
				let marker = new AMap.Marker ( {
					position: new AMap.LngLat ( item.longitude, item.latitude ), //参数为经纬度
					content: `<div data="${item.id}"><img src=${that.img} style="width: 25px" /></div>`
				} )
				that.map.clearMap ()// 清除所有覆盖物（点标志）
				markers.push ( marker )
				let infoWindow = new AMap.InfoWindow ( {
					offset: new AMap.Pixel ( 3, -30 ),
				} );
				marker.on ( "mouseover", ( e ) => {
					infoWindow.setContent ( `
		                <div class="info-box">
		                  <div class='info-name'>${item.stationName}</div>
		                </div>
		              ` );
					infoWindow.open ( that.map, new AMap.LngLat ( item.longitude, item.latitude ) );
				} );
				marker.on ( "mouseout", () => {
					infoWindow.close ( that.map, new AMap.LngLat ( item.longitude, item.latitude ) );
				} );
				marker.on ( "click", ( e ) => {
					that.querySingle(item.id)
				} );
			} )
			that.map.add ( markers )// 添加点标志
		},
		// 点击加载站点详情
		querySingle(id){
			post(api.StationSingle, {id: id}).then(res=>{
				this.stationEntity = res.data.data
			})
		},
	}
}
</script>

<style lang="less">
@import "../../static/siteMap";
</style>
