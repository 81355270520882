<template>
	<div class="main">
		<div class="top-panel">
			<div class="">
				<a-select
					size="small"
					style="width: 200px"
				></a-select>
			</div>
			<div class="btn">
				<a-button type="primary" @click="addUser">添加</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table :columns="columns" :data-source="data" :loading="loading" :rowKey="data => data.id" :pagination="false"
					 :scroll="{ x: 1000, y: tableHeight }" size="middle">
				<template slot="createTime" slot-scope="data">
					{{moment(data.createTime).format('YYYY-MM-DD')}}
				</template>
				<template #action>
					<a>删除</a>
				</template>
			</a-table>
			<div class="page-box">
				<a-pagination
						size="small"
						:showQuickJumper="true"
						:showSizeChanger="true"
						:current="page.current"
						:pageSize="page.pageSize"
						:total="page.total"
						:show-total="(total) => `共${total}条`"
						@showSizeChange="onShowSizeChange"
						@change="changePageSize"
				></a-pagination>
			</div>
		</div>
		<AddMerchant ref="merchant" :title="title" @reloadList="queryList"></AddMerchant>
	</div>
</template>

<script>
import {api, post} from "@/api/api";
import AddMerchant from "@/views/system/components/AddMerchant";
import moment from "moment";

export default {
	name: "MerchantList",
	components: {AddMerchant},
	data() {
		return {
			page: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			loading: true,
			columns: [
				{title: "序号", dataIndex: "index", width: 50,
					customRender: (text, record, index) => {
						return index + 1;
					},fixed: 'left'
				},
				{title: '商户名称', dataIndex: 'merchantName', key: 'merchantName'},
				{title: '联系电话', dataIndex: 'telPhone', key: 'telPhone'},
				{title: '联系人', dataIndex: 'contact', key: 'contact'},
				{title: '上级商户', dataIndex: 'parentName', key: 'parentName'},
				{title: '所在地址', dataIndex: 'address', key: 'address'},
				{title: '商户描述', dataIndex: 'merchantDesc', key: 'merchantDesc'},
				{title: '邮件数量', dataIndex: 'emailNumber', key: 'emailNumber'},
				{title: '短信数量', dataIndex: 'messageNumber', key: 'messageNumber'},
				{title: '创建时间', scopedSlots: {customRender: 'createTime'}},
				{title: '操作', dataIndex: '', key: 'x', scopedSlots: {customRender: 'action'}},
			],
			data: [],
			title: '',
			tableHeight: 0,
			moment,
		}
	},
	created() {
		this.queryList()
	},
	mounted() {
		// 设置表格y轴滚动条区域的高度，其中236包含顶部、按钮面板、分页面板以及表格header高度
		this.tableHeight = window.innerHeight - 236
	},
	methods: {
		queryList(){
			post ( api.MerchantEntities, {page: this.page.current, limit: this.page.pageSize} ).then ( res => {
				this.data = res.data.data
				this.page.total = res.data.count
				this.loading = false
			} )
		},
		addUser() {
			this.title = '新增商户'
			this.$refs.merchant.show()
		},
		onShowSizeChange ( current, pageSize ) {
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		},
		changePageSize(current, pageSize){
			this.page.current = current
			this.page.pageSize = pageSize
			this.queryList()
		}
	}
}
</script>

<style scoped>

</style>
